(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('OrganisationInvitationService', OrganisationInvitationService);

    OrganisationInvitationService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function OrganisationInvitationService($http, $q, logger, ENV_CONFIG) {
        var subPath = 'organisations';
        var userPath = 'users';
        var service = {
            invite: invite,
            accept: accept,
            reject: reject,
            getAll: getAll,
            sendReminder: sendReminder
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        function invite(organisationId, userId, params) {
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/invitations/' + userId, null, {
                params: params
            })
            .then(success)
            .catch(fail);
        }

        function accept(organisationId, invitationId) {
            return $http.post(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/invitations/' + invitationId)
                .then(success)
                .catch(fail);
        }

        function reject(organisationId, invitationId) {
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/invitations/' + invitationId)
                .then(success)
                .catch(fail);
        }

        function getAll(organisationId, params) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/invitations', {
                params: params
            })
            .then(success)
            .catch(fail);
        }

        function sendReminder(userId) {
            return $http.get(ENV_CONFIG.baseAPI + userPath + '/' + userId + '/organisations/invitations/reminder')
                .then(success)
                .catch(fail);
        }
    }

})();
