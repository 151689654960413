import angular from "angular";

angular
    .module("app.organisation.manage-organisation")
    .controller(
        "ManageOrganisationProjectsController",
        ManageOrganisationProjectsController
    );

ManageOrganisationProjectsController.$inject = [
    "$stateParams",
    "$q",
    "logger",
    "currentUser",
    "myOrganisation",
    "features",
    "memberships",
    "invitations",
    "OrganisationInvitationService",
    "OrganisationService",
    "ProjectService",
    "$uibModal",
    "$translate",
    "$rootScope",
];

/* @ngInject */
function ManageOrganisationProjectsController(
    $stateParams,
    $q,
    logger,
    currentUser,
    myOrganisation,
    features,
    memberships,
    invitations,
    OrganisationInvitationService,
    OrganisationService,
    ProjectService,
    $uibModal,
    $translate,
    $rootScope
) {
    var ctrl = this;
    var DUPLICATE = $translate.instant("PROJECT.DUPLICATE");
    ctrl.currentUser = currentUser;
    ctrl.organisation = myOrganisation;
    ctrl.memberships = memberships;
    ctrl.invitations = invitations;
    ctrl.features = features;
    ctrl.isEditing = false;
    ctrl.newInvitation = {
        selectedUser: null,
    };
    ctrl.filterDate = "";
    ctrl.connectionType = "";
    ctrl.visibleProjectsByState = ["DRAFT", "PUBLISHED", "ARCHIVED"];
    ctrl.filterType = "ALL";
    ctrl.setStateOptions = function () {
        var stateDescriptions = $translate.instant([
            "ORGANISATION.PROJECTS.STATE_OPTIONS.PLACEHOLDER",
            "ORGANISATION.PROJECTS.STATE_OPTIONS.DRAFT",
            "ORGANISATION.PROJECTS.STATE_OPTIONS.PUBLISHED",
            "ORGANISATION.PROJECTS.STATE_OPTIONS.ARCHIVED",
            "ORGANISATION.PROJECTS.DATE_OPTIONS.PLACEHOLDER",
        ]);
        var typeDescriptions = $translate.instant([
            "PROJECTS.TYPE_FILTER.ROLES",
            "PROJECTS.TYPE_FILTER.PROJECTS",
        ]);
        ctrl.typeOptions = [
            {
                name: "JOB",
                description: typeDescriptions["PROJECTS.TYPE_FILTER.ROLES"],
            },
            {
                name: "PROJECT",
                description: typeDescriptions["PROJECTS.TYPE_FILTER.PROJECTS"],
            },
        ];
        ctrl.statePlaceholder =
            stateDescriptions["ORGANISATION.PROJECTS.DATE_OPTIONS.PLACEHOLDER"];
        ctrl.stateOptions = [
            {
                name: "DRAFT",
                description:
                    stateDescriptions[
                        "ORGANISATION.PROJECTS.STATE_OPTIONS.DRAFT"
                    ],
            },
            {
                name: "PUBLISHED",
                description:
                    stateDescriptions[
                        "ORGANISATION.PROJECTS.STATE_OPTIONS.PUBLISHED"
                    ],
            },
            {
                name: "ARCHIVED",
                description:
                    stateDescriptions[
                        "ORGANISATION.PROJECTS.STATE_OPTIONS.ARCHIVED"
                    ],
            },
        ];
    };

    $rootScope.$on("$translateChangeSuccess", function () {
        ctrl.setStateOptions();
        DUPLICATE = $translate.instant("PROJECT.DUPLICATE");
    });

    ctrl.setStateOptions();
    ctrl.filterStates = [ctrl.stateOptions[0], ctrl.stateOptions[1]];
    ctrl.filterType = [ctrl.typeOptions[0], ctrl.typeOptions[1]];
    ctrl.filterByState = function (project) {
        return ctrl.visibleProjectsByState.indexOf(project.state) !== -1;
    };

    ctrl.formatDate = function (date) {
        return moment(date).format("D/M/Y");
    };

    ctrl.toggleProjectStateVisible = function (state) {
        var idx = ctrl.visibleProjectsByState.indexOf(state);
        // is currently selected
        if (idx > -1) {
            ctrl.visibleProjectsByState.splice(idx, 1);
        } else {
            ctrl.visibleProjectsByState.push(state);
        }
    };

    if($stateParams.filter == "0") {
        ctrl.filterStates = [ctrl.stateOptions[0]];
        ctrl.filterType = [ctrl.typeOptions[1]];
    }
    if($stateParams.filter == "1") {
        ctrl.filterStates = [ctrl.stateOptions[1]];
        ctrl.filterType = [ctrl.typeOptions[1]];
    }

    ctrl.filterProject = function () {
        var params = {};
        if (ctrl.filterType) {
            params.type = ctrl.filterType.map(function (elem) {
                return elem.name;
            });
        }
        if (ctrl.filterDate) {
            params.created_range = ctrl.filterDate;
        }
        if (ctrl.filterStates) {
            params.state = ctrl.filterStates.map(function (elem) {
                return elem.name;
            });
        }

        OrganisationService.getProjects(
            $stateParams.organisationId,
            params
        ).then(function (projects) {
            ctrl.projects = projects;
        });
    };

    ctrl.editForm = {
        organisation: angular.copy(ctrl.organisation, {}),
    };

    ctrl.setEditForm = function (organisationForm) {
        if (organisationForm) {
            ctrl.organisationForm = organisationForm;
        }
    };

    ctrl.toggleEditing = function () {
        ctrl.isEditing = !ctrl.isEditing;
    };

    ctrl.updateProjectState = function (state, project) {
        project.state = state;
        ProjectService.update($stateParams.organisationId, project);
    };

    ctrl.deleteProject = function (project) {
        ProjectService.remove($stateParams.organisationId, project._id).then(
            function (result) {
                var index = ctrl.projects.indexOf(project);
                ctrl.projects.splice(index, 1);
            }
        );
    };

    ctrl.save = function () {
        if (
            ctrl.organisationForm &&
            !ctrl.organisationForm.$invalid &&
            ctrl.isEditing
        ) {
            OrganisationService.update(
                myOrganisation._id,
                ctrl.editForm.organisation
            ).then(function (organisation) {
                ctrl.organisation = organisation;
                ctrl.isEditing = !ctrl.isEditing;
            });
        } else {
            logger.warning(
                "Cannot update organisation with the current state",
                ctrl.organisationForm
            );
        }
    };

    ctrl.cancel = function () {
        ctrl.editForm = {
            organisation: angular.copy(ctrl.organisation, {}),
        };
        ctrl.isEditing = !ctrl.isEditing;
    };

    ctrl.invite = function () {
        if (
            !ctrl.newInvitation.selectedUser ||
            !ctrl.newInvitation.selectedUser._id
        ) {
            logger.warning(
                "No user selected to invite! Select a user before sending an invitation."
            );
            return;
        }

        OrganisationInvitationService.invite(
            ctrl.organisation._id,
            ctrl.newInvitation.selectedUser._id,
            { includes: ["user"] }
        ).then(function (invitation) {
            ctrl.invitations.push(invitation);
            ctrl.newInvitation.selectedUser = null;
        });
    };

    ctrl.reject = function (invitation) {
        if (!invitation) {
            logger.error(
                "Expected an invitation to remove. No invitation was passed to remove."
            );
            return;
        }

        OrganisationInvitationService.reject(
            ctrl.organisation._id,
            invitation._id
        ).then(function (invitation) {
            var index = ctrl.invitations.indexOf(invitation);
            ctrl.invitations.splice(index, 1);
        });
    };

    ctrl.removeMembership = function (membership) {
        if (membership.role == "CHIEF") {
            logger.error(
                "You cannot leave an organisation while you are chief! Assign a new chief or delete the organisation from the organisation's profile"
            );
            return;
        }

        OrganisationService.removeMember(
            membership.organisation_id,
            membership._id
        ).then(function (result) {
            var index = ctrl.memberships.indexOf(membership);
            ctrl.memberships.splice(index, 1);
        });
    };

    ctrl.showEditPrivilegesForm = function (membership) {
        var dialog = $uibModal.open({
            backdrop: "static",
            size: "lg",
            template: require("~/app/organisation/edit-membership-privileges/edit-membership-privileges-form.html"),
            controller: "EditMembershipPrivilegesController",
            controllerAs: "vm",
            resolve: {
                currentUser: function () {
                    return ctrl.currentUser;
                },
                membership: function () {
                    return membership;
                },
            },
        });

        dialog.result.then(
            function (membership) {},
            function () {
                // cancelled
            }
        );
    };

    ctrl.duplicateProject = function (project) {
        var duplicatedProject = angular.copy(project, {});
        duplicatedProject.state = "DRAFT";
        duplicatedProject.name = DUPLICATE + " " + duplicatedProject.name;
        var oldId = duplicatedProject._id;
        delete duplicatedProject._id;
        delete duplicatedProject.created_at;
        ProjectService.duplicate(
            ctrl.organisation._id,
            oldId,
            duplicatedProject
        ).then(function (project) {
            ctrl.projects.push(project);
        });
    };
}
