import angular from "angular";
import template from "./membership-card.html";

angular
    .module("app.organisation.my-organisation")
    .directive("membershipCard", MembershipCard);

MembershipCard.$inject = [];

/* @ngInject */
function MembershipCard() {
    var directive = {
        scope: {
            membership: "=",
        },
        template: template,
        controller: MembershipCardController,
        controllerAs: "vm",
        restrict: "E",
    };

    return directive;
}

MembershipCardController.$inject = [];

/* @ngInject */
function MembershipCardController() {
    var vm = this;

    vm.shouldShowProperty = function (user, propertyName) {
        if (!user) {
            console.log("[ERROR] User was undefined...");
            return;
        }

        var scopes = [];
        for (var i = 0; i < user.scopes.length; i++) {
            scopes[user.scopes[i].property] = user.scopes[i].scope;
        }
        return (
            scopes.hasOwnProperty(propertyName) &&
            scopes[propertyName] != "private" &&
            user.hasOwnProperty(propertyName) &&
            typeof user[propertyName] !== "undefined"
        );
    };
}
