import angular from "angular";
import template from "./talent.html";
import "./popover.html";

angular.module("app.project").directive("talent", Talent);

Talent.$inject = [];

/* @ngInject */
function Talent() {
    var directive = {
        bindToController: true,
        controller: TalentController,
        controllerAs: "talent",
        restrict: "E",
        replace: true,
        scope: {
            talent: "=",
        },
        template: template,
    };

    /* @ngInject */
    function TalentController() {
        var ctrl = this;
        ctrl.customPopover = false
        ctrl.openCustomPopover = function() {
            if(this.customPopover) {
                ctrl.customPopover = false
            } else {
                ctrl.customPopover = true
            }
        }
    }

    return directive;
}
