/* Root files */
//import '!!file-loader?name=[name].[ext]!./favicon.ico';
// import '!!file-loader?name=[name].[ext]!./favicon.ico';
// import '!!file-loader?name=[name].[ext]!./browserconfig.xml';
// import '!!file-loader?name=[name].[ext]!./android-chrome-512x512.png';
// import '!!file-loader?name=[name].[ext]!./android-chrome-192x192.png';
// import '!!file-loader?name=[name].[ext]!./mstile-150x150.png';
// import '!!file-loader?name=[name].[ext]!./mstile-310x150.png';
// import '!!file-loader?name=[name].[ext]!./mstile-310x310.png';
// import '!!file-loader?name=[name].[ext]!./mstile-70x70.png';

/*
  We must import external css from js instead of scss
  because of https://github.com/jtangelder/sass-loader/issues/164
  so we can split them later to lib.css
*/
//@require "./**/*.html"
import "angucomplete-alt/angucomplete-alt.css";
import "ui-select/dist/select.css";
import "toastr/toastr.scss";
import "flag-icon-css/css/flag-icon.css";
import "ng-table/dist/ng-table.css";
import "textangular/dist/textAngular.css";
import "bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";
import "intro.js/introjs.css";
import "./styles/main.scss";

import "jquery";

import "./app";
import Raven from "raven-js";
import ngRaven from "raven-js/plugins/angular";

import "./resources/l10n/locale-en.json";
import "./resources/l10n/locale-nl.json";
import "./resources/i18n/angular-locale_en.js";
import "./resources/i18n/angular-locale_nl.js";

Raven.config("https://942e66e6125544f994de153537b2a608@sentry.io/208645")
    .addPlugin(ngRaven)
    .install();
