import angular from "angular";
import template from "./search-talent.html";

angular.module("app.organisation.search-talent").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.organisation-search-talent",
            config: {
                url: "/my-organisation/:organisationId/search-talent",
                template: template,
                controller: "SearchTalentController",
                controllerAs: "$ctrl",
                title: "search talent",
                resolve: {
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                },
            },
        },
    ];
}
