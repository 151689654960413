import angular from "angular";
import * as template from "./onboarding.html";
import "./onboarding-profile-delails.html";
import "./onboarding-step1.html";
import "./onboarding-step2.html";
angular.module("app.account.onboarding").run(appRun);

appRun.$inject = ["$rootScope", "$state", "routerHelper", "AuthService"];
/* @ngInject */
function appRun($rootScope, $state, routerHelper, AuthService) {
    $rootScope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
            // if(toState.name === 'app-nomenu.onboarding') {
            //     var currentUser = AuthService.getCurrentUser();
            //
            //     if (currentUser.onboarding_completed) {
            //         $state.go('app.my-profile');
            //         event.preventDefault();
            //     }
            // }
        }
    );

    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app-nomenu.onboarding",
            config: {
                url: "/welcome?step",
                template: template,
                controller: "AccountOnboardingController",
                controllerAs: "vm",
                title: "Welcome",
            },
        },
    ];
}
