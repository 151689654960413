(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('TalentMasterService', TalentMasterService);

    TalentMasterService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG', 'l10n'];

    /* @ngInject */
    function TalentMasterService($http, $q, logger, ENV_CONFIG, l10n) {
        var subPath = 'talents';
        var service = {
            list: list,
            search: search,
            save: save,
            update: update,
            getOne: getOne,
            remove: remove,
            replace: replace
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        function list(offset, size, filter, sort) {
            var params = {};
            params.o = offset;
            params.s = size;

            if(filter) {
                params.filter = filter;
            }

            if(sort) {
                params.sort = sort;
            }

            return $http.get(ENV_CONFIG.baseAPI + subPath + '/', {
                params: params
            }).then(success).catch(fail);
        }

        function save(talent) {
            return $http.post(ENV_CONFIG.baseAPI + subPath, talent)
                .then(success)
                .catch(fail);
        }


        function update(talentId, data) {
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/' + talentId, data)
                .then(success)
                .catch(fail);
        }

        /**
         * GET one user
         *
         * @param {number} userId
         * @returns {*}
         */
        function search(query, category) {
            var language = l10n.getLanguage();
            var params = {
                q: query,
                language: language
            };

            if(category) params.category = category;

            return $http.get(ENV_CONFIG.baseAPI + subPath + '/search', {
                params: params
            }).then(success).catch(fail);
        }

        /**
         * GET one user
         *
         * @param talentId
         * @returns {*}
         */
        function getOne(talentId) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + talentId)
                .then(success)
                .catch(fail);
        }
        /**
         * DELETE one talent
         *
         * @param talentId
         * @returns {*}
         */
        function remove(talentId){
            return $http.delete(ENV_CONFIG.baseAPI+subPath+'/'+talentId);
        }
        /**
         * REPLACE one talent
         *
         * @param oldTalentId
         * @param newTalent
         * @returns {*}
         */
        function replace(oldTalentId, newTalent){
            return $http.put(`${ENV_CONFIG.baseAPI}${subPath}/${oldTalentId}/replace/${newTalent._id}`, newTalent);
        }
    }

})();
