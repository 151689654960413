import angular from "angular";
import template from "./latest-project.html";
import controller from "./latest-project.controller";

var LatestProject = {
    bindings: {
        organisationid: "<",
        latestproject: "<"
    },
    template: template,
    controller: controller,
};

angular
    .module("app.dashboard.latest-project")
    .component("latestProject", LatestProject);
