import angular from 'angular';

angular
    .module('app.organisation.manage-organisation')
    .controller('FindMembersController', FindMembersController);

FindMembersController.$inject = ['$scope', '$q', 'logger', '$translate', 'UserService', 'OrganisationService'];

/* @ngInject */
function FindMembersController($scope, $q, logger, $translate, UserService, OrganisationService) {
    var ctrl = this;


    ctrl.qry = "";
    ctrl.result = {
        hasSearched: false,
        total: 0,
        data: []
    };

    ctrl.search = function() {
        var type = "ALL";
        OrganisationService.searchMembers(ctrl.organisationId, ctrl.qry, type).then(function(result){
            ctrl.result.data = result.members;
            ctrl.result.hasSearched = true;
            ctrl.result.total = result.total;
        })
    };

    ctrl.setOwner = function(user){
        ctrl.result.hasSearched = false;
        ctrl.owner = user;
    };

    ctrl.change = function(){
        ctrl.result.hasSearched = false;
        ctrl.result.data = null;
        ctrl.qry = "";
        ctrl.owner = null;
    }
}
