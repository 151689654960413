import angular from "angular";
import * as template from "./feedback.html";

angular.module("app.profile.feedback").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.feedback",
            config: {
                url: "/feedback",
                template: template,
                controller: "feedbackController",
                controllerAs: "vm",
                title: "My feedback",
                resolve: {
                    memberships: [
                        "currentUser",
                        "UserService",
                        function (currentUser, UserService) {
                            return UserService.getOrganisationMemberships(
                                currentUser._id,
                                { includes: ["organisation"] }
                            );
                        },
                    ],
                    suggestions: [
                        "TalentSuggestion",
                        function (TalentSuggestion) {
                            return TalentSuggestion.find();
                        },
                    ],
                },
            },
        },
    ];
}
