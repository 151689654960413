(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('TalentSuggestion', TalentSuggestion);

    TalentSuggestion.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function TalentSuggestion($http, $q, logger, ENV_CONFIG) {
        var subPath = 'suggest-talent';
        var service = {
            create: create,
            find: find,
            accept: accept,
            reject: reject,
            checkExist: checkExist
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        function create(data) {
            return $http.post(ENV_CONFIG.baseAPI + subPath, data).then(success).catch(fail);
        }
        function find() {
            return $http.get(ENV_CONFIG.baseAPI + subPath).then(success).catch(fail);
        }
        function accept(suggestionId){
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/accept/' + suggestionId).then(success).catch(fail);
        }
        function reject(suggestionId) {
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/reject/' + suggestionId).then(success).catch(fail);
        }
        function checkExist(userId, talentId){
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/user/' + userId + '/talent/' + talentId).then(success).catch(fail);
            
        }
    }

})();
