import angular from "angular";
import template from "./footer-button.html";

angular.module("app.project").directive("footerButton", FooterButton);

FooterButton.$inject = [];

/* @ngInject */
function FooterButton() {
    var directive = {
        bindToController: true,
        controller: FooterButtonController,
        controllerAs: "footerButton",
        restrict: "E",
        scope: {
            form: "=",
            disabled: "<",
            customOnNext: "=",
            alwaysShowFinalButton: "=",
            projectType: "<",
            addRole: "=",
            save: "=",
            update: "=",
            role: "<",
        },
        template: template,
    };

    FooterButtonController.$inject = ["WizardHandler"];

    /* @ngInject */
    function FooterButtonController(WizardHandler) {
        var ctrl = this;

        ctrl.onNext = function () {
            if (ctrl.customOnNext && ctrl.customOnNext()) {
                return;
            }
            if (ctrl.form && ctrl.form.$invalid) {
                for (var error in ctrl.form.$error) {
                    ctrl.form.$error[error].forEach(function (element) {
                        element.$touched = true;
                    });
                }
            } else {
                if (
                    ctrl.projectType &&
                    ctrl.projectType === "JOB" &&
                    WizardHandler.wizard().currentStepNumber() === 3 &&
                    ctrl.addRole
                ) {
                    ctrl.addRole();
                    ctrl.trySave();
                    WizardHandler.wizard().goTo(4);
                } else {
                    ctrl.trySave();
                    WizardHandler.wizard().next();
                }
            }
        };

        ctrl.trySave = function () {
            if (ctrl.alwaysShowFinalButton) return;
            if (
                ctrl.isFirstStep() ||
                WizardHandler.wizard().currentStepNumber() === 4
            ) {
                ctrl.save();
            } else {
                if (!ctrl.update) return;
                if (ctrl.role) {
                    ctrl.update(ctrl.role);
                } else {
                    ctrl.update();
                }
            }
        };

        ctrl.onPrevious = function () {
            if (
                ctrl.projectType &&
                ctrl.projectType === "JOB" &&
                WizardHandler.wizard().currentStepNumber() === 5
            ) {
                WizardHandler.wizard().goTo(2);
            } else {
                WizardHandler.wizard().previous();
            }
        };

        ctrl.isFirstStep = function () {
            var wizard = WizardHandler.wizard();
            return wizard.currentStepNumber() === 1;
        };

        ctrl.isLastStep = function () {
            var wizard = WizardHandler.wizard();
            return (
                wizard.currentStepNumber() === wizard.totalStepCount() &&
                wizard.currentStepNumber() !== 4
            );
        };
    }

    return directive;
}
