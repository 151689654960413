import angular from 'angular';
import appCore from '../core';
import managerViewProject from './manager-view-project';
import managerEditProject from './edit';

angular.module('app.project', [
    appCore,
    managerViewProject,
    managerEditProject
]);
