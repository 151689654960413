import angular from "angular";
import template from "./my-profile.html";

angular.module("app.profile.my-profile").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.my-profile",
            config: {
                url: "/?action&invitationId/:tour/:step",
                template: require("~/app/profile/my-profile/my-profile.html"),
                controller: "MyProfileController",
                controllerAs: "profile",
                title: "My profile",
                resolve: {
                    currentUser: [
                        "UserService",
                        function (UserService) {
                            return UserService.getMe();
                        },
                    ],
                    //talents: ['Session', 'API', function(Session, API) {
                    //    return API.one('members', Session.getUserId()).one('talents').get({ s: 100 });
                    //}],
                    //talentgroups: ['Session', 'API', function(Session, API) {
                    //    return API.one('members', Session.getUserId()).one('talentgroups').get();
                    //}]
                },
            },
        },
    ];
}
