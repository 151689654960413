import angular from "angular";
import template from "./invite-tribeforce-users.html";

let InviteTribeforceUsers = {
    bindings: {
        organisationId: "<",
    },
    template: template,
    controller: "InviteTribeforceUsersController",
};

angular
    .module("app.organisation.manage-organisation")
    .component("inviteTribeforceUsers", InviteTribeforceUsers);
