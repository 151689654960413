import angular from "angular";

angular
    .module("app.organisation.manage-organisation")
    .controller(
        "ManageOrganisationUsersController",
        ManageOrganisationUsersController
    );

ManageOrganisationUsersController.$inject = [
    "$stateParams",
    "$q",
    "logger",
    "currentUser",
    "myOrganisation",
    "features",
    "memberships",
    "OrganisationService",
    "$uibModal",
];

/* @ngInject */
function ManageOrganisationUsersController(
    $stateParams,
    $q,
    logger,
    currentUser,
    myOrganisation,
    features,
    memberships,
    OrganisationService,
    $uibModal
) {
    var ctrl = this;
    ctrl.currentUser = currentUser;
    ctrl.organisation = myOrganisation;
    ctrl.memberships = memberships;
    ctrl.features = features;

    ctrl.removeMembership = function (membership) {
        if (membership.role == "CHIEF") {
            logger.error(
                "You cannot leave an organisation while you are chief! Assign a new chief or delete the organisation from the organisation's profile"
            );
            return;
        }

        OrganisationService.removeMember(
            membership.organisation_id,
            membership._id
        ).then(function (result) {
            var index = ctrl.memberships.indexOf(membership);
            ctrl.memberships.splice(index, 1);
        });
    };

    ctrl.showEditPrivilegesForm = function (membership) {
        var dialog = $uibModal.open({
            backdrop: "static",
            size: "lg",
            template: require("~/app/organisation/edit-membership-privileges/edit-membership-privileges-form.html"),
            controller: "EditMembershipPrivilegesController",
            controllerAs: "vm",
            resolve: {
                currentUser: function () {
                    return ctrl.currentUser;
                },
                membership: function () {
                    return membership;
                },
            },
        });

        dialog.result.then(
            function (membership) {},
            function () {
                // cancelled
            }
        );
    };
}
