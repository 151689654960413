import angular from "angular";

angular
    .module("app.dashboard.latest-project")
    .controller("LatestProjectController", LatestProjectController);

LatestProjectController.$inject = ['$stateParams'];

/* @ngInject */
function LatestProjectController($stateParams) {
    var vm = this;
    vm.message = $stateParams.message;
}

export default LatestProjectController;
