import angular from 'angular';

angular
    .module('app.account')
    .run(appRun);

appRun.$inject = ['routerHelper'];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: 'app.change-password',
            config: {
                url: '/settings/change-password',
                template: '<change-password></change-password>',
                title: 'Change Password'
            }
        }
    ];
}
