import angular from "angular";
import * as template from "./talent-view-block.component.html";
import talentSuggestion from "../../widgets/talent-suggestion/talent-suggestion.html"

var TalentViewBlock = {
    bindings: {
        ids: "=",
        talents: "=",
        currentUser: "=",
        type: "<",
        types: "<",
        categoryTitle: "<",
        categoryFilter: "<",
        user: "=",
    },
    template: template,
    controller: TalentViewBlockController,
};

TalentViewBlockController.$inject = [
    "l10n",
    "_",
    "UserTalentService",
    "toastr",
    "$uibModal",
    "TalentMasterService",
];

/* @ngInject */
function TalentViewBlockController(
    l10n,
    _,
    UserTalentService,
    toastr,
    $uibModal,
    TalentMasterService
) {
    var ctrl = this;
    ctrl.userLanguage = l10n.guessLanguage();
    ctrl.getTalent = function (talentId) {
        return ctrl.talents[talentId];
    };
    ctrl.showTalentSuggestForm = function () {
        var title = ctrl.categoryTitle;
        var category = ctrl.categoryFilter;
        var dialog = $uibModal.open({
            backdrop: "static",
            size: "lg",
            template: talentSuggestion,
            controller: function (
                $translate,
                user,
                l10n,
                UserTalentService,
                me,
                TalentSuggestion,
                logger
            ) {
                var ctrl = this;
                ctrl.user = user;
                ctrl.title = $translate.instant(title);

                ctrl.categoryFilter = category;
                // console.log(currentUser);
                ctrl.close = function () {
                    console.log(getType(title));
                    dialog.close();
                };
                ctrl.newTalents = [];
                ctrl.checkExist = function (talentId) {
                    TalentSuggestion.checkExist(ctrl.user._id, talentId).then(
                        (res) => {
                            if (res && ~user[getType(title)].indexOf(res._id)) {
                                ctrl.newTalents.splice(
                                    ctrl.newTalents.length - 1
                                );
                                logger.error(
                                    $translate.instant(
                                        "TALENT_SUGGESTIONS.TALENT_EXIST"
                                    )
                                );
                            }
                        }
                    );
                };
                ctrl.addTalent = function () {
                    if (
                        typeof ctrl.newTalent == "string" ||
                        ctrl.newTalent instanceof String
                    ) {
                        // Talent is new and was not provided by the autocomplete function
                        var talent = {};
                        talent[l10n.guessLanguage()] = ctrl.newTalent;
                        if (ctrl.categoryFilter)
                            talent.labels = [ctrl.categoryFilter];
                        // First save the talent

                        TalentMasterService.save(talent).then(function (
                            talent
                        ) {
                            ctrl.newTalents.push(talent);
                        });
                    } else {
                        TalentSuggestion.checkExist(
                            ctrl.user._id,
                            ctrl.newTalent._id
                        ).then((res) => {
                            if (res && ~user[getType(title)].indexOf(res._id)) {
                                ctrl.newTalent = "";
                                logger.error(
                                    $translate.instant(
                                        "TALENT_SUGGESTIONS.TALENT_EXIST"
                                    )
                                );
                            } else {
                                ctrl.newTalents.push(ctrl.newTalent);
                                ctrl.newTalents = _.uniqBy(
                                    ctrl.newTalents,
                                    "_id"
                                );
                                ctrl.newTalent = "";
                            }
                        });
                    }

                    //console.log(ctrl.newTalent)
                };
                ctrl.suggestTalent = function () {
                    var suggestion = {
                        user: user._id,
                        requster: me._id,
                        type: getType(title),
                        talents: ctrl.newTalents,
                        why_suggest: ctrl.whySuggest,
                    };
                    TalentSuggestion.create(suggestion).then((res) => {
                        logger.success(
                            $translate.instant("TALENT_SUGGESTIONS.TALENT_ADD")
                        );
                        dialog.close();
                    });
                };
            },
            controllerAs: "vm",
            resolve: {
                me: [
                    "UserService",
                    function (UserService) {
                        return UserService.getMe();
                    },
                ],
                user: ctrl.user,
            },
        });

        dialog.result.then(
            function () {},
            function () {
                // cancelled
            }
        );
    };
}

function getType(type) {
    var str = type.split(".");
    var type = str[str.length - 1];
    // var labels = [];
    //var arrays = ['knowledge', 'general', 'languages_mother_tongue', 'languages_writing', 'languages_understanding', 'languages_speaking', 'tools', 'personality']
    return type.toLowerCase();
}

angular.module("app.widgets").component("talentViewBlock", TalentViewBlock);
