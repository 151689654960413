angular.module("app.core").run(appRun);

/* @ngInject */
function appRun(routerHelper) {
    var otherwise = "/404";
    routerHelper.configureStates(getStates(), otherwise);
}

function getStates() {
    return [
        {
            state: "guest.404",
            config: {
                url: "/404",
                template: require("./404.html"),
                title: "404",
            },
        },
    ];
}
