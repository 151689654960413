import angular from "angular";
import * as template from "./my-organisation.html";

angular.module("app.organisation.my-organisation").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.my-organisation",
            config: {
                url: "/my-organisation/:organisationId",
                template: template,
                controller: "MyOrganisationController",
                controllerAs: "myOrg",
                title: "My organisation",
                resolve: {
                    myOrganisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    features: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getAvailableFeatures(
                                $stateParams.organisationId
                            );
                        },
                    ],
                    memberships: [
                        "currentUser",
                        "UserService",
                        function (currentUser, UserService) {
                            return UserService.getOrganisationMemberships(
                                currentUser._id
                            );
                        },
                    ],
                },
            },
        },
    ];
}
