import './api.module.js';
import './me.service';
import './organisation.service';
import './organisation-invitation.service';
import './project.service';
import './refer.service';
import './search.service';
import './talent.service';
import './upload-picture.service';
import './user.service';
import './user-talent.service';
import './user-talentgroup.service';
import './talent-suggestion.service';
import './dashboard-service';
export default 'blocks.api';
