(function () {
    'use strict';

    angular
        .module('app.widgets')
        .directive('ctDrilldownDatepicker', CtDrilldownDatepicker);

    CtDrilldownDatepicker.$inject = [];

    /* @ngInject */
    function CtDrilldownDatepicker () {
        var directive = {
            restrict: 'E',
            scope: {
                model: '=',
                placeholder: '='
            },
            link: link,
            template: '<input type="text" uib-datepicker-popup="{{format}}" datepicker-options="options" datepicker-mode="options.datepickerMode" is-open="isOpen" ng-model="model" ng-focus="isOpen = true" show-button-bar="false" placeholder="{{placeholder}}" class="form-control"/>'
        };
        return directive;

        function link(scope, element, attrs) {
            scope.format = 'dd/MM/yyyy';
            scope.options = {
                datepickerMode: 'year',
                minMode: 'day'
            }
        }
    }
})();
