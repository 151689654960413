import './widgets.module';
import './ct-drilldown-datepicker/ct-drilldown-datepicker.directive';
import './mongoose-error/mongoose-error.directive';
import './onboarding-talent-toggler/onboarding-talent-toggler.directive';
import './talent/talent.component';
import './talent-multiselect/talent-multiselect.component';
import './talent-state-toggle/talent-state-toggle.directive';
import './talent-typeahead/talent-typeahead.directive';
import './talent-view-block/talent-view-block.component';
import './user-talent-multiselect/user-talent-multiselect.component';
import './user-typeahead/user-typeahead.directive';
import './talent-block/talent-block.component';
import './ui-image/ui-image.directive';
import './user-picture/user-picture.directive';
import './organisation-picture/organisation-picture.directive';
import './talent-suggestion/talent-suggestion.html';
import './talent-block/talent-error.html';
export default 'app.widgets';
