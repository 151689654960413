import angular from "angular";
import template from "./statistics.html";
import controller from "./statistics.controller";

var Statistics = {
    bindings: {
        organisationid: "<",
        memberships: "<",
        planning: "<",
        active: "<",
        passive: "<",

    },
    template: template,
    controller: controller,
};

angular
    .module("app.dashboard.statistics")
    .component("statistics", Statistics);
