import angular from "angular";
import appShellTemplate from "./layout/app-shell.html";
import appNoMenuTemplate from "./layout/app-nomenu.html";
import guestShellTemplate from "./layout/guest-shell.html";

angular.module("app").run(appRun);

/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app",
            config: {
                abstract: true,
                template: appShellTemplate,
                controller: "AppShellController",
                controllerAs: "app",
                resolve: {
                    currentUser: [
                        "UserService",
                        function (UserService) {
                            return UserService.getMe();
                        },
                    ],
                },
            },
        },
        {
            state: "app-nomenu",
            config: {
                abstract: true,
                template: appNoMenuTemplate,
                controller: "AppShellController",
                controllerAs: "app",
                resolve: {
                    currentUser: [
                        "UserService",
                        function (UserService) {
                            return UserService.getMe();
                        },
                    ],
                },
            },
        },
        {
            state: "guest",
            config: {
                abstract: true,
                template: guestShellTemplate,
                controller: "GuestShellController",
                controllerAs: "guest",
            },
        },
    ];
}
