import angular from "angular";
import template from "./user-search.html";

angular.module("app.search.user").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.search-user",
            config: {
                url: "/search/user",
                template: template,
                controller: "UserSearchController",
                controllerAs: "searchCtrl",
                title: "search users",
            },
        },
    ];
}
