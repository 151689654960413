(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('UploadPictureService', UploadPictureService);

    UploadPictureService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG', 'l10n'];

    /* @ngInject */
    function UploadPictureService($http, $q, logger, ENV_CONFIG, l10n) {
        var subPath = 'aws';
        var signedUrlRoute = '/get-picture-signed-url';
        var service = {
            signUrl:signUrl,
            upload:upload,
            removeResume: removeResume
        };

        return service;
        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        function signUrl(query){
            return $http.post(ENV_CONFIG.baseAPI+subPath+signedUrlRoute, query)
             .then(success)
             .catch(fail);
        }
        function upload(data,file){
            var signedUrl = data.signedUrl;
            return $http.put(signedUrl, file).then(success)
                .catch(fail);

        }
        function removeResume(){
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/resume/')
                .then(success)
                .catch(fail);
        }
        
    }

})();
