import angular from "angular";
import template from "./latest-project.html";

angular.module("app.dashboard.latest-project").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.dashboard.latest-project",
            config: {
                url: "/dashboard/:organisationId/latest-project",
                template: template,
                controller: "LatestProjectController",
                controllerAs: "$ctrl",
            },
        },
    ];
}
