(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('ProjectService', ProjectService);

    ProjectService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function ProjectService($http, $q, logger, ENV_CONFIG) {
        const organisationsPath = 'organisations';
        const projectsPath = 'projects';
        const service = {
            save: save,
            update: update,
            getOne: getOne,
            remove: remove,
            search: search,
            getMatchingProjectsForUser: getMatchingProjectsForUser,
            getMatchingConnectionsForProjectRole: getMatchingConnectionsForProjectRole,
            getMatchingConnectionsForProjectRoleHapponomy: getMatchingConnectionsForProjectRoleHapponomy,
            apply: apply,
            duplicate: duplicate,
            getMessages: getMessages,
            getAllMessages: getAllMessages,
            deleteMessage: deleteMessage,
            getAssignedConnections: getAssignedConnections,
            getMyAssignes: getMyAssignes,
            createAssignedConnection: createAssignedConnection,
            removeAssignedConnection: removeAssignedConnection,
            getOrganisationProjectRole: getOrganisationProjectRole,
            createOrganisationProjectRoles: createOrganisationProjectRoles,
            createOrganisationProjectRole: createOrganisationProjectRole,
            deleteOrganisationProjectRole: deleteOrganisationProjectRole,
            updateOrganisationProjectRole: updateOrganisationProjectRole,
            getTemplates,
            getRoles,
            getTemplate,
            duplicateFromTemplate
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        function save(organisationId, project) {
            return $http.post(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath, project)
                .then(success)
                .catch(fail);
        }


        function update(organisationId, data) {
            return $http.put(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + data._id, data)
                .then(success)
                .catch(fail);
        }

        function duplicate(organisationId, oldId, data){
            return $http.post(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + oldId+'/duplicate', data)
                .then(success)
                .catch(fail);
        }

        /**
         * GET one organisation
         *
         * @param organisationId
         * @returns {*}
         */
        function getOne(organisationId, projectId) {
            return $http.get(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + projectId)
            .then(success)
            .catch(fail);
        }

        function remove(organisationId, projectId) {
            return $http.delete(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + projectId)
                .then(success)
                .catch(fail);
        }

        /**
         *
         * @param query
         * @returns {*}
         */
        function search(query) {
            return $http.get(ENV_CONFIG.baseAPI + organisationsPath + '/search', {
                params: {
                    q: '(name:' + query + ')'
                }
            }).then(success).catch(fail);
        }


        /**
         * GET all matching projects for a specific user
         *
         * @param organisationId
         * @returns {*}
         */
        function getMatchingProjectsForUser(organisationId, userId) {
            return $http.get(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/matchingProjects/' + userId)
                .then(success)
                .catch(fail);
        }

        function getMessages(organisationId, projectId, type) {
            var params = {
                includes: ['user']
            };
            if(type) params.type = type;

            return $http.get(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/' + projectId + '/messages',
                {
                    params: params
                })
                .then(success)
                .catch(fail);
        }

        function getAllMessages(organisationId) {
            var params = {
                includes: ['user', 'project']
            };
            return $http.get(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/all-messages',
                {
                    params: params
                })
                .then(success)
                .catch(fail);
        }

        function getAssignedConnections(projectId){
            return $http.get(ENV_CONFIG.baseAPI + projectsPath + '/' + projectId + '/assignees')
                .then(success)
                .catch(fail);
        }
        function getMyAssignes(params){
            return $http.get(ENV_CONFIG.baseAPI + projectsPath + '/assignees', {
                params: params
            })
                .then(success)
                .catch(fail);
        }

        function createAssignedConnection(projectId, assignedConection){
            return $http.post(ENV_CONFIG.baseAPI + projectsPath + '/' + projectId + '/assignees', assignedConection)
                .then(success)
                .catch(fail);
        }

        function removeAssignedConnection(projectId, assignedConectionId){
            return $http.delete(ENV_CONFIG.baseAPI + projectsPath + '/' + projectId + '/assignees/' + assignedConectionId)
                .then(success)
                .catch(fail);
        }

        function getOrganisationProjectRole(organisationId, projectId, params) {
            return $http.get(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + projectId + '/roles',
                {
                    params: params
                })
                .then(success)
                .catch(fail);
        }

        function updateOrganisationProjectRole(organisationId, projectId, role) {
            return $http.put(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + projectId + '/roles/' + role._id, role)
                .then(success)
                .catch(fail);
        }

        function createOrganisationProjectRoles(organisationId, projectId, roles)
        {
            return $http.post(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + projectId + '/roles', roles)
            .then(success)
            .catch(fail);
        }

        function createOrganisationProjectRole(organisationId, projectId, role)
        {
            return $http.post(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + projectId + '/role', role)
            .then(success)
            .catch(fail);
        }

        function deleteOrganisationProjectRole(organisationId, projectId, roleId)
        {
            return $http.delete(ENV_CONFIG.baseAPI + organisationsPath + '/' + organisationId + '/' + projectsPath + '/' + projectId + '/roles/' + roleId)
            .then(success)
            .catch(fail);
        }

        function deleteMessage(organisationId, projectId, messageId){
            return $http.delete(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/' + projectId + '/messages/'+messageId)
                .then(success)
                .catch(fail);
        }

        /**
         * GET all users for a specific project
         *
         * @param organisationId
         * @returns {*}
         */
        function getMatchingConnectionsForProjectRole(organisationId, projectId, roleId, type) {
            return $http.get(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/' + projectId + '/role/' + roleId + '/matching-connections',
            {
                params: { type: type}
            })
                .then(success)
                .catch(fail);
        }

        /**
         * GET all users for a specific project Happonomy
         *
         * @param organisationId
         * @returns {*}
         */
         function getMatchingConnectionsForProjectRoleHapponomy(organisationId, projectId, roleId, type) {
            return $http.get(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/' + projectId + '/role/' + roleId + '/matching-connections-happonomy',
            {
                params: { type: type}
            })
                .then(success)
                .catch(fail);
        }

        function apply(organisationId, projectId, application) {
            return $http.post(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/' + projectId + '/apply', application)
                .then(success)
                .catch(fail);
        }

        function getTemplates(type, labels) {
            let params = {};
            if (type) params.type = type;
            if (labels) params.labels = labels;

            return $http.get(ENV_CONFIG.baseAPI + 'templates', { params })
                .then(success)
                .catch(fail);
        }

        function getRoles(organisationId, projectId) {
            return $http.get(ENV_CONFIG.baseAPI + 'organisations/' + organisationId + '/projects/' + projectId + '/roles')
                .then(success)
                .catch(fail);
        }

        function getTemplate(templateId) {
            return $http.get(`${ENV_CONFIG.baseAPI}templates/${templateId}`)
                .then(success)
                .catch(fail);
        }

        function duplicateFromTemplate(organisationId, templateId){
            return $http.put(`${ENV_CONFIG.baseAPI}templates/clone/${templateId}/${organisationId}`, {})
                .then(success)
                .catch(fail);
        }
    }

})();
