import angular from "angular";
import * as template from "./talent-block.component.html";

var TalentBlock = {
    bindings: {
        ids: "=",
        talents: "=",
        currentUser: "=",
        type: "<",
        types: "<",
        blockTitle: "<",
        categoryFilter: "<",
    },
    template: template,
    controller: TalentBlockController,
};

TalentBlockController.$inject = [
    "$translate",
    "$q",
    "TalentMasterService",
    "l10n",
    "_",
    "UserTalentService",
    "toastr",
    "$uibModal",
    "Util",
];

/* @ngInject */
function TalentBlockController(
    $translate,
    $q,
    TalentMasterService,
    l10n,
    _,
    UserTalentService,
    toastr,
    $uibModal,
    Util
) {
    var ctrl = this;
    var LANGUAGE_MOTHER_TONGUE = "languages_mother_tongue",
        LANGUAGE_SPEAKING = "languages_speaking",
        LANGUAGE_WRITING = "languages_writing";
    var languageLabelsForMotherTongue = [
        "languages_speaking",
        "languages_writing",
        "languages_understanding",
    ];
    var languageLabels = ["languages_understanding"];
    var promise = $q.all([]);
    ctrl.newTalent = null;
    ctrl.userLanguage = l10n.guessLanguage();

    ctrl.toggleEditing = function () {
        ctrl.isEditing = !ctrl.isEditing;
    };
    ctrl.addTalent = function () {
        if (ctrl.newTalent == null || ctrl.newTalent == "") {
            logger.warning(
                "Unable to add talent, something went wrong!",
                ctrl.newTalent
            );
            return;
        } else if (
            typeof ctrl.newTalent == "string" ||
            ctrl.newTalent instanceof String
        ) {
            // Talent is new and was not provided by the autocomplete function
            var talent = {};
            talent["en"] = ctrl.newTalent;
            talent["nl"] = ctrl.newTalent;
            if (ctrl.categoryFilter) talent.labels = [ctrl.categoryFilter];
            // First save the talent
            TalentMasterService.save(talent)
                .then(function (talent) {
                    var newUserTalent = angular.copy(talent);
                    newUserTalent.user_id = ctrl.currentUser._id;
                    newUserTalent.talent_id = talent._id;
                    if (ctrl.type === LANGUAGE_MOTHER_TONGUE) {
                        saveLanguages(
                            languageLabelsForMotherTongue,
                            newUserTalent
                        );
                    }
                    if (
                        ctrl.type === LANGUAGE_SPEAKING ||
                        ctrl.type === LANGUAGE_WRITING
                    ) {
                        saveLanguages(languageLabels, newUserTalent);
                    }

                    UserTalentService.save(
                        ctrl.currentUser._id,
                        newUserTalent,
                        ctrl.type
                    )
                        .then(function (newTalent) {
                            ctrl.talents[newTalent._id] = newTalent;
                            ctrl.currentUser[ctrl.type].push(newTalent._id);
                            ctrl.newTalent = null;
                        })
                        .catch(function (err) {
                            toastr.error(err, "Warning");
                        });
                })
                .catch(function (err) {
                    if (~err.indexOf("OOPS")) {
                        onTalentError(err, ctrl.newTalent);
                        ctrl.newTalent = null;
                    } else toastr.error(err, "Warning");
                });
        } else if (angular.isObject(ctrl.newTalent)) {
            // Talent was hopefully provided by the autocomplete function
            var newUserTalent = angular.copy(ctrl.newTalent);
            delete newUserTalent._id;
            newUserTalent.user_id = ctrl.currentUser._id;
            newUserTalent.talent_id = ctrl.newTalent._id;
            UserTalentService.save(
                ctrl.currentUser._id,
                newUserTalent,
                ctrl.type
            )
                .then(function (newTalent) {
                    ctrl.talents[newTalent._id] = newTalent;
                    ctrl.currentUser[ctrl.type].push(newTalent._id);
                    ctrl.newTalent = null;
                    if (ctrl.type === LANGUAGE_MOTHER_TONGUE) {
                        saveLanguages(
                            languageLabelsForMotherTongue,
                            newUserTalent
                        );
                    }
                    if (
                        ctrl.type === LANGUAGE_SPEAKING ||
                        ctrl.type === LANGUAGE_WRITING
                    ) {
                        saveLanguages(languageLabels, newUserTalent);
                    }
                })
                .catch(function (err) {
                    toastr.warning(err, "Warning");
                });
        }
    };

    ctrl.removeTalent = function (talent) {
        UserTalentService.remove(
            ctrl.currentUser._id,
            talent._id,
            ctrl.type
        ).then(function (result) {
            if (result) {
                delete ctrl.talents[talent._id];
            }

            ctrl.currentUser[ctrl.type] = _.without(
                ctrl.currentUser[ctrl.type],
                talent._id
            );
        });
    };
    function onTalentError(err, item) {
        var modalInstance = $uibModal.open({
            animation: true,
            template: require("~/app/widgets/talent-block/talent-error.html"),
            controller: function (error, item) {
                var vm = this;
                vm.talent = item;
                vm.err = error;
                if (~error.indexOf("LANGUAGE")) {
                    var type = "languages_mother_tongue";
                    vm.category = "LANGUAGE";
                }
                if (~error.indexOf("PERSONALITY")) {
                    var type = "personality";
                    vm.category = "PERSONALITY";
                }
                if (~error.indexOf("SOFTWARE")) {
                    var type = "tools";
                    vm.category = "SOFTWARE";
                }
                if (~error.indexOf("different")) {
                    var type = "general";
                    vm.category = "NONE";
                }
                vm.translatedCategory = $translate.instant(
                    "TALENT_CATEGORY_ERROR." + vm.category
                );
                vm.addTalent = function () {
                    saveNewTalent(item, vm.category, type);
                    modalInstance.close();
                };
                vm.close = function () {
                    modalInstance.close();
                };
            },
            controllerAs: "vm",
            size: "md",
            resolve: {
                error: function () {
                    return err;
                },
                item: function () {
                    return item;
                },
            },
        });
        modalInstance.result.then(
            function (membership) {},
            function () {}
        );
    }

    function saveNewTalent(query, categoryFilter, type) {
        TalentMasterService.search(query, categoryFilter).then(function (
            talents
        ) {
            var talent = talents.data.find(function (elem) {
                return (
                    (elem.nl &&
                        elem.nl.toLowerCase() === query.toLowerCase()) ||
                    (elem.en && elem.en.toLowerCase() === query.toLowerCase())
                );
            });
            var id = ctrl.userId;
            var newUserTalent = angular.copy(talent);
            delete newUserTalent._id;
            newUserTalent.user_id = ctrl.currentUser._id;
            newUserTalent.talent_id = talent._id;
            if (categoryFilter === "LANGUAGE") {
                saveLanguages(languageLabelsForMotherTongue, newUserTalent);
            }
            UserTalentService.save(ctrl.currentUser._id, newUserTalent, type)
                .then(function (newTalent) {
                    ctrl.talents[newTalent._id] = newTalent;
                    ctrl.currentUser[type].push(newTalent._id);
                    ctrl.newTalent = null;
                })
                .catch(function (err) {
                    toastr.warning(err, "Warning");
                });
        });
    }

    function saveLanguages(labels, newUserTalent) {
        angular.forEach(labels, function (label) {
            promise = promise.then(function () {
                UserTalentService.save(
                    ctrl.currentUser._id,
                    newUserTalent,
                    label
                )
                    .then(function (newTalent) {
                        ctrl.talents[newTalent._id] = newTalent;
                        ctrl.currentUser[label].push(newTalent._id);
                        ctrl.newTalent = null;
                    })
                    .catch(function (err) {
                        toastr.error(err, "Warning");
                    });
            });
        });
    }
}

angular.module("app.widgets").component("talentBlock", TalentBlock);
