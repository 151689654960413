import angular from "angular";

angular
    .module("app.organisation.manage-organisation")
    .controller("ManageOrganisationController", ManageOrganisationController);

ManageOrganisationController.$inject = [
    "$state",
    "$stateParams",
    "$q",
    "logger",
    "currentUser",
    "myOrganisation",
    "features",
    "memberships",
    "invitations",
    "messages",
    "OrganisationInvitationService",
    "OrganisationService",
    "$uibModal",
    "$http",
    "UploadPictureService",
    "ProjectService",
];

/* @ngInject */
function ManageOrganisationController(
    $state,
    $stateParams,
    $q,
    logger,
    currentUser,
    myOrganisation,
    features,
    memberships,
    invitations,
    messages,
    OrganisationInvitationService,
    OrganisationService,
    $uibModal,
    $http,
    UploadPictureService,
    ProjectService
) {
    var ctrl = this;
    ctrl.currentUser = currentUser;
    ctrl.organisation = myOrganisation;
    ctrl.memberships = memberships;
    ctrl.invitations = invitations;
    ctrl.messages = messages;
    ctrl.features = features;
    ctrl.isEditing = false;
    ctrl.newInvitation = {
        selectedUser: null,
    };
    ctrl.visibleProjectsByState = ["DRAFT", "PUBLISHED"];
    ctrl.stateOptions = [
        { name: "DRAFT", description: "Draft" },
        { name: "PUBLISHED", description: "Published" },
        { name: "ARCHIVED", description: "Archived" },
    ];

    ctrl.filterByState = function (project) {
        return ctrl.visibleProjectsByState.indexOf(project.state) !== -1;
    };

    ctrl.toggleProjectStateVisible = function (state) {
        var idx = ctrl.visibleProjectsByState.indexOf(state);
        // is currently selected
        if (idx > -1) {
            ctrl.visibleProjectsByState.splice(idx, 1);
        } else {
            ctrl.visibleProjectsByState.push(state);
        }
    };

    if (features.projects) {
        OrganisationService.getProjects($stateParams.organisationId).then(
            function (projects) {
                ctrl.projects = projects;
            }
        );
    }

    ctrl.editForm = {
        organisation: angular.copy(ctrl.organisation, {}),
    };

    ctrl.setEditForm = function (organisationForm) {
        if (organisationForm) {
            ctrl.organisationForm = organisationForm;
        }
    };

    ctrl.toggleEditing = function () {
        ctrl.isEditing = !ctrl.isEditing;
    };
    ctrl.toggleEssentialEditing = function () {
        ctrl.isEssentialEditing = !ctrl.isEssentialEditing;
    };
    ctrl.save = function () {
        if (
            ctrl.organisationForm &&
            !ctrl.organisationForm.$invalid &&
            ctrl.isEssentialEditing
        ) {
            OrganisationService.update(
                myOrganisation._id,
                ctrl.editForm.organisation
            ).then(function (organisation) {
                ctrl.organisation = organisation;
                ctrl.isEditing = !ctrl.isEditing;
                ctrl.isEssentialEditing = !ctrl.isEssentialEditing;
            });
        } else {
            logger.warning(
                "Cannot update organisation with the current state",
                ctrl.organisationForm
            );
        }
    };

    ctrl.cancel = function () {
        ctrl.editForm = {
            organisation: angular.copy(ctrl.organisation, {}),
        };
        ctrl.isEditing = !ctrl.isEditing;
        ctrl.isEssentialEditing = !ctrl.isEssentialEditing;
    };

    ctrl.invite = function () {
        if (
            !ctrl.newInvitation.selectedUser ||
            !ctrl.newInvitation.selectedUser._id
        ) {
            logger.warning(
                "No user selected to invite! Select a user before sending an invitation."
            );
            return;
        }

        OrganisationInvitationService.invite(
            ctrl.organisation._id,
            ctrl.newInvitation.selectedUser._id,
            { includes: ["user"] }
        ).then(function (invitation) {
            ctrl.invitations.push(invitation);
            ctrl.newInvitation.selectedUser = null;
        });
    };

    ctrl.reject = function (invitation) {
        if (!invitation) {
            logger.error(
                "Expected an invitation to remove. No invitation was passed to remove."
            );
            return;
        }

        OrganisationInvitationService.reject(
            ctrl.organisation._id,
            invitation._id
        ).then(function (invitation) {
            var index = ctrl.invitations.indexOf(invitation);
            ctrl.invitations.splice(index, 1);
        });
    };

    ctrl.removeMembership = function (membership) {
        if (membership.role == "CHIEF") {
            logger.error(
                "You cannot leave an organisation while you are chief! Assign a new chief or delete the organisation from the organisation's profile"
            );
            return;
        }

        OrganisationService.removeMember(
            membership.organisation_id,
            membership._id
        ).then(function (result) {
            var index = ctrl.memberships.indexOf(membership);
            ctrl.memberships.splice(index, 1);
        });
    };

    ctrl.showEditPrivilegesForm = function (membership) {
        var dialog = $uibModal.open({
            backdrop: "static",
            size: "lg",
            template: require("~/app/organisation/edit-membership-privileges/edit-membership-privileges-form.html"),
            controller: "EditMembershipPrivilegesController",
            controllerAs: "vm",
            resolve: {
                currentUser: function () {
                    return ctrl.currentUser;
                },
                membership: function () {
                    return membership;
                },
            },
        });

        dialog.result.then(
            function (membership) {},
            function () {
                // cancelled
            }
        );
    };

    ctrl.deleteMessage = function (message) {
        var organisationId = ctrl.organisation._id;
        var projectId = message.project._id;
        var messageId = message._id;

        ProjectService.deleteMessage(organisationId, projectId, messageId).then(
            function (deletedMessage) {
                ctrl.messages.splice(ctrl.messages.indexOf(message), 1);
            }
        );
    };

    ctrl.onFileSelect = function (files) {
        var file = files[0];
        if (file) {
            var query = {
                id: ctrl.currentUser._id,
                type: file.type,
                name: file.name,
            };
            UploadPictureService.signUrl(query).then(function (data) {
                UploadPictureService.upload(data, files[0]).then(function () {
                    ctrl.organisation.logo_url = data.getUrl;
                    ctrl.editForm.organisation.logo_url = data.getUrl;
                });
            });
        } else return;
    };

    ctrl.showOrganisationRemoveDialog = function () {
        var dialog = $uibModal.open({
            backdrop: "static",
            size: "md",
            template: require("~/app/organisation/shared/organisation-remove-dialog.html"),
            controller: function (OrganisationService) {
                var vm = this;
                vm.confirm = function () {
                    OrganisationService.remove(ctrl.organisation._id)
                        .then(function (res) {
                            dialog.close();
                            $state.go("app.my-profile");
                        })
                        .catch(function (err) {
                            vm.error = err;
                        });
                };
                vm.cancel = function () {
                    dialog.close();
                };
            },
            controllerAs: "vm",
            resolve: {},
        });
    };
}
