(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('ReferService', ReferService);

    ReferService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function ReferService($http, $q, logger, ENV_CONFIG) {
        var subPath = 'refer';

        var service = {
            sendReferEmail: sendReferEmail
        };

        return service;
        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }


        function sendReferEmail(data){
            return $http.post(ENV_CONFIG.baseAPI + subPath+'/friends', data)
                .then(success)
                .catch(fail);

        }
    }

})();
