import angular from 'angular';

angular
    .module('app.profile.feedback')
    .controller('feedbackController', feedbackController);

feedbackController.$inject = ['$scope', '$q', 'logger', 'UserService', 'currentUser','$translate', 'TalentSuggestion', 'Util',
    'OrganisationInvitationService', 'ProjectService', 'AuthService', 'MeService', 'UploadPictureService', '$state', 'memberships', 'suggestions'];

/* @ngInject */
function feedbackController($scope, $q, logger, UserService, currentUser, $translate, TalentSuggestion, Util,
    OrganisationInvitationService, ProjectService, AuthService, MeService, UploadPictureService, $state, memberships, suggestions) {
    var ctrl = this;
    ctrl.currentUser = currentUser;
    ctrl.isEditing = false;
    ctrl.memberships = memberships;
    ctrl.suggestions = suggestions;
    
    ctrl.acceptSuggestion = function(suggestion){
        TalentSuggestion.accept(suggestion._id).then((res)=>{
            ctrl.suggestions.splice(ctrl.suggestions.indexOf(suggestion),1);
            logger.success($translate.instant('TALENT_SUGGESTIONS.TALENT_CONFIRM'));

        })
    }
    ctrl.rejectSuggestion = function (suggestion) {
        TalentSuggestion.reject(suggestion._id).then((res) => {
            ctrl.suggestions.splice(ctrl.suggestions.indexOf(suggestion), 1);
        })
    }
}
