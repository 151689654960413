(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('GuestShellController', GuestShellController);

    GuestShellController.$inject = ['$rootScope', '$timeout', 'config', 'logger','ngDrift'];
    /* @ngInject */
    function GuestShellController($rootScope, $timeout, config, logger, ngDrift) {
        var vm = this;
        $rootScope.currentYear = new Date().getFullYear();
        ngDrift.show();
    }
})();
