import angular from "angular";
import template from "./navigation.html";
import navSelectOrganisationTemplate from "./nav-select-organisation-dialog.html";

angular.module("app.layout").directive("navigation", Navigation);

Navigation.$inject = ["$q", "$state", "AuthService", "$uibModal"];

/* @ngInject */
function Navigation($q, $state, AuthService, $uibModal) {
    var directive = {
        bindToController: true,
        controller: NavigationController,
        controllerAs: "nav",
        restrict: "EA",
        scope: {},
        template: template,
    };

    /* @ngInject */
    function NavigationController($scope) {
        var ctrl = this;

            $q.when(AuthService.getCurrentUserMemberships()).then(function (memberships) {
                ctrl.memberships = memberships;
            });
        
            $scope.$watch(
                angular.bind(this, function () {
                    return AuthService.currentUserMemberships;
                }),
                function (newVal) {
                    if (newVal && newVal.length) {
                        ctrl.chiefMemberships = newVal.filter(function (membership) {
                            return membership.role === "CHIEF";
                        });
                    }
                }
            );
            

        ctrl.goManageOrganisations = async function () {
            await AuthService.refreshCurrentUserMemberships();
            if (ctrl.chiefMemberships.length > 1) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    template: navSelectOrganisationTemplate,
                    controller: function ($uibModalInstance, chiefMemberships) {
                        var vm = this;
                        vm.chiefMemberships = chiefMemberships;
                        vm.selectOrganisation = function (membership) {
                            $uibModalInstance.close(membership);
                        };
                    },
                    controllerAs: "vm",
                    size: "sm",
                    resolve: {
                        chiefMemberships: function () {
                            return ctrl.chiefMemberships;
                        },
                    },
                });

                modalInstance.result.then(
                    function (membership) {
                        $state.go("app.dashboard", {
                            organisationId: membership.organisation_id,
                        });
                    },
                    function () {}
                );
            } else if (ctrl.chiefMemberships.length === 1) {
                $state.go("app.dashboard", {
                    organisationId: ctrl.chiefMemberships[0].organisation_id,
                });
            }
        };
    }

    return directive;
}
