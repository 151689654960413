import angular from "angular";
import * as template from "./refer-friends.html";

angular.module("app.refer-friends").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.refer-friends",
            config: {
                url: "/refer",
                template: template,
                controller: "ReferController",
                controllerAs: "vm",
                title: "Refer a friends",
                resolve: {
                    currentUser: [
                        "UserService",
                        function (UserService) {
                            return UserService.getMe();
                        },
                    ],
                    //talents: ['Session', 'API', function(Session, API) {
                    //    return API.one('members', Session.getUserId()).one('talents').get({ s: 100 });
                    //}],
                    //talentgroups: ['Session', 'API', function(Session, API) {
                    //    return API.one('members', Session.getUserId()).one('talentgroups').get();
                    //}]
                },
            },
        },
    ];
}
