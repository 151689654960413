let organisationPicture = () => {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {
            let src = attr.url || require(`Images/placeholder/organisation.png`);
            element.attr('src', src);
            attr.$observe('url', function(newUrl){
                element.attr('src', newUrl || src);
            })

        }
    };
};

organisationPicture.$inject = [];

angular.module('app.widgets').directive('organisationPicture', organisationPicture);

