import angular from "angular";
import * as template from "./login.html";

angular.module("app.login").run(appRun);

appRun.$inject = ["$rootScope", "$state", "routerHelper", "AuthService"];
/* @ngInject */
function appRun($rootScope, $state, routerHelper, AuthService) {
    $rootScope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
            if (!toState.isPublic) {
                if (!AuthService.isLoggedIn()) {
                    if (toState.name === "guest.login") return;
                    $state.go("guest.login", {
                        action: toParams.action,
                        invitationId: toParams.invitationId,
                    });
                    event.preventDefault();
                }
            }
        }
    );

    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "guest.login",
            config: {
                url: "/login?message&action&invitationId",
                template: template,
                controller: "LoginController",
                controllerAs: "loginCtrl",
                isPublic: true,
                title: "Login",
                pageClass: "page-login",
                hideHeader: true,
            },
        },
    ];
}
