import angular from 'angular';

angular
    .module('app.account')
    .controller('ResetPasswordRequestController', ResetPasswordRequestController);

ResetPasswordRequestController.$inject = ['AuthService', '$stateParams', '$state'];

/* @ngInject */
function ResetPasswordRequestController(AuthService, $stateParams, $state) {
    var ctrl = this;

    ctrl.user = {
        email: '',
        token: '',
        newPassword: '',
        confirmPassword: ''
    };

    // If stateParams has an e-mail and token we are in the second part of the password reset cycle
    ctrl.user.email = $stateParams.email;
    ctrl.user.token = $stateParams.token;


    ctrl.errors = {
        other: undefined
    };

    ctrl.message = '';
    ctrl.submitted = false;

    ctrl.resetPasswordRequest = function(form) {
        ctrl.submitted = true;

        if(form.$valid) {
            AuthService.resetPasswordRequest(ctrl.user.email)
                .then(function(res) {
                    ctrl.message = res.data.message;
                })
                .catch(function(err) {
                    if(err && err.data && err.data.message) {
                        ctrl.message = err.data.message
                    } else {
                        ctrl.message = 'An unknown error has occurred. Please contact support.'
                    }
                });
        }
    };

    ctrl.resetPassword = function(form) {
        if(form.$valid) {
            AuthService.resetPassword(ctrl.user.email, ctrl.user.token, ctrl.user.newPassword)
                .then(function(res) {
                    ctrl.message = res.data.message;
                    $state.go('guest.login', {message: res.data.message});
                })
                .catch(function(err) {
                    if(err && err.data && err.data.message) {
                        ctrl.message = err.data.message
                    } else {
                        ctrl.message = 'An unknown error has occurred. Please contact support.'
                    }
                });
        }
    };
}
