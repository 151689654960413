import angular from "angular";
import template from "./talent-multiselect.component.html";
import errorTemplate from "../talent-block/talent-error.html";

/**
 * categoryFilter: 'LANGUAGE', 'PERSONALITY', 'SOFTWARE'
 *
 * @type {{bindings: {model: string, placeholder: string, categoryFilter: string}, template: string, controller: TalentMultiselectController}}
 */
const TalentMultiselect = {
    bindings: {
        data: "=",
        model: "=",
        placeholder: "<",
        categoryFilter: "<",
        type: "=",
        suggestionCheck: "=",
    },
    template: template,
    controller: TalentMultiselectController,
};

TalentMultiselectController.$inject = [
    "TalentMasterService",
    "l10n",
    "toastr",
    "Util",
    "_",
    "$uibModal",
    "$translate",
];

/* @ngInject */
function TalentMultiselectController(
    TalentMasterService,
    l10n,
    toastr,
    Util,
    _,
    $uibModal,
    $translate
) {
    var ctrl = this;
    var LANGUAGE_MOTHER_TONGUE = "languages_mother_tongue",
        LANGUAGE_SPEAKING = "languages_speaking",
        LANGUAGE_WRITING = "languages_writing";
    var languageLabelsForMotherTongue = [
        "languages_speaking",
        "languages_writing",
        "languages_understanding",
    ];
    var languageLabels = ["languages_understanding"];
    ctrl.onSelectCallback = function (item) {
        if (item.isNew) {
            var masterTalent = angular.copy(item);
            delete masterTalent._id;
            delete masterTalent.isNew;

            TalentMasterService.save(masterTalent)
                .then(function (talent) {
                    if (ctrl.data && ctrl.type === LANGUAGE_MOTHER_TONGUE) {
                        saveLanguages(languageLabelsForMotherTongue, talent);
                    } else if (
                        ctrl.data &&
                        (ctrl.type === LANGUAGE_SPEAKING ||
                            ctrl.type === LANGUAGE_WRITING)
                    ) {
                        saveLanguages(languageLabels, talent);
                    }

                    talent.talent_id = talent._id;
                    delete talent._id;
                    ctrl.model.splice(ctrl.model.indexOf(item), 1);
                    ctrl.model.push(talent);
                })
                .catch(function (err) {
                    if (~err.indexOf("OOPS")) {
                        if (!ctrl.suggestionCheck) {
                            onTalentError(err, item);
                        } else {
                            toastr.error(
                                $translate.instant("ONBOARDING.ERROR.TITLE"),
                                "Error"
                            );
                        }
                    } else toastr.error(err, "Error");
                    ctrl.model.splice(ctrl.model.indexOf(item), 1);
                });
        } else {
            if (ctrl.data && ctrl.type === LANGUAGE_MOTHER_TONGUE) {
                saveLanguages(languageLabelsForMotherTongue, item);
            } else if (
                ctrl.data &&
                (ctrl.type === LANGUAGE_SPEAKING ||
                    ctrl.type === LANGUAGE_WRITING)
            ) {
                saveLanguages(languageLabels, item);
            }
            if (ctrl.suggestionCheck) {
                ctrl.suggestionCheck(item._id);
            }
            item.talent_id = item._id;
            delete item._id;
            ctrl.model = _.uniq(ctrl.model, "_id");
        }
    };

    ctrl.searchForTalent = function (query) {
        if (!query) return;

        TalentMasterService.search(query, ctrl.categoryFilter).then(function (
            result
        ) {
            var combinedResult = result.data;
            var currentLanguage = l10n.getLanguage();
            var matchingQuery = result.data.filter(function (element) {
                return (
                    element[currentLanguage] &&
                    element[currentLanguage].toLowerCase() ===
                        query.toLowerCase()
                );
            });

            if (!result.data.length || matchingQuery.length === 0) {
                var userInputItem = {
                    _id: query,
                    en: query,
                    nl: query,
                    isNew: true,
                };

                // Only add label when creating for a category that actually uses labels...
                if (ctrl.categoryFilter)
                    userInputItem.labels = [ctrl.categoryFilter];

                combinedResult = [userInputItem].concat(combinedResult);
            }
            ctrl.results = combinedResult;
        });
    };

    function onTalentError(err, item) {
        var modalInstance = $uibModal.open({
            animation: true,
            template: errorTemplate,
            controller: function (error, item) {
                var vm = this;
                vm.talent = item._id;
                vm.err = error;
                if (~error.indexOf("LANGUAGE")) {
                    var type = "languages_mother_tongue";
                    vm.category = "LANGUAGE";
                }
                if (~error.indexOf("PERSONALITY")) {
                    var type = "personality";
                    vm.category = "PERSONALITY";
                }
                if (~error.indexOf("SOFTWARE")) {
                    var type = "tools";
                    vm.category = "SOFTWARE";
                }
                if (~error.indexOf("different")) {
                    var type = "general";
                    vm.category = "NONE";
                }
                vm.translatedCategory = $translate.instant(
                    "TALENT_CATEGORY_ERROR." + vm.category
                );
                vm.addTalent = function () {
                    saveNewTalent(item._id, vm.category, type);
                    modalInstance.close();
                };
                vm.close = function () {
                    modalInstance.close();
                };
            },
            controllerAs: "vm",
            size: "md",
            resolve: {
                error: function () {
                    return err;
                },
                item: function () {
                    return item;
                },
            },
        });
        modalInstance.result.then(
            function (membership) {},
            function () {}
        );
    }

    function saveNewTalent(query, categoryFilter, type) {
        TalentMasterService.search(query, categoryFilter).then(function (
            talents
        ) {
            var talent = talents.data.find(function (elem) {
                return (
                    (elem.nl &&
                        elem.nl.toLowerCase() === query.toLowerCase()) ||
                    (elem.en && elem.en.toLowerCase() === query.toLowerCase())
                );
            });
            ctrl.data[type].push(talent);
            ctrl.data[type] = _.uniqBy(ctrl.data[type], "_id");
            if (categoryFilter === "LANGUAGE") {
                saveLanguages(languageLabelsForMotherTongue, talent);
            }
        });
    }

    function saveLanguages(labels, talent) {
        angular.forEach(labels, function (label) {
            var index = Util.findIndexByAttribute(
                ctrl.data[label],
                "_id",
                talent._id
            );
            if (!index && index != 0) {
                ctrl.data[label].push(talent);
            }
        });
    }
}

angular.module("app.widgets").component("talentMultiselect", TalentMultiselect);
