import angular from "angular";
import template from "./edit-talent-form.html";

angular.module("app.talent.talent-pool").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.edit-talent",
            config: {
                url: "/edit-talent/:talentId",
                template: template,
                controller: "EditTalentFormController",
                controllerAs: "vm",
                title: "Edit talent",
                resolve: {
                    talent: [
                        "$stateParams",
                        "TalentMasterService",
                        function ($stateParams, TalentMasterService) {
                            return TalentMasterService.getOne(
                                $stateParams.talentId
                            );
                        },
                    ],
                },
            },
        },
    ];
}
