import angular from 'angular';

angular
    .module('blocks.l10n')
    .constant('LOCALES', {
        'locales': {
            'nl': 'Nederlands',
            'en': 'English'
        },
        'preferredLocale': 'nl'
    });
