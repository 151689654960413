import angular from 'angular';

let core = angular.module('app.core');

core.config(toastrConfig);

toastrConfig.$inject = ['toastr'];

/* @ngInject */
function toastrConfig(toastr) {
    toastr.options.timeOut = 4000;
    toastr.options.positionClass = 'toast-bottom-right';
}

let config = {
    appErrorPrefix: '[TF Error] ',
    appTitle: 'Tribeforce'
};

core.value('config', config);

core.config(configure);

configure.$inject = ['ENV_CONFIG', '$logProvider', 'routerHelperProvider', 'exceptionHandlerProvider'];
/* @ngInject */
function configure(ENV_CONFIG, $logProvider, routerHelperProvider, exceptionHandlerProvider) {
    if ($logProvider.debugEnabled) {
        $logProvider.debugEnabled(true);
    }
    exceptionHandlerProvider.configure(config.appErrorPrefix);
    routerHelperProvider.configure({ docTitle: config.appTitle + ': ' });
}

// Check if the protocol is HTTP
if (window.location.protocol === 'http:') {
    // Redirect to the same address, but using the HTTPS protocol
    window.location.href = window.location.href.replace(/^http:/, 'https:');
}
