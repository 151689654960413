import angular from "angular";
import * as template from "./register.html";
angular.module("app.account.register").run(appRun);

appRun.$inject = [
    "$rootScope",
    "$state",
    "routerHelper",
    "AuthService",
    "$stateParams",
];
/* @ngInject */
function appRun($rootScope, $state, routerHelper, AuthService, $stateParams) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "guest.register",
            config: {
                url: "/register?token&email",
                template: template,
                controller: "AccountRegisterController",
                controllerAs: "vm",
                isPublic: true,
                title: "Register",
                hideHeader: true,
                resolve: {
                    passiveUser: [
                        "$stateParams",
                        "UserService",
                        function ($stateParams, UserService) {
                            if ($stateParams.token)
                                return UserService.searchByToken(
                                    $stateParams.token
                                ).then(function (user) {
                                    user.token = $stateParams.token;
                                    return user;
                                });
                            else return false;
                        },
                    ],
                },
            },
        },
    ];
}
