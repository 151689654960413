import angular from 'angular';

angular
    .module('app.project')
    .controller('ViewTemplateController', ViewTemplateController);

ViewTemplateController.$inject = ['$rootScope', '$scope', '$q', 'logger', 'l10n', 'currentUser', 'template', 'organisation', 'ProjectService', '$translate', '$state','Util'];

/* @ngInject */
function ViewTemplateController($rootScope, $scope, $q, logger, l10n, currentUser, template, organisation, ProjectService, $translate, $state, Util) {

    const ctrl = this;

    ctrl.isEditing = false;
    ctrl.userLanguage = l10n.guessLanguage();
    ctrl.currentUser = currentUser;
    let DUPLICATE = $translate.instant('PROJECT.DUPLICATE');
    ctrl.project = template;
    ctrl.projectRoles = template.roles;
    ctrl.selectedRole = template.roles.length ? template.roles[0] : null;
    ctrl.matchingFor = ctrl.selectedRole;
    ctrl.organisation = organisation;
    ctrl.application = {};
    ctrl.contactFormVisible = false;

    if ($state.current.name === 'app.manager-view-project') {
        ctrl.viewer = 'manager';
    } else if ($state.current.name === 'app.view-project') {
        ctrl.viewer = 'enduser';
    }

    ctrl.setActiveRole = function(role){
        ctrl.selectedRole = role;
        ctrl.matchingFor = role;
        ctrl.getMatchingFor(role._id, ctrl.connectionType);
    };

    ctrl.selectedRoleTalentCount = _(ctrl.selectedRole)
        .chain()
        .pick('knowledge', 'general', 'languages_writing', 'languages_understanding', 'languages_speaking', 'tools', 'personality')
        .values()
        .reduce(function(memo, collection){ return memo + collection.length; }, 1)
        .value();

    $rootScope.$on('$translateChangeSuccess', function () {
        DUPLICATE = $translate.instant('PROJECT.DUPLICATE');
    });

    if(ctrl.selectedRole !== null && ctrl.viewer === 'manager') {
        ctrl.getMatchingFor(ctrl.selectedRole._id);
    }

    ctrl.copyToOrg = function(templateId) {
        ProjectService.duplicateFromTemplate(ctrl.organisation._id, templateId).then((result) => {
            $state.go('app.edit-project', { organisationId: ctrl.organisation._id, projectId: result._id });
        });
    }
}
