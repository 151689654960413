import './manage-organisation.module';
import './manage-organisation.route';
import './manage-organisation.controller';
import './common/manage-menu.component';
import './find-members/find-members.component';
import './invite-tribeforce-users/invite-tribeforce-users.component';
import './invite-tribeforce-users/invite-tribeforce-users.controller';
import './manage-members/manage-members.route';
import './manage-members/manage-members.controller';
import './manage-members/error-owner.html';
import './manage-members/manage-members-handover-chief.html';
import './manage-projects/manage-projects.route';
import './manage-projects/manage-projects.controller';
import './manage-users/manage-users.route';
import './manage-users/manage-users.controller';

export default 'app.organisation.manage-organisation';
