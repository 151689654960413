(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('UserTalentService', UserTalentService);

    UserTalentService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function UserTalentService($http, $q, logger, ENV_CONFIG) {
        var prefixPath = 'users';
        var subPath = 'talents';

        var service = {
            save: save,
            update: update,
            remove: remove,
            getOne: getOne,
            getAll: getAll
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        /**
         * Add new Talent for given User
         *
         * @param {number} userId
         * @param {Object} userTalent
         * @returns {*}
         */
        function save(userId, userTalent, category) {
            return $http.post(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath + '/' + category, userTalent)
                .then(success)
                .catch(fail);
        }

        function update(userId, userTalent) {
            return $http.put(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath + '/' + userTalent._id, userTalent)
                .then(success)
                .catch(fail);
        }

        function remove(userId, user_talent_id, category) {
            return $http.delete(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath + '/' + user_talent_id + '/' + category)
                .then(success)
                .catch(fail);
        }

        /**
         * GET one talent of given user
         *
         * @param userId
         * @returns {*}
         */
        function getOne(userId, userTalentId) {
            return $http.get(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath + '/' + userTalentId)
                .then(success)
                .catch(fail);
        }

        /**
         * GET all of given users' talents
         *
         * @param userId
         * @returns {*}
         */
        function getAll(userId) {
            return $http.get(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath)
                .then(success)
                .catch(fail);
        }

    }

})();
