import angular from "angular";
import template from "./public-organisation.html";

angular.module("app.organisation.public-organisation").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.public-organisation",
            config: {
                url: "/organisation/:organisationId",
                template: template,
                controller: "PublicOrganisationController",
                controllerAs: "pubOrg",
                title: "Organisation",
                resolve: {
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                },
            },
        },
    ];
}
