import angular from 'angular';
import appCore from '../core';
import appWidgets from '../widgets';
import statistics from './components/statistics';
import recentActions from './components/recent-actions';
import latestProject from './components/latest-project';

angular.module('app.dashboard', [
    appCore,
    appWidgets,
    statistics,
    recentActions,
    latestProject
]);
