import angular from 'angular';
import angularAnimate from 'angular-animate';
import angularSanitize from 'angular-sanitize';
import appConfig from '../config';
import blocksException from '../blocks/exception';
import blocksLogger from '../blocks/logger';
import blocksRouter from '../blocks/router';
import blocksApi from '../blocks/api';
import blocksAuth from '../blocks/auth';
import blocksL10N from '../blocks/l10n';
import blockUtilities from '../blocks/util';
import uiRouter from 'angular-ui-router';
import formly from 'angular-formly'
import formlyBootstrap from 'angular-formly-templates-bootstrap'

angular
    .module('app.core', [
        appConfig,
        angularAnimate,
        angularSanitize,
        blocksException,
        blocksLogger,
        blocksRouter,
        blocksApi,
        blocksAuth,
        blocksL10N,
        blockUtilities,
        uiRouter,
        formly,
        formlyBootstrap
    ]);
