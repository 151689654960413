import angular from "angular";
import template from "./project-message-block.component.html";

var ProjectMessageBlock = {
    bindings: {
        title: "<",
        emptyPlaceholder: "<",
        data: "=",
    },
    template: template,
    controller: ProjectMessageBlockController,
};

ProjectMessageBlockController.$inject = ["ProjectService"];

/* @ngInject */
function ProjectMessageBlockController(ProjectService) {
    var ctrl = this;

    ctrl.deleteMessage = function (message) {
        var projectId = message.project_id;
        var organisationId = message.project.organisation_id;
        var messageId = message._id;

        ProjectService.deleteMessage(organisationId, projectId, messageId).then(
            function (deletedMessage) {
                ctrl.data.splice(ctrl.data.indexOf(message), 1);
            }
        );
    };
}

angular
    .module("app.project.manager-view-project")
    .component("projectMessageBlock", ProjectMessageBlock);
