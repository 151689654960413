(function () {
    "use strict";

    angular.module("app.layout").directive("htTopNav", htTopNav);

    htTopNav.$inject = ["$q", "$state", "AuthService"];

    /* @ngInject */
    function htTopNav($q, $state, AuthService) {
        var directive = {
            bindToController: true,
            controller: TopNavController,
            controllerAs: "nav",
            restrict: "EA",
            scope: {},
            template: require("~/app/layout/ht-top-nav.html"),
        };

        /* @ngInject */
        function TopNavController() {
            var ctrl = this;

            $q.when(AuthService.getCurrentUser()).then(function (currentUser) {
                ctrl.currentUser = currentUser;
            });

            ctrl.logout = function () {
                AuthService.logout();
                $state.go("guest.login");
            };

            ctrl.getClass = function (path, clazz) {
                if ($location.path().substr(0, path.length) == path) {
                    return clazz;
                } else {
                    return "";
                }
            };
        }

        return directive;
    }
})();
