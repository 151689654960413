import angular from "angular";

angular.module("app.layout").directive("header", Header);

Header.$inject = [
    "$q",
    "$state",
    "AuthService",
    "$localStorage",
    "$uibModal",
    "UserService",
];

/* @ngInject */
function Header(
    $q,
    $state,
    AuthService,
    $localStorage,
    $uibModal,
    UserService
) {
    var directive = {
        bindToController: true,
        controller: HeaderController,
        controllerAs: "nav",
        restrict: "EA",
        scope: {},
        template: require("./header.html"),
    };

    HeaderController.$inject = ["$scope", "UserService"];

    /* @ngInject */
    function HeaderController($scope, UserService) {
        var ctrl = this;

        ctrl.searchQuery = "";

        $scope.$watch(
            function () {
                return $localStorage.currentUser;
            },
            function (newVal, oldVal) {
                if (oldVal !== newVal && newVal === undefined) {
                    console.log("It is undefined");
                } else {
                    ctrl.currentUser = $localStorage.currentUser;
                }
            }
        );

        ctrl.logout = function () {
            AuthService.logout();
            $state.go("guest.login");
        };

        ctrl.goSearch = function () {
            $state.go("app.global-search-results", { query: ctrl.searchQuery });
        };

        ctrl.showProfileRemoveDialog = function () {
            var dialog = $uibModal.open({
                backdrop: "static",
                size: "md",
                template: require("~/app/profile/shared/profile-remove-dialog.html"),
                controller: function (UserService) {
                    var vm = this;
                    vm.confirm = function () {
                        UserService.remove(ctrl.currentUser._id)
                            .then(function (res) {
                                AuthService.logout();
                                $state.go("guest.login");
                                dialog.close();
                            })
                            .catch(function (err) {
                                vm.error = err;
                            });
                    };
                    vm.cancel = function () {
                        dialog.close();
                    };
                },
                controllerAs: "vm",
                resolve: {},
            });
        };
    }

    return directive;
}
