import angular from "angular";
import template from "./manage-menu.html";

let ManageOrganisationMenu = {
    bindings: {
        organisationId: "<",
    },
    template: template,
};

angular
    .module("app.organisation.manage-organisation")
    .component("manageOrganisationMenu", ManageOrganisationMenu);
