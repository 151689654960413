import angular from "angular";
import template from "./find-members.html";

let FindMembers = {
    bindings: {
        organisationId: "<",
        owner: "=",
    },
    template: template,
    controller: "FindMembersController",
};

angular
    .module("app.organisation.manage-organisation")
    .component("findMembers", FindMembers);
