import angular from "angular";
import template from "./recent-actions.html";
import controller from "./recent-actions.controller";

var RecentActions = {
    bindings: {
        recentactions: "<",
    },
    template: template,
    controller: controller,
};

angular
    .module("app.dashboard.recent-actions")
    .component("recentActions", RecentActions);
