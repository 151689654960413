import angular from "angular";
import template from "./talent-typeahead.html";

angular.module("app.widgets").directive("talentTypeahead", TalentTypeahead);

TalentTypeahead.$inject = [];

/* @ngInject */
function TalentTypeahead() {
    var directive = {
        scope: {
            model: "=",
            placeholder: "=",
            onSelect: "&",
            categoryFilter: "<",
        },
        template: template,
        controller: TalentTypeaheadController,
        controllerAs: "vm",
        bindToController: true,
        restrict: "E",
    };

    return directive;
}

TalentTypeaheadController.$inject = ["TalentMasterService"];

/* @ngInject */
function TalentTypeaheadController(TalentMasterService) {
    var ctrl = this;

    ctrl.searchForTalent = function (query) {
        if (!query) {
            return;
        }

        return TalentMasterService.search(query, ctrl.categoryFilter).then(
            function (result) {
                return result.data;
            }
        );
    };

    ctrl.onSelectProxy = function ($item, $model, $label) {
        if (ctrl.onSelect) {
            ctrl.onSelect({ $item: $item, $model: $model, $label: $label });
        }
    };
}
