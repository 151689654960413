(function () {
    'use strict';

    var authModule = angular.module('blocks.auth');

    /**
     * Configure pre startup
     */
    authModule.config(configure);

    configure.$inject = ['$httpProvider'];

    function configure($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
    }

})();



