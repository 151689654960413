import angular from 'angular';

angular
    .module('app.widgets')
    .directive('uiImage', uiImage);

uiImage.$inject = [];

function uiImage() {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {
            let src = attr.uiImage || attr.src;

            function loadImage(image) {
                return require(`Images/${image}`);
            }

            // required for webpack to pick up image
            element.attr('src', loadImage(src));
        }
    };
}




