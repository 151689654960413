import angular from 'angular';

angular
    .module('app.account.onboarding')
    .controller('AccountOnboardingController', AccountOnboardingController);

AccountOnboardingController.$inject = ['$translate','toastr','$timeout', '$state', '$stateParams', 'ENV_CONFIG', 'AuthService', 'TalentMasterService', 'currentUser', 'l10n', 'MeService','UserService','logger','UploadPictureService'];

/* @ngInject */
function AccountOnboardingController($translate, toastr,$timeout, $state, $stateParams, ENV_CONFIG, AuthService, TalentMasterService, currentUser, l10n, MeService, UserService, logger, UploadPictureService) {
    /* jshint validthis: true */
    var vm = this;

    vm.isLoading = false;
    vm.currentUser = currentUser;
    vm.address = currentUser.address;
    console.log(vm.currentUser);
    vm.editForm = {
        user: angular.copy(vm.currentUser, {}),
        address: angular.copy(vm.address, {})
    };
    if (!vm.editForm.user.facebookId){
        vm.editForm.user.facebookId="";
    }
    if (!vm.editForm.user.instagramId) {
        vm.editForm.user.instagramId = "";
    }
    if (!vm.editForm.user.linkedinId) {
        vm.editForm.user.linkedinId = "";
    }
    if (!vm.editForm.user.twitterId) {
        vm.editForm.user.twitterId = "";
    }
    if (!vm.editForm.user.phone_numbers || !vm.editForm.user.phone_numbers.length) {
        vm.editForm.user.phone_numbers = [];
        vm.editForm.user.phone_numbers.push({ number: '' });
    }
    // if (!vm.editForm.user.emails || !vm.editForm.user.emails.length) {
    //     vm.editForm.user.emails = [];
    //     vm.editForm.user.emails.push({email:''});
    // }
    if(vm.editForm.user.date_of_birth) {
        vm.editForm.user.date_of_birth = new Date(vm.editForm.user.date_of_birth);
    }
    vm.data = {
        employment_statuses: [],
        knowledge: [],
        general: [],
        languages_mother_tongue: [],
        languages_writing: [],
        languages_understanding: [],
        languages_speaking: [],
        tools: [],
        personality: []
    };
    vm.step = $stateParams.step | 0 || 0;

    MeService.startOnboarding().then(function(onboarding) {
        vm.data = angular.extend({}, vm.data, onboarding);
        console.log(vm.data);
    });

    vm.previous = function() {
        vm.step--;
        $state.go('.', {step: vm.step}, {notify: false});
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    vm.endOnboarding = function() {
        vm.editForm.user.onboardingStep = vm.step;
        if (vm.memberForm && !vm.memberForm.$invalid) {
            vm.isLoading = true;

            vm.editForm.user.address = vm.editForm.address;
            for (var key in vm.data) {
                vm.editForm.user[key] = vm.data[key];
            }
    
            MeService.endOnboarding(vm.editForm.user).then(function(result) {
                $timeout(function() {
                    $state.go('app.my-profile', { "tour": true });
                }, 2000);
            });
        } else {
            logger.warning('Cannot update profile with the current state', vm.memberForm);
        }
    };

    vm.next = function() {
        vm.step++;
        vm.editForm.user.onboardingStep = vm.step;
        $state.go('.', {step: vm.step}, {notify: false});
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if(vm.step === 10){
            if(vm.memberForm && !vm.memberForm.$invalid) {
                vm.editForm.user.address = vm.editForm.address;
                for (var key in vm.data) {
                    vm.editForm.user[key] = vm.data[key];
                }
                console.log(vm.editForm.user);
                MeService.finishOnboarding(vm.editForm.user).then(function(result) {
                    $timeout(function() {
                        $state.go('app.my-profile', { "tour": true });
                    }, 2000);
                });
            } else {
                logger.warning('Cannot update profile with the current state', vm.memberForm);
            }
            console.log(vm.editForm.user);
            MeService.finishOnboarding(vm.editForm.user).then(function(result) {
                $timeout(function() {
                    $state.go('app.my-profile', { "tour": true });
                }, 2000);
            });
        }
    };

    vm.gotoStep = function(step) {
        vm.step = step;
        $state.go('.', {step: vm.step}, {notify: false});
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if(vm.step === 10) {
            for (let key in vm.data) {
                vm.editForm.user[key] = vm.data[key];
            }
            // console.log(vm.data);
            // console.log(JSON.stringify(vm.data));
            vm.editForm.user.address = vm.editForm.address;
            MeService.finishOnboarding(vm.editForm.user).then(function(result) {
                $timeout(function() {
                    $state.go('app.my-profile', { "tour": true });
                }, 2000);
            });
        }
    };

    vm.toggleEmploymentStatus = function(status) {

        // Is currently selected
        if (vm.data.employment_statuses[0] === status) {
            vm.data.employment_statuses[0] = null;
        } else {
            vm.data.employment_statuses[0] = status;
        }
    };

    vm.hasEmploymentStatus = function(status) {
        return vm.data.employment_statuses[0] === status;

    };

    vm.addPhoneNumber = function() {
        if(! vm.editForm.user.phone_numbers) {
            vm.editForm.user.phone_numbers = [];
        }
        vm.editForm.user.phone_numbers.push({number: ''})
    };
    vm.addEmail = function () {
        if (!vm.editForm.user.emails) {
            vm.editForm.user.emails = [];
        }
        vm.editForm.user.emails.push({ email: '' });
    };
    vm.deletePhoneNumber = function(index) {
        if (vm.editForm.user.phone_numbers) {
            vm.editForm.user.phone_numbers.splice(index, 1);
        }
    };
    vm.deleteEmail = function (index) {
        if (vm.editForm.user.emails) {
            vm.editForm.user.emails.splice(index, 1);
        }
    };

    vm.setEditForm = function(memberForm) {
        if(memberForm) {
            vm.memberForm = memberForm;
        }
    };

    vm.onFileSelect = function(files){
        var file = files[0];
        if(file){
            var query = {
                id: vm.currentUser._id,
                type : file.type,
                name : file.name
            };
            UploadPictureService.signUrl(query).then( function(data){
                UploadPictureService.upload(data,file).then(function(){
                        vm.currentUser.picture_url = data.getUrl;
                        vm.editForm.user.picture_url = data.getUrl;
                })
            });
        }
        else return;
    };
    vm.uploadResume = function (files) {
        var file = files[0];
        if (file) {
            var query = {
                id: vm.currentUser._id,
                type: file.type,
                name: file.name
            };
            UploadPictureService.signUrl(query).then(function (data) {
                UploadPictureService.upload(data, file).then(function () {
                    vm.editForm.user.resumeUrl = data.getUrl;
                }).then(() => {
                    UserService.update(vm.editForm.user._id, { resumeUrl: vm.editForm.user.resumeUrl }).then(()=>{});
                })
            });
        }
        else return;
    };
    vm.showError = function (file) {
        toastr.error($translate.instant('ONBOARDING.STEP1.RESUME_ERROR'), 'Error');
        vm.InvalidFile = false;
    }

    vm.removeResume = function(){
        UploadPictureService.removeResume().then(()=>{
            vm.currentUser.resumeUrl = null;
            vm.editForm.user.resumeUrl = null;
            UserService.update(vm.editForm.user._id, { resumeUrl: vm.editForm.user.resumeUrl }).then(() => {});

        })
    }

}
