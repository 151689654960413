import angular from "angular";

angular
    .module("app.dashboard.recent-actions")
    .controller("RecentActionsController", RecentActionsController);

RecentActionsController.$inject = ['$stateParams'];

/* @ngInject */
function RecentActionsController($stateParams) {
    var vm = this;
    vm.message = $stateParams.message;
}

export default RecentActionsController;
