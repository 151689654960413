import angular from "angular";
import template from "./talent.component.html";

const Talent = {
    bindings: {
        data: "<",
        highlight: "<",
    },
    template: template,
    controller: TalentController,
};

/* @ngInject */
function TalentController() {
    const ctrl = this;
}

angular.module("app.widgets").component("newTalent", Talent);
