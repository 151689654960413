(function () {
    'use strict';

    angular
        .module('app.talent.my-talent')
        .controller('MyTalentController', MyTalentController);

    MyTalentController.$inject = ['$state','$timeout','$stateParams', '$scope', '$q', 'logger', 'UserTalentService', 'currentUser', 'userTalent', 'l10n','$rootScope' ,'ngIntroService', '$translate'];

    /* @ngInject */
    function MyTalentController($state, $timeout, $stateParams, $scope, $q, logger, UserTalentService, currentUser, userTalent, l10n, $rootScope ,ngIntroService, $translate) {
        var ctrl = this;

        ctrl.isEditing = false;
        ctrl.userLanguage = l10n.guessLanguage();
        ctrl.currentUser = currentUser;
        ctrl.userTalent = userTalent;
        ctrl.isPublicProfile = $stateParams.isPublicProfile;

        ctrl.editForm = {
            userTalent: angular.copy(ctrl.userTalent, {})
        };

        ctrl.toggleEditing = function() {
            $('.introjs-tooltip').css('top', 450);
            ctrl.isEditing = !ctrl.isEditing;
            ngIntroService.refresh();
        };

        ctrl.save = function() {
            $('.introjs-tooltip').css('top', 250);
            UserTalentService.update(ctrl.currentUser._id, ctrl.editForm.userTalent).then(function(userTalent) {
                ctrl.userTalent = userTalent;
                ctrl.isEditing = !ctrl.isEditing;
            });
        };

        ctrl.cancel = function() {
            $('.introjs-tooltip').css('top', 250);            
            ctrl.editForm = {
                userTalent: angular.copy(ctrl.userTalent, {})
            };
            ctrl.isEditing = !ctrl.isEditing;
        };
        
        
        // console.log(document.getElementById('step2223'))
        $scope.$on('$stateChangeSuccess',
            function (event, toState, toParams, fromState, fromParams) {
                if (fromParams.tour){
                    ngIntroService.clear()
                    ctrl.IntroOptions = {
                        steps: [
                            {
                                element:  "#step3",
                                intro: '<p class="blue-grey f-s-20">' + $translate.instant('PRODUCT_TOUR.STEP_3_TITLE') +'</p>' + $translate.instant('PRODUCT_TOUR.STEP_3'),
                                position: "bottom"
                            },
                            {
                                element: "#step3",
                                intro: '<p class="blue-grey f-s-20">' + $translate.instant('PRODUCT_TOUR.STEP_TALENT_DETAIL_TITLE') + '</p>' + $translate.instant('PRODUCT_TOUR.STEP_TALENT_DETAIL'),                              
                                position: "bottom"
                            },
                            {
                                element: "#step3",
                                intro: '<p class="blue-grey f-s-20">' + $translate.instant('PRODUCT_TOUR.STEP_TALENT_DETAIL_TITLE') + '</p>' + $translate.instant('PRODUCT_TOUR.STEP_TALENT_DETAIL'),
                                position: "bottom"
                            }
                        ],
                        showStepNumbers: false,
                        showBullets: false,
                        exitOnOverlayClick: true,
                        exitOnEsc: true,
                        hidePrev: true,
                        hideNext: true,
                        skipLabel: $translate.instant('PRODUCT_TOUR.SKIP'),
                        nextLabel: $translate.instant('PRODUCT_TOUR.BUTTON_2'),
                        prevLabel: $translate.instant('PRODUCT_TOUR.PREV_BUTTON'),
                        doneLabel: $translate.instant('PRODUCT_TOUR.BUTTON_3')
                    };
                    ngIntroService.setOptions(ctrl.IntroOptions);
                    $timeout(ngIntroService.start.bind(ngIntroService));
                    ngIntroService.onComplete(function () {
                        ngIntroService.clear();
                        $state.go('app.my-profile', { "tour": true, "step": 3 });
                    });
                    ngIntroService.onAfterChange(function () {
                        $('.introjs-nextbutton').css("color", "white").css("background", "#3399ff").css("text-shadow", "none");
                        if (ngIntroService.intro._currentStep === 0) {
                            $('.introjs-nextbutton ').html($translate.instant('PRODUCT_TOUR.BUTTON_2'));
                        }
                        if (ngIntroService.intro._currentStep === 1) {
                            $('.introjs-prevbutton').css('display', 'none');
                            $('.introjs-nextbutton ').html($translate.instant('PRODUCT_TOUR.TALENT_DETAIL_NEXT_BUTTON'));
                        }
                        if (ngIntroService.intro._currentStep === 2){
                            ngIntroService.clear();
                            $state.go('app.my-profile', { "tour": true, "step": 3 });
                        }
                    });
                }
            })
                
    }
})();
