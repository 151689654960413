(function () {
    'use strict';

    angular
        .module('blocks.auth')
        .factory('AuthService', AuthService);

    AuthService.$inject = ['ENV_CONFIG', '$location', '$rootScope', '$http', 'UserService', '$localStorage', '$cookieStore', '$q'];


    /**
     * AuthService
     *
     * @param $location
     * @param $rootScope
     * @param $http
     * @param UserService
     * @param $localStorage
     * @param $cookieStore
     * @param $q
     * @returns {{login: Function, logout: Function, createUser: Function, changePassword: Function, getCurrentUser: Function, isLoggedIn: Function, isLoggedInAsync: Function, isAdmin: Function, getToken: Function}}
     * @constructor
     */
    /* @ngInject */
    function AuthService(ENV_CONFIG, $location, $rootScope, $http, UserService, $localStorage, $cookieStore, $q) {
        var currentUser = $localStorage.currentUser;

        if (($cookieStore.get('token') && (currentUser && !currentUser._id)) || ($cookieStore.get('token') && !currentUser)) {
            currentUser = UserService.getMe().then(function (currentUser) {
                $localStorage.currentUser = currentUser;
                return currentUser;
            });
        }

        return {

            currentUserMemberships: null,

            /**
             * Authenticate user and save token
             *
             * @param  {Object}   user     - login info
             * @param  {Function} callback - optional
             * @return {Promise}
             */
            login: function (email, password) {
                return $http.post(ENV_CONFIG.baseURI + '/auth/local', {
                    email: email,
                    password: password
                }).then(function (res) {
                    $cookieStore.put('token', res.data.token);
                    return UserService.getMe().then(function (user) {
                        currentUser = user;
                        $localStorage.currentUser = user;
                        return user;
                    });
                }).catch(function (err) {
                    throw err;
                });

            },

            resetPasswordRequest: function (email) {
                return $http.post(ENV_CONFIG.baseURI + '/auth/local/reset-password-request', {
                    email: email
                }).then(function (res) {
                    return res;
                }).catch(function (err) {
                    throw err;
                });

            },

            resetPassword: function (email, token, newPassword) {
                return $http.post(ENV_CONFIG.baseURI + '/auth/local/reset-password', {
                    email: email,
                    token: token,
                    newPassword: newPassword
                }).then(function (res) {
                    return res;
                }).catch(function (err) {
                    throw err;
                });

            },

            /**
             * Delete access token and user info
             *
             * @param  {Function}
             */
            logout: function () {
                $cookieStore.remove('token');
                currentUser = null;
                this.currentUserMemberships = null;
                delete $localStorage.currentUser;
            },

            /**
             * Create a new user
             *
             * @param  {Object}   user     - user info
             * @param  {Function} callback - optional
             * @return {Promise}
             */
            createUser: function (user) {
                return UserService.save(user).then(function (data) {
                    $cookieStore.put('token', data.token);
                    return UserService.getMe().then(function (currentUser) {
                        currentUser = user;
                        $localStorage.currentUser = user;
                        return currentUser;
                    });
                });
            },

            /**
             * Gets all available info on authenticated user
             *
             * @return {Object} user
             */
            getCurrentUser: function () {
                return $q.when(currentUser).then(function (currentUser) {
                    return currentUser;
                });
            },

            refreshCurrentUser: function () {
                currentUser = UserService.getMe().then(function (currentUser) {
                    $localStorage.currentUser = currentUser;
                    return currentUser;
                });
            },

            getCurrentUserMemberships: function () {
                var me = this;

                return $q.when(this.getCurrentUser()).then(function (currentUser) {
                    return UserService.getOrganisationMemberships(currentUser._id, { includes: ['organisation'] })
                        .then(function (memberships) {
                            me.currentUserMemberships = memberships;
                            return me.currentUserMemberships;
                        })
                })
            },

            /**
             * Check if user is a chief
             */
            isChief: function () {
                return this.getCurrentUserMemberships().then(function (memberships) {
                    for (const membership of memberships) {
                        if ((membership.user_id == currentUser._id) && membership.role == 'CHIEF') {
                            return { isChief: true, organisationId: membership.organisation_id };
                        }
                    }
                    return { isChief: false, organisationId: null };
                });
            },

            refreshCurrentUserMemberships: function () {
                var me = this;
                return $q.when(this.getCurrentUser()).then(function (currentUser) {
                    return UserService.getOrganisationMemberships(currentUser._id, { includes: ['organisation'] })
                        .then(function (memberships) {
                            me.currentUserMemberships = memberships;
                            return me.currentUserMemberships;
                        })
                })
            },

            /**
             * Check if a user is logged in
             *
             * @return {Boolean}
             */
            isLoggedIn: function () {
                return !(angular.isUndefined(currentUser) || currentUser === null);
            },

            /**
             * Waits for currentUser to resolve before checking if user is logged in
             */
            isLoggedInAsync: function (cb) {
                $q.when(currentUser).then(function (currentUser) {
                    if (currentUser) {
                        cb(true);
                    } else {
                        cb(false);
                    }
                });
            },

            /**
             * Check if a user is an admin
             *
             * @return {Boolean}
             */
            isAdmin: function () {
                return currentUser.role === 'admin';
            },

            /**
             * Get auth token
             */
            getToken: function () {
                return $cookieStore.get('token');
            }
        };
    }

})();
