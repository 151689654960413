import angular from "angular";
import template from "./language-selector.html";

angular.module("blocks.l10n").directive("languageSelector", languageSelector);

languageSelector.$inject = ["config"];

/* @ngInject */
function languageSelector(config) {
    const directive = {
        restrict: "E",
        replace: true,
        template: template,
        controller: Controller,
    };

    return directive;
}

Controller.$inject = ["$scope", "l10n"];

/* @ngInject */
function Controller($scope, l10n) {
    $scope.currentLocaleDisplayName = l10n.getLocaleDisplayName();
    $scope.localesDisplayNames = l10n.getLocalesDisplayNames();
    $scope.visible =
        $scope.localesDisplayNames && $scope.localesDisplayNames.length > 1;

    $scope.changeLanguage = function (locale) {
        l10n.setLocaleByDisplayName(locale);
    };
}
