import angular from 'angular';

angular
    .module('app.profile')
    .controller('PublicOrganisationController', PublicOrganisationController);

PublicOrganisationController.$inject = ['$scope', '$q', 'logger', 'currentUser', 'organisation'];

/* @ngInject */
function PublicOrganisationController($scope, $q, logger, currentUser, organisation) {
    var ctrl = this;
    ctrl.currentUser = currentUser;
    ctrl.organisation = organisation;
}
