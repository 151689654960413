import angular from "angular";
import * as template from "./project-templates.html";

angular.module("app.project").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.project-templates",
            config: {
                url: "/templates/:organisationId/?type",
                template: template,
                controller: "TemplatesDirectoryController",
                controllerAs: "main",
                title: "Template directory",
                resolve: {
                    organisationId: [
                        "$stateParams",
                        function ($stateParams) {
                            return $stateParams.organisationId;
                        },
                    ],
                },
            },
        },
    ];
}
