import angular from "angular";
import * as template from "./dashboard.html";

angular.module("app.dashboard").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.dashboard",
            config: {
                url: "/dashboard/:organisationId",
                template: template,
                controller: "DashboardController",
                controllerAs: "dashboardCtrl",
                title: "Dashboard",
                params: {
                    project: undefined,
                },
                resolve: {
                    myOrganisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    features: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getAvailableFeatures(
                                $stateParams.organisationId
                            );
                        },
                    ],
                    memberships: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getMemberships(
                                $stateParams.organisationId,
                                { includes: ["user"] }
                            ).then(function (memberships) {
                                return memberships;
                            });
                        },
                    ],
                    invitations: [
                        "$stateParams",
                        "OrganisationInvitationService",
                        function ($stateParams, OrganisationInvitationService) {
                            return OrganisationInvitationService.getAll(
                                $stateParams.organisationId,
                                { includes: ["user"] }
                            ).then(function (invitations) {
                                return invitations;
                            });
                        },
                    ],
                    projectStatistics: [
                        "$stateParams",
                        "DashboardService",
                        function ($stateParams, DashboardService) {
                            return DashboardService.getProjectStatistics(
                                $stateParams.organisationId,
                            ).then(function (projectStatistics) {
                                return projectStatistics;
                            });
                        },
                    ],
                    recentStatusChanges: [
                        "$stateParams",
                        "DashboardService",
                        function ($stateParams, DashboardService) {
                            return DashboardService.getUsersWithRecentStatusChanges(
                                $stateParams.organisationId,
                            ).then(function (recentStatusChanges) {
                                return recentStatusChanges;
                            });
                        },
                    ],
                    latestProjectsChanged: [
                        "$stateParams",
                        "DashboardService",
                        function ($stateParams, DashboardService) {
                            return DashboardService.latestProjectsChanged(
                                $stateParams.organisationId,
                            ).then(function (latestProjectsChanged) {
                                return latestProjectsChanged;
                            });
                        },
                    ],
                    project: [
                        "$stateParams",
                        function ($stateParams) {
                            return $stateParams.project;
                        },
                    ],
                    projectRoles: [
                        function () {
                            return [];
                        },
                    ],
                },
            },
        },
    ];
}

