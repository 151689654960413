import angular from 'angular';

angular
    .module('blocks.utilities')
    .factory('Util', Util);

Util.$inject = [];

/* @ngInject */
function Util() {
    var service = {
        findIndexByAttribute: findIndexByAttribute,
        toHashMap: toHashMap,
        isFunction: isFunction,
        find: find
    };

    return service;
    /////////////////////

    function findIndexByAttribute(array, attr, value) {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
    }

    /**
     * This function converts an array to hash map
     * @param {String | function} key describes the key to be evaluated in each object to use as key for hasmap
     * @returns Object
     * @Example
     *      [{id:123, name:'naveen'}, {id:345, name:"kumar"}].toHashMap("id")
     *      Returns :
     *          - Object {123: Object, 345: Object}
     *
     *      [{id:123, name:'naveen'}, {id:345, name:"kumar"}].toHashMap(function(obj){return obj.id+1})
     *      Returns :
     *          - Object {124: Object, 346: Object}
     */
    function toHashMap(array, key) {
        var _hashMap = {}, getKey = isFunction(key) ? key: function(_obj) { return _obj[key]; };
        array.forEach(function (obj){
            _hashMap[getKey(obj)] = obj;
        });
        return _hashMap;
    }

    function isFunction(func){
        return Object.prototype.toString.call(func) === '[object Function]';
    }

    /**
     * Find the first element in an array that matches the test
     *
     * @param arr
     * @param test
     * @param ctx
     * @returns {*}
     */
    function find(arr, test, ctx) {
        var result = null;
        var localCtx = ctx;
        arr.some(function(el, i) {
            return test(localCtx, el, i, arr) ? ((result = el), true) : false;
        });
        return result;
    }
}
