(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('AppNomenuController', AppNomenuController);

    AppNomenuController.$inject = ['$rootScope', '$timeout', 'config', 'logger', 'AuthService', 'ngDrift'];
    /* @ngInject */
    function AppNomenuController($rootScope, $timeout, config, logger, AuthService, ngDrift) {
        var vm = this;
        $rootScope.currentYear = new Date().getFullYear();
        ngDrift.show();
        vm.isLoggedIn = AuthService.isLoggedIn();
    }
})();
