import angular from "angular";
import template from "./invite-users.html";

angular.module("app.organisation.invite-users").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.organisation-invite-users",
            config: {
                url: "/manage-organisation/:organisationId/invite-users",
                template: template,
                controller: "InviteUsersController",
                controllerAs: "vm",
                title: "invite users",
                resolve: {
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                },
            },
        },
    ];
}
