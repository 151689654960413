import angular from "angular";

angular
    .module("app.organisation.search-talent")
    .controller("SearchTalentController", SearchTalentController);

SearchTalentController.$inject = ["logger", "$uibModal", "OrganisationService"];

/* @ngInject */
function SearchTalentController(logger, $uibModal, OrganisationService) {
    var ctrl = this;

    ctrl.criteria = {
        language: "en",
        should: [],
    };

    ctrl.result = {
        hasSearched: false,
        total: 0,
        data: [],
    };

    ctrl.searchTalent = function (talent) {
        ctrl.addExactTalentCriteria(talent);
        ctrl.search();
    };

    ctrl.addExactTalentCriteria = function (talent) {
        if (talent == null || !angular.isObject(talent)) {
            logger.warning(
                "You need to select a talent from the results in order to add it to the criteria.",
                talent
            );
            return;
        } else {
            ctrl.criteria.should.push(talent);
            ctrl.searchForm.talent_typeahead = null;
        }
    };

    ctrl.removeExactTalentCriteria = function (talent, index) {
        ctrl.criteria.should.splice(index, 1);
        if (ctrl.criteria.should.length === 0) {
            ctrl.result.hasSearched = false;
        }
    };

    ctrl.search = function () {
        ctrl.result.hasSearched = true;

        var request = {
            language: ctrl.criteria.language,
            should: ctrl.criteria.should.map(function (talent) {
                var param = {};
                if (talent["en"]) {
                    param["en"] = talent["en"];
                }

                if (talent["nl"]) {
                    param["nl"] = talent["nl"];
                }
                return param;
            }),
        };

        OrganisationService.searchConnections(
            ctrl.organisationId,
            request
        ).then(function (result) {
            console.log(result);
            ctrl.result.data = result;
        });
    };

    ctrl.shouldShowProperty = function (user, propertyName) {
        var scopes = [];
        for (var i = 0; i < user.scopes.length; i++) {
            scopes[user.scopes[i].property] = user.scopes[i].scope;
        }
        return (
            scopes.hasOwnProperty(propertyName) &&
            scopes[propertyName] != "private" &&
            user.hasOwnProperty(propertyName) &&
            typeof user[propertyName] !== "undefined"
        );
    };

    ctrl.changeMotivation = function (talent) {
        var modalInstance = $uibModal.open({
            animation: true,
            size: "lg",
            template: require("~/app/organisation/manage-organisation/search-talent/edit-talent-motivation.html"),
            controller: function ($uibModalInstance, talent) {
                var ctrl = this;

                ctrl.talent = talent;

                ctrl.close = function () {
                    $uibModalInstance.close();
                };
            },
            controllerAs: "vm",
            resolve: {
                talent: function () {
                    return talent;
                },
            },
        });
    };
}

export default SearchTalentController;
