import angular from 'angular';

angular
    .module('app.account')
    .controller('ChangePasswordController', ChangePasswordController);

ChangePasswordController.$inject = ['UserService'];

/* @ngInject */
function ChangePasswordController(UserService) {
    var ctrl = this;

    ctrl.user = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    ctrl.errors = {
        other: undefined
    };

    ctrl.message = '';
    ctrl.submitted = false;

    ctrl.changePassword = function(form) {
        ctrl.submitted = true;

        if(form.$valid) {
            UserService.changePassword(ctrl.user.oldPassword, ctrl.user.newPassword)
                .then(function() {
                    ctrl.message = 'Password successfully changed.';
                })
                .catch(function() {
                    form.password.$setValidity('mongoose', false);
                    ctrl.errors.other = 'Incorrect password';
                    ctrl.message = '';
                });
        }
    }
}
