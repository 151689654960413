(function () {
    "use strict";

    angular.module("app.widgets").directive("userTypeahead", UserTypeahead);

    UserTypeahead.$inject = [];

    /* @ngInject */
    function UserTypeahead() {
        var directive = {
            scope: {
                model: "=",
            },
            template: require("~/app/widgets/user-typeahead/user-typeahead.html"),
            controller: UserTypeaheadController,
            controllerAs: "vm",
            restrict: "E",
        };

        return directive;
    }

    UserTypeaheadController.$inject = ["UserService"];

    /* @ngInject */
    function UserTypeaheadController(UserService) {
        var ctrl = this;

        ctrl.searchForMember = function (query) {
            if (!query) return;

            return UserService.search(query).then(function (result) {
                return result.data;
            });
        };
    }
})();
