import angular from 'angular';

angular
    .module('app.talent')
    .filter('translateTalent', TranslateTalent);

TranslateTalent.$inject = ['l10n', 'Util'];

var languages = ['en', 'nl', 'bg', 'cs', 'da', 'de', 'et', 'el', 'es', 'fr', 'is', 'it', 'lv', 'lt', 'hr',
    'hu', 'mt', 'pl', 'pt', 'ro', 'sk', 'sl', 'fi', 'sv'];

function TranslateTalent(l10n, Util) {
    return function(input) {
        if(!input) return;
        var language = l10n.guessLanguage();

        if(input[language]) {
            return input[language];
        } else if(input['en']) {
            return input['en'];
        } else {
            var lang = Util.find(languages, function(ctx, el, i, arr) {
                if (ctx.hasOwnProperty(languages[i])) {
                    return true;
                }
            }, input);

            return input[lang];
        }
    };
}
