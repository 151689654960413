import angular from 'angular';

angular
    .module('app.organisation')
    .controller('EditMembershipPrivilegesController', EditMembershipPrivilegesController);

EditMembershipPrivilegesController.$inject = ['$scope', '$http', '$uibModalInstance', 'logger', 'currentUser',
    'membership', 'l10n', 'OrganisationService'];

/* @ngInject */
function EditMembershipPrivilegesController($scope, $http, $uibModalInstance, logger, currentUser, membership,
                                            l10n, OrganisationService) {
    var ctrl = this;

    ctrl.userLanguage = l10n.guessLanguage();
    ctrl.currentUser = currentUser;
    ctrl.membership = membership;
    ctrl.templateMemberhip = angular.copy(ctrl.membership, {})
    ctrl.availablePrivileges = ['HR'];
    ctrl.save = function() {
        OrganisationService.updateMember(ctrl.membership.organisation_id, ctrl.membership._id, ctrl.templateMemberhip)
            .then(function(result) {
                ctrl.membership.type = result.type;
                ctrl.membership.privileges = result.privileges;
                ctrl.membership.user.language = ctrl.templateMemberhip.user.language;
                $uibModalInstance.close(result);
            });
    };

    ctrl.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}
