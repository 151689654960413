import angular from "angular";
import template from "./view-template.html";
import "./shared/template-project-details.html";
import "./shared/template-role-details.html";

angular.module("app.project").run(appRun);

appRun.$inject = ["routerHelper"];

/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.view-template",
            config: {
                url: "/templates/view/:templateId/:organisationId",
                template: template,
                controller: "ViewTemplateController",
                controllerAs: "main",
                title: "",
                resolve: {
                    template: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getTemplate(
                                $stateParams.templateId
                            ).then(function (template) {
                                return template;
                            });
                        },
                    ],
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                },
            },
        },
    ];
}
