import angular from 'angular';
import modalTemplate from "../layout/template-modal.html";
import modalTemplateForRole from "../layout/templates-for-role-modal.html";
import inviteModalTemplate from "../organisation/manage-organisation/invite-users/invite-users.html";
import searchTalentModal from '../organisation/manage-organisation/search-talent-modal.html';
import searchTribeMemberModal from '../organisation/manage-organisation/search-tribe-member-modal.html';
import createOrganisation from '../organisation/create-organisation-form.html';
import createProjectModal from '../layout/create-project-modal.html';

const environment = process.env.NODE_ENV || 'development';

const TEMPLATE_ORGANISATION_ID = environment != 'development' ? '58bc0ee877cea1001106c9bf' : '5aae7c70e9d0cb0014a83834';

angular
    .module('app.dashboard')
    .controller('DashboardController', DashboardController);

DashboardController.$inject = ['$stateParams', 'logger', "$uibModal", "currentUser", "projectStatistics", "recentStatusChanges", "latestProjectsChanged", "myOrganisation", "features", "memberships", "UserService", 'OrganisationInvitationService', 'OrganisationService', 'UploadPictureService', 'AuthService', 'TalentMasterService', 'UserTalentService', 'ProjectService', 'WizardHandler', '$translate', '$state', 'project', 'organisation', 'projectRoles', '$rootScope', '$scope', 'l10n'];

function DashboardController($stateParams, logger, $uibModal, currentUser, projectStatistics, recentStatusChanges, latestProjectsChanged, myOrganisation, features, memberships, UserService, OrganisationInvitationService, OrganisationService, UploadPictureService, AuthService, TalentMasterService, UserTalentService, ProjectService, WizardHandler, $translate, $state, project, organisation, projectRoles, $rootScope, $scope, l10n) {
    var vm = this;
    vm.organisationsScrollTop = function(){
        $('.user-organisations-container').animate({
            scrollTop: '-=100'
        }, 100);
    }

    vm.organisationsScrollDown = function(){
        $('.user-organisations-container').animate({
            scrollTop: '+=100'
        }, 100);
    }
    vm.currentUser = currentUser;
    vm.myOrganisation = myOrganisation;
    vm.memberships = memberships;
    vm.features = features;
    vm.projectsInPlanning = projectStatistics.draft;
    vm.activeProjects = projectStatistics.published;
    vm.passiveMembers = vm.memberships.filter((element) => element.user.type == "PASSIVE");
    UserService.getUserOrganisations(currentUser._id, {
    }).then(function (userOrganisations) {
        vm.userOrganisations = userOrganisations;
    });
    vm.recentStatusChanges = recentStatusChanges;
    vm.latestProjectsChanged = latestProjectsChanged;

    vm.user = {};
    vm.user.connectiontype = "EMPLOYEE";
    vm.user.language = "en";
    vm.qry = "";
    vm.result = {
        newEmail: true,
        hasSearched: false,
        total: 0,
        data: []
    };
    vm.data = {
        knowledge: [],
        general: [],
        languages_mother_tongue: [],
        languages_writing: [],
        languages_understanding: [],
        languages_speaking: [],
        tools: [],
        personality: []
    };

    vm.openInviteMemberModal = function() {
        $uibModal.open({
                animation: true,
                template: inviteModalTemplate,
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.search = function (form) {
                        vm.submitted = true;
                        if(form.$valid){
                            var qry = {
                                fullName: vm.user.firstname + ' ' + vm.user.lastname,
                                email: vm.user.email
                            };
                            UserService.search(qry)
                                .then(searchResultHandler)
                                .catch(function (err) {
                                    err = err.data;
                                    vm.errors = {};

                                    // Update validity of form fields that match the mongoose errors
                                    angular.forEach(err.errors, function(error, field) {
                                        form[field].$setValidity('mongoose', false);
                                        vm.errors[field] = error.message;
                                    });
                                });
                        }
                    };
                    vm.backStep = function() {
                        vm.result.hasSearched = !vm.result.hasSearched;
                        vm.showForm = false;
                        vm.result.newEmail = true;
                    };

                    vm.save = function(form) {
                        vm.submitted = true;
                        if(form.$valid) {
                            for (var key in vm.data) {
                                vm.user[key] = vm.data[key];
                            };
                            OrganisationService.saveMember(myOrganisation._id, vm.user)
                                .then(function(result) {
                                    logger.success($translate.instant('PASSIVE-PROFILE.CREATE.SUCCESS_MESSAGE'));
                                    vm.user = {};
                                    vm.data = {
                                        knowledge: [],
                                        general: [],
                                        languages_mother_tongue: [],
                                        languages_writing: [],
                                        languages_understanding: [],
                                        languages_speaking: [],
                                        tools: [],
                                        personality: []
                                    };
                                    vm.submitted = false;
                                    vm.showForm = false;
                                    $state.go('app.dashboard', {organisationId: myOrganisation._id});
                                    $uibModalInstance.close();
                                })
                                .catch(function(err) {
                                    err = err.data;
                                    vm.errors = {};

                                    // Update validity of form fields that match the mongoose errors
                                    angular.forEach(err.errors, function(error, field) {
                                        form[field].$setValidity('mongoose', false);
                                        vm.errors[field] = error.message;
                                    });
                                });
                        }
                    };
                    vm.inviteUser = function(user) {
                        if(!user) {
                            logger.warning('No user selected to invite! Select a user before sending an invitation.');
                            return;
                        }

                        OrganisationInvitationService.invite(myOrganisation._id, user._id, {type: vm.user.connectiontype}).then(function(invitation) {

                            logger.success($translate.instant('INVITE-USERS.SUCCESS-MESSAGE'));
                        });
                    };
                    vm.onFileSelect = function(files){
                        var file =files[0];
                        if(file){
                            var query = {
                                id : myOrganisation._id,
                                type : file.type,
                                name : file.name
                            };
                            UploadPictureService.signUrl(query).then( function(data){
                                UploadPictureService.upload(data,file).then(function(){
                                    vm.user.picture_url = data.getUrl;
                                })
                            });
                        }
                        else return;
                    }
                    function searchResultHandler(result) {
                        vm.result.total = result.total;
                        vm.result.data = result.data;
                        vm.result.newEmail = true;
                        vm.result.data.forEach(function (element) {
                            if (vm.user.email == element.email){
                                vm.result.newEmail = false;
                            }
                        });
                        if (!vm.result.total)
                            vm.showForm = true;
                        vm.result.hasSearched = true;
                        vm.submitted = false;
                    };
                    vm.cancel = function () {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    templates: function () {
                        return;
                    },
                },
            }).result.then(function(){}, function(){})
    };

    vm.openSearchTalentModal = function() {
        $uibModal.open({
                animation: true,
                template: searchTalentModal,
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.organisationId = myOrganisation._id
                    vm.cancel = function () {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    templates: function () {
                        return;
                    },
                },
            }).result.then(function(){}, function(){})
    };

    vm.openSearchTribeMemberModal = function() {
        $uibModal.open({
                animation: true,
                template: searchTribeMemberModal,
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.organisationId = myOrganisation._id
                    vm.cancel = function () {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    templates: function () {
                        return;
                    },
                },
            }).result.then(function(){}, function(){})
    };

    vm.createTribeModal = function() {
        $uibModal.open({
                animation: true,
                template: createOrganisation,
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.currentUser = currentUser;
                    vm.valid = false;
                    vm.organisation = {
                        name: ''
                    };
                    vm.organisationId = myOrganisation._id
                    vm.saveOrganisation = function() {
                        OrganisationService.save(vm.organisation).then(function (organisation) {
                            AuthService.refreshCurrentUserMemberships();
                            $state.go('app.my-organisation', {'organisationId': organisation._id });
                            $uibModalInstance.close();
                        }, function () {
                            logger.error('Unable to create your organisation. Please contact support.');
                        });
                    }
                    vm.onFileSelect = function(files){
                        var file =files[0];
                        if(file){
                            var query = {
                                id : vm.currentUser._id,
                                type : file.type,
                                name : file.name
                            };
                            UploadPictureService.signUrl(query).then( function(data){
                                UploadPictureService.upload(data,file).then(function(){
                                    vm.organisation.logo_url = data.getUrl;
                                })
                            });
                        }
                        else return;
                    }
                    vm.cancel = function () {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    templates: function () {
                        return;
                    },
                },
            }).result.then(function(){}, function(){})
    };

    vm.createProjectModal = function() {
        $uibModal.open({
                animation: true,
                template: createProjectModal,
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.organisationId = myOrganisation._id
                    vm.projectRoles = projectRoles.map((elem) => {
                        if (elem.start) {
                            elem.start = new Date(elem.start);
                            elem.hasEndDate = false;
                        }
                        else{
                            elem.start = new Date(project.start);
                            elem.end = project.end ? new Date(project.end) : null;
                            elem.hasEndDate = project.end ? project.hasEndDate : false;
                        }
                        if (elem.end) {
                            elem.end = new Date(elem.end);
                            elem.hasEndDate = true;
                        }
                        return elem;
                    });

                    vm.userLanguage = l10n.guessLanguage();
                    vm.newTalent = null;
                    vm.isTemplateEditor = currentUser.roles && currentUser.roles.includes('template_editor');
                    vm.isEditing = $state.current.name === 'app.edit-project' ? true : false;
                    vm.availableTemplateTags = ['Start-up', 'Planning', 'Management',
                        'Juridisch', 'Financieel', 'Verkoop', 'Marketing', 'Product', 'Team'];
                    vm.availableProjectTags = [];
                    if(project){
                        if(project.start){
                            project.start = new Date(project.start);
                        }
                        if(project.end){
                            project.end = new Date(project.end);
                            project.hasEndDate = true;
                        }else{
                            project.hasEndDate = false;
                        }
                        vm.project = project;
                    }else{
                        vm.project = {
                            organisation_id: organisation._id,
                            owner: organisation.owner,
                            //organisation_member_id: currentUser._id, // TODO: Check what should be done with this?
                            start: new Date(),
                            end: new Date(),
                            locations: [],
                            logo_url: organisation.logo_url,
                            type: 'JOB',
                            state: 'PUBLISHED',
                            duration: 'CONTINUOUS',
                            frequency: 'FREQUENCY',
                            hasEndDate: true,
                            project_roles: []
                        };
                        // Add the organisation's address as default location
                        if(organisation.street || organisation.number || organisation.city) {
                            vm.project.locations.push({
                                street: organisation.street || '',
                                number: organisation.number || '',
                                box: organisation.box || '',
                                zipcode: organisation.zipcode || '',
                                city: organisation.city || '',
                                province: organisation.province || '',
                                country: organisation.country || ''
                            });
                        }
                    }
                    vm.projectRoles = projectRoles.map((elem) => {
                        if (elem.start) {
                            elem.start = new Date(elem.start);
                            elem.hasEndDate = false;
                        }
                        else{
                            elem.start = new Date(project.start);
                            elem.end = project.end ? new Date(project.end) : null;
                            elem.hasEndDate = project.end ? project.hasEndDate : false;
                        }
                        if (elem.end) {
                            elem.end = new Date(elem.end);
                            elem.hasEndDate = true;
                        }
                        return elem;
                    });

                    vm.setStateOptions = function() {
                        vm.stateOptions = [
                            { name: 'DRAFT', description: $translate.instant('ORGANISATION.PROJECTS.STATE_OPTIONS.DRAFT') },
                            { name: 'PUBLISHED', description: $translate.instant('ORGANISATION.PROJECTS.STATE_OPTIONS.PUBLISHED') },
                            { name: 'ARCHIVED', description: $translate.instant('ORGANISATION.PROJECTS.STATE_OPTIONS.ARCHIVED') }
                        ];
                    };
                    vm.setStateOptions();


                    vm.setConnectionVisbilityOptions = function() {
                        vm.connectionVisibilityOptions = [
                            { name: 'APPLICANT', description: $translate.instant('GENERAL.CONNECTION_TYPES.APPLICANT') },
                            { name: 'BOARD_MEMBER', description: $translate.instant('GENERAL.CONNECTION_TYPES.BOARD_MEMBER') },
                            { name: 'FREELANCER', description: $translate.instant('GENERAL.CONNECTION_TYPES.FREELANCER') },
                            { name: 'PARTNER', description: $translate.instant('GENERAL.CONNECTION_TYPES.PARTNER') },
                            { name: 'EMPLOYEE', description: $translate.instant('GENERAL.CONNECTION_TYPES.EMPLOYEE') },
                            { name: 'EX-EMPLOYEE', description: $translate.instant('GENERAL.CONNECTION_TYPES.EX-EMPLOYEE') },
                            { name: 'VOLUNTEER', description: $translate.instant('GENERAL.CONNECTION_TYPES.VOLUNTEER') },
                            { name: 'OTHER', description: $translate.instant('GENERAL.CONNECTION_TYPES.OTHER') }
                        ];
                    };
                    vm.setConnectionVisbilityOptions();

                    $rootScope.$on('$translateChangeSuccess', function () {
                        vm.setStateOptions();
                        vm.setConnectionVisbilityOptions();
                    });

                    vm.is100PercentAtHome = function() {
                        return vm.project.is_remote && vm.project.remote_percentage >= 100;
                    }

                    vm.toggleOpenDatePicker = function($event,datePicker) {
                        $event.preventDefault();
                        $event.stopPropagation();
                        vm[datePicker] = !vm[datePicker];
                    };

                    vm.onHomePercentageChange = function() {
                        if(vm.is100PercentAtHome()) {
                            vm.project.is_on_site = false;
                        }
                    };

                    vm.onOnSiteClick = function() {
                        if(!vm.is100PercentAtHome()){
                            vm.project.is_on_site = !vm.project.is_on_site;
                        }
                    };

                    vm.addLocation = function() {
                        if (!vm.project.locations) {
                            vm.project.locations = [];
                        }

                        vm.project.locations.push({city: "", province: "", country: ""});
                    };

                    vm.deleteLocation = function(index) {
                        if (vm.project.locations) {
                            vm.project.locations.splice(index, 1);
                        }
                    };

                    vm.save = function() {
                        ProjectService.save(organisation._id, vm.project).then(function(project) {
                            $state.go('app.my-organisation', {organisationId: organisation._id});
                        });
                    };

                    vm.searchForTalent = function(query) {
                        if(!query) { return; }

                        return TalentMasterService.search(query).then(function(result) {
                            return result.data;
                        });
                    };

                    vm.addTalent = function() {
                        if (vm.newTalent == null || vm.newTalent == '') {
                            logger.warning('Unable to add talent, something went wrong!', vm.newTalent);
                            return;
                        } else if (typeof vm.newTalent == 'string' || vm.newTalent instanceof String) {
                            // Talent is new and was not provided by the autocomplete function
                            var talent = {};
                            talent['en'] = vm.newTalent;
                            talent['nl'] = vm.newTalent;
                            // First save the talent
                            TalentMasterService.save(talent).then(function(talent) {
                                // Next push the project-talent
                                var projectTalent = angular.copy(talent);
                                projectTalent.talent_id = projectTalent._id;
                                delete projectTalent._id;
                                vm.project.talents.push(projectTalent);
                                vm.newTalent = null;
                            });
                        } else if (angular.isObject(vm.newTalent)) {
                            // Talent was hopefully provided by the autocomplete function
                            var projectTalent = angular.copy(vm.newTalent);
                            projectTalent.talent_id = projectTalent._id;
                            delete projectTalent._id;
                            vm.project.talents.push(projectTalent);
                            vm.newTalent = null;
                        }
                    };

                    vm.removeTalent = function(talent, index) {
                        vm.project.talents.splice(index, 1);
                    };

                    vm.setProjectType = function(type) {
                        vm.project.type = type;
                    }

                    vm.addTask = function() {
                        if (!vm.project.tasks) {
                            vm.project.tasks = [];
                        }

                        vm.project.tasks.push({description: ""});
                    };

                    vm.addRoles = function() {

                        var role = {
                            name: vm.newRole,
                            hours_recurrence: 'ONGOING',
                            frequency: 'PER_DAY',
                            tasks: [],
                            knowledge: [],
                            general: [],
                            start: vm.project.start,
                            end: vm.project.end,
                            hasEndDate: vm.project.hasEndDate,
                            languages_mother_tongue: [],
                            languages_writing: [],
                            languages_understanding: [],
                            languages_speaking: [],
                            tools: [],
                            personality: [],
                            start:vm.project.start,
                            end:vm.project.end
                        }
                        if(vm.project.type === "JOB"){
                            if(!vm.projectRoles[0]) {
                                vm.projectRoles[0] = role;
                            }
                            vm.projectRoles[0].name = vm.project.name;
                            vm.projectRoles[0].description = vm.project.description;
                        }
                        else {
                            vm.projectRoles.push(role);
                        }
                        vm.newRole = '';

                    };

                    vm.openTemplateModal = function() {
                        $uibModal.open({
                                animation: true,
                                template: modalTemplate,
                                controller: function ($uibModalInstance) {
                                    var ctrl = this;
                                    ctrl.templates = []
                                    ProjectService.getTemplates('PROJECT').then((templates) => ctrl.templates = templates);
                                    ctrl.templates = ctrl.templates;
                                    ctrl.selectTemplate = function (template) {
                                        $uibModalInstance.close(template);
                                        template.roles = [];
                                        ProjectService.getRoles(TEMPLATE_ORGANISATION_ID, template._id)
                                            .then((roles) => {
                                                template.roles = roles;
                                                vm.project.name = template.name;
                                                vm.project.description = template.description;
                                                if(template.tags) {
                                                    vm.project.tags = template.tags;
                                                }
                                                if(template.start) {
                                                    vm.project.start = new Date(template.start);
                                                }
                                                if(template.end) {
                                                    vm.project.hasEndDate = true
                                                    vm.project.end = new Date(template.end)
                                                } else {
                                                    vm.project.hasEndDate = false;
                                                }
                                                if(template.is_remote) {
                                                    vm.project.is_remote = template.is_remote;
                                                }
                                                if(template.remote_percentage) {
                                                    vm.project.remote_percentage = template.remote_percentage;
                                                }
                                                if(template.locations) {
                                                    vm.project.locations = template.locations;
                                                    vm.project.is_on_site = true;
                                                }
                                                if(template.state) {
                                                    vm.project.state = template.state
                                                }
                                                if(template.roles) {
                                                    vm.projectRoles = template.roles.map((elem) => {
                                                        if (elem.start) {
                                                            elem.start = new Date(elem.start);
                                                            elem.hasEndDate = false;
                                                        }
                                                        else{
                                                            elem.start = new Date(project.start);
                                                            elem.end = project.end ? new Date(project.end) : null;
                                                            elem.hasEndDate = project.end ? project.hasEndDate : false;
                                                        }
                                                        if (elem.end) {
                                                            elem.end = new Date(elem.end);
                                                            elem.hasEndDate = true;
                                                        }
                                                        elem.fromTemplate = true;
                                                        delete elem._id;
                                                        return elem;
                                                    });
                                                }
                                            }
                                        );

                                    };
                                    ctrl.cancel = function () {
                                        $uibModalInstance.close();
                                    };
                                },
                                controllerAs: "vm",
                                size: "m",
                                resolve: {
                                    templates: function () {
                                        return vm.templates;
                                    },
                                },
                            }).result.then(function(){}, function(){})
                    };

                    vm.openTemplateForRolesModal = function() {
                        $uibModal.open({
                                animation: true,
                                template: modalTemplateForRole,
                                controller: function ($uibModalInstance) {
                                    var ctrl = this;
                                    ctrl.templates = [];
                                    ProjectService.getTemplates('JOB').then((templates) => ctrl.templates = templates);
                                    ctrl.templates = ctrl.templates;
                                    ctrl.selectTemplate = function (template) {
                                        $uibModalInstance.close(template);
                                        template.roles = [];
                                        ProjectService.getRoles(TEMPLATE_ORGANISATION_ID, template._id)
                                            .then((roles) => {
                                        template.roles = roles;
                                        vm.project.name = template.name;
                                        vm.project.description = template.description;
                                        if(template.tags) {
                                            vm.project.tags = template.tags;
                                        }
                                        if(template.start) {
                                            vm.project.start = new Date(template.start);
                                        }
                                        if(template.end) {
                                            vm.project.hasEndDate = true
                                            vm.project.end = new Date(template.end)
                                        } else {
                                            vm.project.hasEndDate = false;
                                        }
                                        if(template.is_remote) {
                                            vm.project.is_remote = template.is_remote;
                                        }
                                        if(template.remote_percentage) {
                                            vm.project.remote_percentage = template.remote_percentage;
                                        }
                                        if(template.locations) {
                                            vm.project.locations = template.locations;
                                            vm.project.is_on_site = true;
                                        }
                                        if(template.state) {
                                            vm.project.state = template.state
                                        }
                                        if(template.roles) {
                                            vm.projectRoles = template.roles.map((elem) => {
                                                if (elem.start) {
                                                    elem.start = new Date(elem.start);
                                                    elem.hasEndDate = false;
                                                }
                                                else{
                                                    elem.start = new Date(project.start);
                                                    elem.end = project.end ? new Date(project.end) : null;
                                                    elem.hasEndDate = project.end ? project.hasEndDate : false;
                                                }
                                                if (elem.end) {
                                                    elem.end = new Date(elem.end);
                                                    elem.hasEndDate = true;
                                                }
                                                elem.fromTemplate = true;
                                                delete elem._id;
                                                return elem;
                                            });
                                        }
                                    });
                                    };
                                    ctrl.cancel = function () {
                                        $uibModalInstance.close();
                                    };
                                },
                                controllerAs: "vm",
                                size: "m",
                                resolve: {
                                    templates: function () {
                                        return vm.templates;
                                    },
                                },
                            }).result.then(function(){}, function(){})
                    };

                    vm.addRolesTemplateToProject = function() {
                        $uibModal.open({
                                animation: true,
                                template: modalTemplateForRole,
                                controller: function ($uibModalInstance) {
                                    var ctrl = this;
                                    ctrl.templates = [];
                                    ProjectService.getTemplates('JOB').then((templates) => ctrl.templates = templates);
                                    ctrl.templates = ctrl.templates;
                                    ctrl.selectTemplate = function (template) {
                                        $uibModalInstance.close(template);
                                        template.roles = [];
                                        ProjectService.getRoles(TEMPLATE_ORGANISATION_ID, template._id)
                                            .then((roles) => {
                                        template.roles = roles;
                                        if(vm.projectRoles.length != 0) {
                                            var newTemplate = ({ ...vm.projectRoles[0] });
                                            newTemplate.$$hashKey = template.$$hashKey;
                                            newTemplate.project_id = template._id;
                                            newTemplate.name = template.name;
                                            newTemplate.description = template.description;
                                            if(template.start) {
                                                newTemplate.start = new Date(template.start);
                                            }
                                            if(template.end) {
                                                newTemplate.hasEndDate = true
                                                newTemplate.end = new Date(template.end);
                                            } else {
                                                newTemplate.hasEndDate = false
                                            }
                                            vm.projectRoles.push(newTemplate);
                                        } else {
                                            vm.projectRoles.push(template)
                                        }
                                    });
                                    };
                                    ctrl.cancel = function () {
                                        $uibModalInstance.close();
                                    };
                                },
                                controllerAs: "vm",
                                size: "m",
                                resolve: {
                                    templates: function () {
                                        return vm.templates;
                                    },
                                },
                            }).result.then(function(){}, function(){})
                    };

                    vm.addRoleTasks = function(role) {
                        if (!role.tasks) {
                            role.tasks = [];
                        }

                        if(vm.newTask){
                            role.tasks.push({
                                description: vm.newTask,
                                time_percentage: vm.newTaskHours || 0
                            });
                            vm.newTask = '';
                            vm.newTaskHours = '';
                        }
                    };

                    vm.deleteTask = function(index) {
                        if (vm.project.tasks) {
                            vm.project.tasks.splice(index, 1);
                        }
                    };

                    vm.deleteRole = function(index) {
                        if (!vm.projectRoles) return;
                        if (vm.projectRoles[index]._id) {
                            ProjectService.deleteOrganisationProjectRole(organisation._id, vm.project._id, vm.projectRoles[index]._id)
                                .then(function(){
                                    vm.projectRoles.splice(index, 1);
                                });
                        }
                        else {
                            vm.projectRoles.splice(index, 1);
                        }
                    };

                    vm.deleteRoleTask = function(role, index) {
                        if (role.tasks) {
                            role.tasks.splice(index, 1);
                        }
                    };

                    vm.searchForTalent = function(query) {
                        if(!query) return;

                        TalentMasterService.search(query).then(function(result) {

                            var combinedResult = result.data;
                            var currentLanguage = l10n.getLanguage();
                            var matchingQuery = result.data.filter(function(element) {
                                return (element[currentLanguage] && (element[currentLanguage].toLowerCase() === query.toLowerCase()));
                            });

                            if(!result.data.length || matchingQuery.length === 0) {
                                var userInputItem = {
                                    _id: query,
                                    en: query,
                                    nl: query,
                                    isNew: true
                                };

                                combinedResult = [userInputItem].concat(combinedResult);
                            }

                            vm.talentsAutocomplete = combinedResult;
                        });
                    };

                    vm.getPostbody = function(){
                        var postbody = {};
                        angular.copy(vm.project, postbody);
                        // remove unused properties
                        if(vm.project.hasEndDate === false){
                            postbody.end = null;
                        }
                        if(vm.project.is_remote !== true){
                            delete postbody.remote_percentage;
                        }

                        delete postbody.hasEndDate;
                        return postbody;
                    };

                    vm.onComplete = function() {
                        if(vm.project.type === 'JOB'){
                            vm.addRoles();
                        }
                        vm.projectRoles.forEach(function(oneRole,index){
                            vm.updateRole(oneRole);
                        });
                        ProjectService.update(organisation._id, vm.getPostbody()).then(function(project) {
                            $state.go('app.manager-view-project', {organisationId: organisation._id, projectId: project._id});
                        });
                        $uibModalInstance.close();
                    };
                    vm.saveProject = function (){
                        if(vm.project && vm.project._id) {
                            vm.updateProject();
                            if(vm.project.type === 'JOB') {
                                vm.projectRoles[0].name = vm.project.name;
                                vm.projectRoles[0].description = vm.project.description;
                                vm.updateRole(vm.projectRoles[0]);

                            }
                        } else {
                            ProjectService.save(organisation._id, vm.project).then(function(project) {
                                vm.project._id = project._id;
                                if(vm.project.type === 'JOB') {
                                    vm.addRoles();
                                    vm.createRoles();
                                }
                            });
                        }
                    };

                    vm.updateProject = function (){

                        ProjectService.update(organisation._id, vm.getPostbody()).then(function(project){
                            // vm.project = project;
                        });
                    };

                    vm.updateRole = function (role){
                        if(role.fromTemplate) {
                            role.project_id = vm.project._id;
                            delete role.fromTemplate;
                            delete role._id;
                            ProjectService.createOrganisationProjectRole(organisation._id, vm.project._id, role).then((result) => role._id = result._id);
                        } else
                        if(role._id) {
                            ProjectService.updateOrganisationProjectRole(organisation._id, vm.project._id, role);
                        } else {
                            ProjectService.createOrganisationProjectRole(organisation._id, vm.project._id, role).then((result) => role._id = result._id);
                        }
                    };

                    vm.createRoles = function (){
                        var newRoles = [];
                        ProjectService.createOrganisationProjectRoles(organisation._id, vm.project._id, vm.projectRoles)
                            .then(function(newRole,index){
                                vm.projectRoles.forEach(function(oneRole,index){
                                    if(newRole[index]) {
                                        vm.projectRoles[index]._id = newRole[index]._id;
                                    }
                                });
                            })
                    }
                    vm.empty = function (){
                    }
                    vm.locationOnNext = function(){
                        if(vm.project.is_remote !== true && vm.project.is_on_site !== true ){
                            vm.noLocationError = true;
                            return true;
                        }else{
                            vm.noLocationError = false;
                            return false;
                        }
                    };
                    vm.checkDisabled = function(roles){
                        if(!roles || roles.length === 0){
                            return true;
                        }
                        var value = roles.every(function(role){
                            return role.name;
                        });
                        return !value;
                    };

                    if ($stateParams.step) {
                        var step = $stateParams.step;
                        var wizard = WizardHandler.wizard();
                        if (wizard){
                            wizard.goTo(step);
                        }
                    }
                    $scope.$watch(function () {
                        return WizardHandler.wizard();
                    }, function (wizard) {
                        if (wizard && step){
                            wizard.goTo(3);
                        }
                    });
                    vm.cancel = function () {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    templates: function () {
                        return;
                    },
                },
            }).result.then(function(){}, function(){})
    };
}
