(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('UserService', UserService);

    UserService.$inject = ['$http', '$q', 'ENV_CONFIG'];

    /* @ngInject */
    function UserService($http, $q, ENV_CONFIG) {
        var subPath = 'users';
        var service = {
            save: save,
            update: update,
            remove: remove,
            changePassword: changePassword,
            getOne: getOne,
            search: search,
            getMe: getMe,
            getOrganisationMemberships: getOrganisationMemberships,
            getOrganisationInvitations: getOrganisationInvitations,
            searchByToken: searchByToken,
            getUserOrganisations: getUserOrganisations
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        function save(user) {
            return $http.post(ENV_CONFIG.baseAPI + subPath, user)
                .then(success);
        }


        function update(userId, data) {
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/' + userId, data)
                .then(success)
                .catch(fail);
        }

        function remove(userId) {
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/' + userId)
                .then(success)
                .catch(fail);
        }

        function changePassword(oldPassword, newPassword) {
            return $http.put(ENV_CONFIG.baseAPI + 'me/password', {oldPassword: oldPassword, newPassword: newPassword})
                .then(success)
                .catch(fail);
        }

        /**
         * GET one user
         *
         * @param userId
         * @returns {*}
         */
        function getOne(userId) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + userId)
                .then(success)
                .catch(fail);
        }

        /**
         *
         * @param query
         * @returns {*}
         */
        function search(query) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/search', {
                params: {
                    fullName: query.fullName,
                    email: query.email
                }
            }).then(success).catch(fail);
        }

        /**
         * GET the current user's profile.
         * This makes use of the BEARER token to determine the user
         *
         * @param userId
         * @returns {*}
         */
        function getMe() {
            return $http.get(ENV_CONFIG.baseAPI + 'me')
                .then(success)
                .catch(fail);
        }

        /**
         * GET user's organisation memberships
         * This makes use of the BEARER token to determine the user
         *
         * @param userId
         * @returns {*}
         */
        function getOrganisationMemberships(userId, params) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + userId + '/organisations', {
                params: params
            })
            .then(success)
            .catch(fail);

        }
        /**
         * GET the passive user firstname lastname email by token.
         *
         * @param token
         * @returns {*}
         */
        function searchByToken (token) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/searchbytoken', {
                params: {
                    token: token
                }
            }).then(success).catch(fail);
        }
        function getOrganisationInvitations(userId, params) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + userId + '/organisations/invitations', {
                params: params
            })
                .then(success)
                .catch(fail);
        }
        function getUserOrganisations(userId) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + userId + '/organisations?includes=organisation')
                .then(success)
                .catch(fail);
        }
    }

})();
