import angular from 'angular';

angular
    .module('app.search.global')
    .controller('GlobalSearchResultsController', GlobalSearchResultsController);

GlobalSearchResultsController.$inject = ['$scope', '$q', '$stateParams', 'logger', 'SearchService'];

/* @ngInject */
function GlobalSearchResultsController($scope, $q, $stateParams, logger, SearchService) {
    var ctrl = this;
    var offset = 0;

    ctrl.qry = "";
    ctrl.result = {
        hasSearched: false,
        total: 0,
        data: []
    };

    ctrl.search = function() {
        SearchService.search(ctrl.qry).then(function(result) {
            ctrl.result.total = result.total;
            ctrl.result.data = result.data;
            ctrl.result.hasSearched = true;
        });
    };

    ctrl.shouldShowProperty = function(user, propertyName) {
        var scopes = [];
        for(var i=0; i < user.scopes.length; i++) {
            scopes[user.scopes[i].property] = user.scopes[i].scope;
        }
        return scopes.hasOwnProperty(propertyName) && scopes[propertyName] != 'private' && user.hasOwnProperty(propertyName) && typeof user[propertyName] !== 'undefined';
    };

    if ($stateParams.query) {
        ctrl.qry = $stateParams.query;

        // Reset search
        offset = 0;
        ctrl.result.hasSearched = true;
        ctrl.result.total = 0;
        ctrl.result.data = [];

        ctrl.search();
    }
}
