(function () {
    'use strict';

    angular
        .module('app.create-passive-profile')
        .controller('CreatePassiveProfileController', CreatePassiveProfileController);

    CreatePassiveProfileController.$inject = ['$scope', '$state', 'logger', '$translate', 'currentUser', 'organisation',
        'l10n', 'OrganisationService', 'TalentMasterService'];

    /* @ngInject */
    function CreatePassiveProfileController($scope, $state, logger, $translate, currentUser, organisation, l10n,
                                            OrganisationService, TalentMasterService) {
        var vm = this;

        vm.currentUser = currentUser;
        vm.userLanguage = l10n.guessLanguage();
        vm.organisation = organisation;
        vm.user = {
            talents: []
        };
        vm.newTalent = null;

        vm.save = function(form) {
            vm.submitted = true;

            if(form.$valid) {
                OrganisationService.saveMember(vm.organisation._id, vm.user)
                    .then(function(result) {
                        logger.success($translate.instant('PASSIVE-PROFILE.CREATE.SUCCESS_MESSAGE'));
                        $state.go('app.organisation-invite-users', {organisationId: organisation._id});
                    })
                    .catch(function(err) {
                        err = err.data;
                        vm.errors = {};

                        // Update validity of form fields that match the mongoose errors
                        angular.forEach(err.errors, function(error, field) {
                            form[field].$setValidity('mongoose', false);
                            vm.errors[field] = error.message;
                        });
                    });
            }
        };

        vm.addTalent = function() {
            if (vm.newTalent == null || vm.newTalent == '') {
                logger.warning('Unable to add talent, something went wrong!', vm.newTalent);
                return;
            } else if (typeof vm.newTalent == 'string' || vm.newTalent instanceof String) {
                // Talent is new and was not provided by the autocomplete function
                var talent = {};
                talent['en'] = vm.newTalent;
                talent['nl'] = vm.newTalent;
                
                // First save the talent
                TalentMasterService.save(talent).then(function(talent) {
                    // Next push the project-talent
                    var projectTalent = angular.copy(talent);
                    projectTalent.talent_id = projectTalent._id;
                    delete projectTalent._id;
                    vm.user.talents.push(projectTalent);
                    vm.newTalent = null;
                });
            } else if (angular.isObject(vm.newTalent)) {
                // Talent was hopefully provided by the autocomplete function
                var projectTalent = angular.copy(vm.newTalent);
                projectTalent.talent_id = projectTalent._id;
                delete projectTalent._id;
                vm.user.talents.push(projectTalent);
                vm.newTalent = null;
            }
        };

        vm.removeTalent = function(talent, index) {
            vm.user.talents.splice(index, 1);
        };
    }
})();
