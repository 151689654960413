import angular from "angular";
import template from "./change-password.html";

let ChangePassword = {
    bindings: {
        organisationId: "<",
    },
    template: template,
    controller: "ChangePasswordController",
};

angular.module("app.account").component("changePassword", ChangePassword);
