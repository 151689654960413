import angular from 'angular';
import inviteModalTemplate from "../../organisation/manage-organisation/invite-users/invite-users.html";

angular
    .module('app.project.manager-view-project')
    .controller('ManagerViewProjectController', ManagerViewProjectController);

ManagerViewProjectController.$inject = ['$rootScope', '$scope', '$q', 'logger', 'l10n', 'currentUser', 'project', 'projectRoles', 'organisation', 'assignedUsers', 'ProjectService', '$translate', '$state', 'Util', '$uibModal', 'UserService', 'OrganisationInvitationService', 'OrganisationService', 'myOrganisation'];

/* @ngInject */
function ManagerViewProjectController($rootScope, $scope, $q, logger, l10n, currentUser, project, projectRoles, organisation, assignedUsers, ProjectService, $translate, $state, Util, $uibModal, UserService, OrganisationInvitationService, OrganisationService, myOrganisation) {

    const ctrl = this;

    ctrl.isEditing = false;
    ctrl.userLanguage = l10n.guessLanguage();
    ctrl.currentUser = currentUser;
    ctrl.myOrganisation = myOrganisation;
    let DUPLICATE = $translate.instant('PROJECT.DUPLICATE');
    ctrl.project = project;
    ctrl.projectRoles = projectRoles;
    ctrl.selectedRole = projectRoles.length ? projectRoles[0] : null;
    ctrl.matchingFor = ctrl.selectedRole;
    ctrl.organisation = organisation;
    ctrl.application = {};
    ctrl.assignedUsers = assignedUsers;
    console.log(assignedUsers);
    ctrl.contactFormVisible = false;
    ctrl.connectionTypeH = ctrl.connectionType;

    if ($state.current.name === 'app.manager-view-project') {
        ctrl.viewer = 'manager';

        ProjectService.getMessages(organisation._id, project._id, 'QUESTION')
            .then(function (questions) {
                ctrl.questions = questions;
            });

        ProjectService.getMessages(organisation._id, project._id, 'INTERESTED')
            .then(function (applications) {
                ctrl.applications = applications;
            });
    } else if ($state.current.name === 'app.view-project') {
        ctrl.viewer = 'enduser';
    }
    ctrl.setActiveRole = function (role) {
        ctrl.selectedRole = role;
        ctrl.matchingFor = role;
        ctrl.getMatchingFor(role._id, ctrl.connectionType);
    }

    ctrl.selectedRoleTalentCount = _(ctrl.selectedRole)
        .chain()
        .pick('knowledge', 'general', 'languages_writing', 'languages_understanding', 'languages_speaking', 'tools', 'personality')
        .values()
        .reduce(function (memo, collection) { return memo + collection.length; }, 1)
        .value();

    ctrl.sendContactForm = function () {
        ProjectService.apply(organisation._id, project._id, ctrl.application).then(function (result) {
            ctrl.application = {};
            ctrl.contactFormVisible = false;
            logger.success($translate.instant('PROJECTS.VIEW.CONTACT.SUCCESS_MESSAGE'));
        });
    };

    ctrl.showContactForm = function (type) {
        ctrl.application.type = type;
        ctrl.contactFormVisible = true;
    };

    ctrl.cancelContactForm = function () {
        ctrl.contactFormVisible = false;
        ctrl.application = {};
    };

    ctrl.getMatchingFor = function (roleId, type) {
        if (!roleId) return;
        var filter;
        if (type !== "ALL") {
            filter = type;
        }
        ProjectService.getMatchingConnectionsForProjectRole(organisation._id, project._id, roleId, filter)
            .then(function (result) {
                ctrl.matches = result;
            });
    };

    ctrl.getMatchingForHapponomy = function (roleId, type) {
        if (!roleId) return;
        var filter;
        if (type !== "ALL") {
            filter = type;
        }
        ProjectService.getMatchingConnectionsForProjectRoleHapponomy(organisation._id, project._id, roleId, filter)
            .then(function (result) {
                removeMatchingObjects(result);
            });
    };

    ctrl.duplicateRole = function (role) {

        var duplicatedRole = angular.copy(role, {});
        delete duplicatedRole._id;
        duplicatedRole.name = DUPLICATE + " " + duplicatedRole.name;
        var templateProject = angular.copy(ctrl.project, {});
        ProjectService.createOrganisationProjectRole(organisation._id, ctrl.project._id, duplicatedRole)
            .then(function (role) {
                ctrl.projectRoles.push(role);
            });
    };

    ctrl.assignToRole = function (hit) {
        var assignedConection = {
            userId: hit.user._id,
            createdBy: ctrl.currentUser._id,
            roleId: ctrl.matchingFor._id
        };
        ProjectService.createAssignedConnection(ctrl.project._id, assignedConection).then(function (connection) {
            ctrl.assignedUsers.push(connection);
        });
    };

    ctrl.availableToAssign = function (hit) {
        if (!ctrl.matchingFor) return;
        var roleConection = ctrl.assignedUsers.filter(function (connection) {
            return connection.user._id === hit.user._id && connection.role._id === ctrl.matchingFor._id;
        });

        return !roleConection.length;
    };

    ctrl.unassignRole = function (hit) {
        var roleConection = ctrl.assignedUsers.filter(function (connection) {
            return connection.user._id === hit.user._id && connection.role._id === ctrl.matchingFor._id;
        });
        ProjectService.removeAssignedConnection(ctrl.project._id, roleConection[0]._id).then(function (connection) {
            var index = Util.findIndexByAttribute(ctrl.assignedUsers, '_id', connection._id);
            ctrl.assignedUsers.splice(index, 1);
        });
    };

    ctrl.openInviteMemberModal = function (user) {
        $uibModal.open({
            animation: true,
            template: inviteModalTemplate,
            controller: function ($uibModalInstance) {
                var vm = this;
                vm.user = {};
                vm.user.firstname = user.firstname;
                vm.user.lastname = user.lastname;
                vm.user.email = user.email;
                vm.search = function (form) {
                    vm.submitted = true;
                    if (form.$valid) {
                        var qry = {
                            fullName: vm.user.firstname + ' ' + vm.user.lastname,
                            email: vm.user.email
                        };
                        UserService.search(qry)
                            .then(searchResultHandler)
                            .catch(function (err) {
                                err = err.data;
                                vm.errors = {};

                                // Update validity of form fields that match the mongoose errors
                                angular.forEach(err.errors, function (error, field) {
                                    form[field].$setValidity('mongoose', false);
                                    vm.errors[field] = error.message;
                                });
                            });
                    }
                };
                vm.backStep = function () {
                    vm.result.hasSearched = !vm.result.hasSearched;
                    vm.showForm = false;
                    vm.result.newEmail = true;
                };

                vm.inviteUser = function (user) {
                    if (!user) {
                        logger.warning('No user selected to invite! Select a user before sending an invitation.');
                        return;
                    }

                    OrganisationInvitationService.invite(myOrganisation._id, user._id, { type: vm.user.connectiontype }).then(function (invitation) {

                        logger.success($translate.instant('INVITE-USERS.SUCCESS-MESSAGE'));
                        $uibModalInstance.close();
                    });
                };

                function searchResultHandler(result) {
                    vm.result = {};
                    vm.result.total = result.total;
                    vm.result.data = result.data;
                    vm.result.newEmail = true;
                    vm.result.data.forEach(function (element) {
                        if (vm.user.email == element.email) {
                            vm.result.newEmail = false;
                        }
                    });
                    if (!vm.result.total)
                        vm.showForm = true;
                    vm.result.hasSearched = true;
                    vm.submitted = false;
                };
                vm.cancel = function () {
                    $uibModalInstance.close();
                };
            },
            controllerAs: "vm",
            size: "lg",
            resolve: {
                templates: function () {
                    return;
                },
            },
        }).result.then(function () { }, function () { })
    };

    $rootScope.$on('$translateChangeSuccess', function () {
        DUPLICATE = $translate.instant('PROJECT.DUPLICATE');
    });

    if (ctrl.selectedRole != null && ctrl.viewer === 'manager') {
        ctrl.getMatchingFor(ctrl.selectedRole._id);
    }

    // Check in the list of members in the organization, if the member in the matching list already exists in the organization, we do not display it in the matching list
    function removeMatchingObjects(result) {
        ctrl.matchesH = result.filter(function (match) {
            return !ctrl.matches?.some(function (m) {
                return m.user_id === match.user_id;
            });
        });
    }
}
