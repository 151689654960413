import angular from "angular";
import editMemberForm from '../../../organisation/edit-membership-privileges/edit-membership-privileges-form.html';

angular
    .module("app.organisation.manage-organisation")
    .controller(
        "ManageOrganisationMembersController",
        ManageOrganisationMembersController
    );

ManageOrganisationMembersController.$inject = [
    "$state",
    "$stateParams",
    "$scope",
    "$q",
    "logger",
    "currentUser",
    "myOrganisation",
    "features",
    "memberships",
    "invitations",
    "toastr",
    "OrganisationInvitationService",
    "OrganisationService",
    "$uibModal",
    "ProjectService",
    "AuthService",
];

/* @ngInject */
function ManageOrganisationMembersController(
    $state,
    $stateParams,
    $scope,
    $q,
    logger,
    currentUser,
    myOrganisation,
    features,
    memberships,
    invitations,
    toastr,
    OrganisationInvitationService,
    OrganisationService,
    $uibModal,
    ProjectService,
    AuthService
) {
    var ctrl = this;
    ctrl.currentUser = currentUser;
    ctrl.organisation = myOrganisation;
    ctrl.memberships = memberships;
    ctrl.invitations = invitations;
    ctrl.features = features;
    ctrl.isEditing = false;
    ctrl.newInvitation = {
        selectedUser: null,
    };
    $scope.activeTabIndex = parseInt($stateParams.tab);
    ctrl.visibleProjectsByState = ["DRAFT", "PUBLISHED"];
    ctrl.stateOptions = [
        { name: "DRAFT", description: "Draft" },
        { name: "PUBLISHED", description: "Published" },
        { name: "ARCHIVED", description: "Archived" },
    ];

    ctrl.filterByState = function (project) {
        return ctrl.visibleProjectsByState.indexOf(project.state) !== -1;
    };

    ctrl.toggleProjectStateVisible = function (state) {
        var idx = ctrl.visibleProjectsByState.indexOf(state);
        // is currently selected
        if (idx > -1) {
            ctrl.visibleProjectsByState.splice(idx, 1);
        } else {
            ctrl.visibleProjectsByState.push(state);
        }
    };

    if (features.projects) {
        OrganisationService.getProjects($stateParams.organisationId).then(
            function (projects) {
                ctrl.projects = projects;
            }
        );
    }

    ctrl.editForm = {
        organisation: angular.copy(ctrl.organisation, {}),
    };

    ctrl.setEditForm = function (organisationForm) {
        if (organisationForm) {
            ctrl.organisationForm = organisationForm;
        }
    };

    ctrl.toggleEditing = function () {
        ctrl.isEditing = !ctrl.isEditing;
    };

    ctrl.save = function () {
        if (
            ctrl.organisationForm &&
            !ctrl.organisationForm.$invalid &&
            ctrl.isEditing
        ) {
            OrganisationService.update(
                myOrganisation._id,
                ctrl.editForm.organisation
            ).then(function (organisation) {
                ctrl.organisation = organisation;
                ctrl.isEditing = !ctrl.isEditing;
            });
        } else {
            logger.warning(
                "Cannot update organisation with the current state",
                ctrl.organisationForm
            );
        }
    };

    ctrl.cancel = function () {
        ctrl.editForm = {
            organisation: angular.copy(ctrl.organisation, {}),
        };
        ctrl.isEditing = !ctrl.isEditing;
    };

    ctrl.invite = function () {
        if (
            !ctrl.newInvitation.selectedUser ||
            !ctrl.newInvitation.selectedUser._id
        ) {
            logger.warning(
                "No user selected to invite! Select a user before sending an invitation."
            );
            return;
        }

        OrganisationInvitationService.invite(
            ctrl.organisation._id,
            ctrl.newInvitation.selectedUser._id,
            { includes: ["user"] }
        ).then(function (invitation) {
            ctrl.invitations.push(invitation);
            ctrl.newInvitation.selectedUser = null;
        });
    };

    ctrl.reject = function (invitation) {
        if (!invitation) {
            logger.error(
                "Expected an invitation to remove. No invitation was passed to remove."
            );
            return;
        }

        OrganisationInvitationService.reject(
            ctrl.organisation._id,
            invitation._id
        ).then(function (invitation) {
            var index = ctrl.invitations.indexOf(invitation);
            ctrl.invitations.splice(index, 1);
        });
    };

    ctrl.removeMembership = function (membership) {
        if (membership.role == "CHIEF") {
            logger.error(
                "You cannot leave an organisation while you are chief! Assign a new chief or delete the organisation from the organisation's profile"
            );
            return;
        }

        OrganisationService.removeMember(
            membership.organisation_id,
            membership._id
        )
            .then(function (result) {
                var index = ctrl.memberships.indexOf(membership);
                ctrl.memberships.splice(index, 1);
            })
            .catch(function (err) {
                console.log(err);
                if (
                    err.key ==
                    "error.validation.leave-organisation.is-project-owner"
                ) {
                    ctrl.errorHandler(err.message, membership);
                }
            });
    };

    ctrl.filterConnectionType = function () {
        let filter;
        if (ctrl.connectionType) {
            filter = ctrl.connectionType;
            OrganisationService.getMemberships($stateParams.organisationId, {
                includes: ["user"],
                filter: ctrl.connectionType == 'ALL' ? undefined : filter,
            }).then(function (memberships) {
                ctrl.memberships = memberships;
            });
        }
    };

    ctrl.handoverChief = function (membership) {
        let dialog = $uibModal.open({
            backdrop: "static",
            size: "lg",
            template: require("~/app/organisation/manage-organisation/manage-members/manage-members-handover-chief.html"),
            controller: function (membership) {
                let ctrl = this;
                ctrl.membership = membership;

                ctrl.handoverChief = function () {
                    OrganisationService.handoverChief(
                        membership.organisation_id,
                        membership._id
                    ).then(function (res) {
                        AuthService.refreshCurrentUserMemberships().then(
                            function (res) {
                                $state.go("app.my-organisation", {
                                    organisationId: membership.organisation_id,
                                });
                                dialog.close("res");
                            }
                        );
                    });
                };

                ctrl.cancel = function () {
                    dialog.close("cancel");
                };
            },
            controllerAs: "vm",
            resolve: {
                membership: function () {
                    return membership;
                },
            },
        });
    };

    ctrl.showEditPrivilegesForm = function (membership) {
        var dialog = $uibModal.open({
            backdrop: "static",
            size: "lg",
            template: editMemberForm,
            controller: "EditMembershipPrivilegesController",
            controllerAs: "vm",
            resolve: {
                currentUser: function () {
                    return ctrl.currentUser;
                },
                membership: function () {
                    return membership;
                },
            },
        });

        dialog.result.then(
            function (membership) {},
            function () {
                // cancelled
            }
        );
    };

    ctrl.errorHandler = function (err, owner) {
        var dialog = $uibModal.open({
            backdrop: "static",
            template:
                "/app/organisation/manage-organisation/manage-members/error-owner.html",
            controller: function (
                err,
                organisation,
                projects,
                owner,
                memberships
            ) {
                var vm = this;
                vm.err = err;
                vm.memberships = memberships;
                vm.organisation = organisation;
                vm.owner = owner;
                vm.ownerProject = projects.filter(function (project) {
                    if (project.owner) {
                        return project.owner._id == vm.owner.user._id;
                    }
                });
                vm.startEditing = function (project) {
                    vm.startEditingProject = !vm.startEditingProject;
                    vm.selectedProject = project;
                };

                vm.saveOwner = function (selectedProject) {
                    if (vm.owner.user._id != selectedProject.owner._id) {
                        ProjectService.update(
                            organisation._id,
                            selectedProject
                        );
                        var index = vm.ownerProject.indexOf(selectedProject);
                        vm.ownerProject.splice(index, 1);
                    }
                    vm.startEditingProject = !vm.startEditingProject;
                };

                vm.cancel = function () {
                    dialog.close();
                };

                vm.deleteMember = function () {
                    OrganisationService.removeMember(
                        organisation._id,
                        owner._id
                    ).then(function (result) {
                        var index = vm.memberships.indexOf(owner);
                        vm.memberships.splice(index, 1);
                        dialog.close();
                    });
                };
            },
            controllerAs: "vm",
            resolve: {
                err: function () {
                    return err;
                },
                organisation: function () {
                    return ctrl.organisation;
                },
                memberships: function () {
                    return ctrl.memberships;
                },
                projects: function () {
                    return ctrl.projects;
                },
                owner: function () {
                    return owner;
                },
            },
        });
    };

    ctrl.sendReminder = function(userId) {
        OrganisationInvitationService.sendReminder(userId, {
        }).then(function () {
            toastr.success('Reminder sent successfully');
        });
    }
}
