/* eslint-disable quotes */
import angular from 'angular';

angular
  .module('app.config', [])
  .constant('environment', "production")
  .constant('ENV_CONFIG', {"opbeat":{"orgId":"e0cfc99a7c054d38b317745f2e029afa","appId":"984d4a349e"},"baseURI":"https://app.tribeforce.com","baseAPI":"https://app.tribeforce.com/api/","version":"unknown"});

export default 'app.config';

