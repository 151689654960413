import angular from 'angular';
import appCore from '../core';
import appWidgets from '../widgets';
import myProfile from './my-profile';
import publicProfile from './public-profile';
import availability from './my-profile/availability';
import feedback from './my-profile/feedback';
import work from './my-profile/work';


angular.module('app.profile', [
    appCore,
    appWidgets,
    myProfile,
    publicProfile,
    availability,
    feedback,
    work
]);
