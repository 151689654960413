import angular from "angular";

angular
    .module("app.dashboard.statistics")
    .controller("StatisticsController", StatisticsController);

StatisticsController.$inject = ['$stateParams'];

/* @ngInject */
function StatisticsController($stateParams) {
    var vm = this;
    vm.message = $stateParams.message;
}

export default StatisticsController;
