import angular from "angular";
import createTemplate from "./create-organisation-form.html";

angular.module("app.organisation").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.create-organisation",
            config: {
                url: "/new-organisation",
                template: createTemplate,
                controller: "CreateOrganisationFormController",
                controllerAs: "orgCtrl",
                title: "Create new organisation",
            },
        },
    ];
}
