import angular from 'angular';

angular
    .module('app.account.register')
    .controller('AccountRegisterController', AccountRegisterController);

AccountRegisterController.$inject = ['$state', 'ENV_CONFIG', 'AuthService', 'toastr', 'l10n','passiveUser'];

/* @ngInject */
function AccountRegisterController($state, ENV_CONFIG, AuthService, toastr, l10n, passiveUser) {
    /* jshint validthis: true */
    var vm = this;
    vm.passiveUser = passiveUser;
    vm.user = {};
    if(passiveUser){
        vm.user.firstname = passiveUser.firstname;
        vm.user.lastname = passiveUser.lastname;
        vm.user.email = passiveUser.email;
    }
    vm.errors = {};
    vm.user.language = l10n.guessLanguage();

    vm.register = function(form) {
        vm.submitted = true;
        vm.error = null;

        if(form.$valid) {
            AuthService.createUser({
                firstname: vm.user.firstname,
                lastname: vm.user.lastname,
                email: vm.user.email,
                password: vm.user.password,
                register_token: passiveUser.token
            })
                .then(function(result) {
                    if(!result.onboarding_completed) {
                        $state.go('app-nomenu.onboarding');
                    } else {
                        $state.go('app.my-profile');
                    }
                })
                .catch(function(err) {
                    var response = err.data;
                    if(response) {

                        vm.errors = {};

                        // Update validity of form fields that match the mongoose errors
                        angular.forEach(response.errors, function(error, field) {
                            form[field].$setValidity('mongoose', false);
                            vm.errors[field] = error.message;
                        });
                    } else {
                        toastr.error('An unknown error has ocurred, please contact support.', 'Error');
                    }

                });
        }
    };
}
