(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('UserTalentGroupService', UserTalentGroupService);

    UserTalentGroupService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function UserTalentGroupService($http, $q, logger, ENV_CONFIG) {
        var prefixPath = 'users';
        var subPath = 'talentgroups';

        var service = {
            save: save,
            update: update,
            remove: remove,
            getOne: getOne,
            getAll: getAll
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        /**
         * Add new TalentGroup for given User
         *
         * @param {number} userId
         * @param {Object} userTalentGroup
         * @returns {*}
         */
        function save(userId, userTalentGroup) {
            return $http.post(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath, userTalentGroup)
                .then(success)
                .catch(fail);
        }

        function update(userId, userTalentGroup) {
            // If talentgroup has cached talents, remove them
            if(userTalentGroup.tmpTalents) {
                delete userTalentGroup.tmpTalents;
            }

            return $http.put(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath + '/' + userTalentGroup._id, userTalentGroup)
                .then(success)
                .catch(fail);
        }

        function remove(userId, userTalentGroupId) {
            return $http.delete(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath + '/' + userTalentGroupId)
                .then(success)
                .catch(fail);
        }

        /**
         * GET one talent of given user
         *
         * @param {Number} userId
         * @param {Number} userTalentGroupId
         * @returns {*}
         */
        function getOne(userId, userTalentGroupId) {
            return $http.get(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath + '/' + userTalentGroupId)
                .then(success)
                .catch(fail);
        }

        /**
         * GET all of given users' talents
         *
         * @param userId
         * @returns {*}
         */
        function getAll(userId) {
            return $http.get(ENV_CONFIG.baseAPI + prefixPath + '/'+ userId + '/' + subPath)
                .then(success)
                .catch(fail);
        }

    }

})();
