import angular from "angular";
import template from "./onboarding-talent-toggler.html";

angular
    .module("app.widgets")
    .directive("onboardingTalentToggler", OnboardingTalentToggler);

OnboardingTalentToggler.$inject = [];

/* @ngInject */
function OnboardingTalentToggler() {
    var directive = {
        scope: {
            data: "=",
            state: "@",
            user: "=",
        },
        template: template,
        controller: OnboardingTalentTogglerController,
        controllerAs: "vm",
        bindToController: true,
        restrict: "E",
    };

    return directive;
}

OnboardingTalentTogglerController.$inject = [
    "TalentMasterService",
    "UserTalentService",
    "l10n",
    "toastr",
];

/* @ngInject */
function OnboardingTalentTogglerController(
    TalentMasterService,
    UserTalentService,
    l10n,
    toastr
) {
    var vm = this;

    vm.toggleState = function (talent, state) {
        talent[state] = !talent[state];

        UserTalentService.update(vm.user._id, talent).catch(function (err) {
            toastr.error(err, "Error");
            talent[state] = !talent[state];
        });
    };

    vm.searchForTalent = function (query) {
        if (!query) return;

        TalentMasterService.search(query).then(function (result) {
            var combinedResult = result.data;
            var currentLanguage = l10n.getLanguage();
            var matchingQuery = result.data.filter(function (element) {
                return (
                    element[currentLanguage] &&
                    element[currentLanguage].toLowerCase() ===
                        query.toLowerCase()
                );
            });

            if (!result.data.length || matchingQuery.length === 0) {
                var userInputItem = {
                    _id: query,
                    en: query,
                    nl: query,
                    isNew: true,
                };

                combinedResult = [userInputItem].concat(combinedResult);
            }

            vm.talentsAutocomplete = combinedResult;
        });
    };

    vm.onSelectCallback = function (item, model) {
        item[vm.state] = true;
    };
}
