import angular from "angular";

angular
    .module("app.profile.my-profile")
    .controller("MyProfileController", MyProfileController);

MyProfileController.$inject = [
    "$window",
    "$timeout",
    "$rootScope",
    "$scope",
    "$q",
    "logger",
    "UserService",
    "currentUser",
    "OrganisationService",
    "Util",
    "OrganisationInvitationService",
    "ProjectService",
    "AuthService",
    "MeService",
    "UploadPictureService",
    "$state",
    "ngIntroService",
    "$stateParams",
    "$translate",
    "$uibModal",
];

/* @ngInject */
function MyProfileController(
    $window,
    $timeout,
    $rootScope,
    $scope,
    $q,
    logger,
    UserService,
    currentUser,
    OrganisationService,
    Util,
    OrganisationInvitationService,
    ProjectService,
    AuthService,
    MeService,
    UploadPictureService,
    $state,
    ngIntroService,
    $stateParams,
    $translate,
    $uibModal
) {
    var ctrl = this;
    ctrl.currentUser = currentUser;
    ctrl.isEditing = false;
    if ($stateParams.tour) {
        ctrl.IntroOptions = {
            steps: [
                {
                    // element: "#step1",
                    intro:
                        '<p class="blue-grey f-s-20">' +
                        $translate.instant("PRODUCT_TOUR.STEP_1_TITLE") +
                        "</p>" +
                        $translate.instant("PRODUCT_TOUR.STEP_1"),
                    position: "top",
                    scrollTo: "tooltip",
                },
                {
                    element: "#step2",
                    intro:
                        '<p class="blue-grey f-s-20">' +
                        $translate.instant("PRODUCT_TOUR.STEP_2_TITLE") +
                        "</p>" +
                        $translate.instant("PRODUCT_TOUR.STEP_2"),
                    position: "right",
                },
                {
                    element: "#step4",
                    intro:
                        '<p class="blue-grey f-s-20">' +
                        $translate.instant("PRODUCT_TOUR.STEP_4_TITLE") +
                        "</p>" +
                        $translate.instant("PRODUCT_TOUR.STEP_4"),
                    position: "left",
                },
                {
                    element: "#step4",
                    intro:
                        '<p class="blue-grey f-s-20">' +
                        $translate.instant("PRODUCT_TOUR.STEP_4_TITLE") +
                        "</p>" +
                        $translate.instant("PRODUCT_TOUR.STEP_4"),
                },
            ],
            showStepNumbers: false,
            showBullets: false,
            exitOnOverlayClick: true,
            exitOnEsc: true,
            hidePrev: true,
            hideNext: true,
            skipLabel: $translate.instant("PRODUCT_TOUR.SKIP"),
            nextLabel: $translate.instant("PRODUCT_TOUR.BUTTON_1"),
            prevLabel: $translate.instant("PRODUCT_TOUR.PREV_BUTTON"),
            doneLabel: $translate.instant("PRODUCT_TOUR.FINISH"),
        };

        if ($stateParams.step) {
            ctrl.IntroOptions.nextLabel = $translate.instant(
                "PRODUCT_TOUR.FINISH"
            );
            ngIntroService.setOptions(ctrl.IntroOptions);
            ngIntroService.start(+$stateParams.step || 1);
            $(".introjs-nextbutton ")
                .css("color", "white")
                .css("background", "#3399ff")
                .css("text-shadow", "none");
        } else {
            ngIntroService.setOptions(ctrl.IntroOptions);
            $timeout(ngIntroService.start.bind(ngIntroService));
        }
    }
    ngIntroService.onAfterChange(function () {
        $(".introjs-nextbutton ")
            .css("color", "white")
            .css("background", "#3399ff")
            .css("text-shadow", "none");
        if (ngIntroService.intro._currentStep === 0) {
            $(".introjs-nextbutton ").html(
                $translate.instant("PRODUCT_TOUR.BUTTON_1")
            );
            document.body.scrollTop = 0;
        }
        if (ngIntroService.intro._currentStep === 1) {
            $(".introjs-nextbutton ").html(
                $translate.instant("PRODUCT_TOUR.BUTTON_2")
            );
            $(".introjs-prevbutton").css("display", "none");
        }
        if (ngIntroService.intro._currentStep === 2) {
            $(".introjs-prevbutton").css("display", "inline");
            $(".introjs-nextbutton ").html(
                $translate.instant("PRODUCT_TOUR.FINISH")
            );
            if (checkTalents()) {
                $state.go("app.my-talent", { userTalentId: checkTalents() });
            }
        }
        if (ngIntroService.intro._currentStep === 3) {
            if (ctrl.memberships.length) {
                $state.go("app.my-organisation", {
                    organisationId: ctrl.memberships[0].organisation_id,
                });
            } else {
                ngIntroService.clear();
            }
        }
    });
    ngIntroService.onComplete(function () {});
    function checkTalents() {
        if (currentUser.knowledge.length) return currentUser.knowledge[0];
        if (currentUser.personality.length) return currentUser.personality[0];
        if (currentUser.tools.length) return currentUser.tools[0];
        if (currentUser.languages_speaking.length)
            return currentUser.languages_speaking[0];
        if (currentUser.languages_understanding.length)
            return currentUser.languages_understanding[0];
        if (currentUser.languages_writing.length)
            return currentUser.languages_writing[0];
        if (currentUser.languages_mother_tongue.length)
            return currentUser.languages_mother_tongue[0];
        if (currentUser.general.length) return currentUser.general[0];
        else return false;
    }

    ctrl.address = currentUser.address;

    ctrl.editForm = {
        user: angular.copy(ctrl.currentUser, {}),
        address: angular.copy(ctrl.address, {}),
    };

    if (ctrl.editForm.user.date_of_birth) {
        ctrl.editForm.user.date_of_birth = new Date(
            ctrl.editForm.user.date_of_birth
        );
    }

    ctrl.memberships = [];

    ctrl.matchingProjects = {
        data: [],
    };

    UserService.getOrganisationMemberships(currentUser._id, {
        includes: ["organisation"],
    }).then(function (memberships) {
        ctrl.memberships = memberships;

        var organisations = [];
        if (memberships && memberships.length) {
            memberships.forEach(function (membership) {
                organisations[membership.organisation_id] =
                    membership.organisation;
            });
        }

        ctrl.organisations = organisations;
    });

    UserService.getOrganisationInvitations(currentUser._id, {
        includes: ["organisation"],
    }).then(function (invitations) {
        ctrl.invitations = invitations;
        if (invitations.length) {
            var invitation;
            for (var i = 0; i < invitations.length; i++) {
                if (invitations[i]._id === $state.params.invitationId) {
                    invitation = invitations[i];
                    break;
                }
            }
            if ($state.params.action == "accept") {
                ctrl.acceptInvitation(invitation, invitation._id);
            } else if ($state.params.action == "reject") {
                ctrl.rejectInvitation(invitation, invitation._id);
            }
            openDialog();
            $state.go(
                ".",
                { action: undefined, invitationId: undefined },
                { notify: false }
            );
        }
    });
    function openDialog() {
        var dialog = $uibModal.open({
            backdrop: "static",
            size: "md",
            template: require("~/app/profile/shared/organisation-invitation.html"),
            controller: function (invitations) {
                var vm = this;
                vm.invitations = invitations;
                vm.accept = function (invitation) {
                    ctrl.acceptInvitation(invitation, invitation._id);
                    var index = vm.invitations.indexOf(invitation);
                    vm.invitations.splice(index, 1);
                    if (!vm.invitations.length) {
                        vm.cancel();
                    }
                };
                vm.decline = function (invitation) {
                    ctrl.rejectInvitation(invitation, invitation._id);
                    var index = vm.invitations.indexOf(invitation);
                    vm.invitations.splice(index, 1);
                    if (!vm.invitations.length) {
                        vm.cancel();
                    }
                };
                vm.cancel = function () {
                    dialog.close();
                };
            },
            controllerAs: "vm",
            resolve: {
                invitations: [
                    "UserService",
                    function (UserService) {
                        return UserService.getOrganisationInvitations(
                            currentUser._id,
                            { includes: ["organisation"] }
                        );
                    },
                ],
            },
        });
    }
    // ProjectService.getMatchingProjectsForUser(1, currentUser._id).then(function(projects) {
    //     ctrl.matchingProjects = projects;
    // });

    ctrl.setEditForm = function (memberForm) {
        if (memberForm) {
            ctrl.memberForm = memberForm;
        }
    };

    ctrl.toggleEditing = function () {
        ctrl.isEditing = !ctrl.isEditing;
    };

    ctrl.save = function () {
        if (ctrl.memberForm && !ctrl.memberForm.$invalid && ctrl.isEditing) {
            // -- set the address
            //if (!ctrl.editForm.user.address) ctrl.editForm.user.address = [];
            //if (ctrl.editForm.user.address.length > 0) {
            //    ctrl.editForm.user.address[0] = ctrl.editForm.address;
            //} else {
            //    ctrl.editForm.user.address.push(ctrl.editForm.address);
            //}

            ctrl.editForm.user.address = ctrl.editForm.address;

            UserService.update(currentUser._id, ctrl.editForm.user).then(
                function (user) {
                    ctrl.currentUser = user;
                    ctrl.address =
                        user.address && user.address.length > 0
                            ? user.address[0]
                            : {};
                    ctrl.isEditing = !ctrl.isEditing;
                    angular.copy(user, currentUser); // Update the user's settings
                    AuthService.refreshCurrentUser();
                }
            );
        } else {
            logger.warning(
                "Cannot update profile with the current state",
                ctrl.memberForm
            );
        }
    };

    ctrl.savePrivacy = function () {
        ctrl.editForm.user.address = ctrl.editForm.address;

        UserService.update(currentUser._id, ctrl.editForm.user).then(function (
            user
        ) {
            ctrl.currentUser = user;
            ctrl.address =
                user.address && user.address.length > 0 ? user.address[0] : {};
            ctrl.privacyIsEditing = !ctrl.privacyIsEditing;
            angular.copy(user, currentUser); // Update the user's settings
        });
    };

    ctrl.cancelPrivacy = function () {
        ctrl.editForm = {
            user: angular.copy(ctrl.currentUser, {}),
            address: angular.copy(ctrl.address, {}),
        };

        ctrl.privacyIsEditing = !ctrl.privacyIsEditing;
    };

    ctrl.cancel = function () {
        ctrl.editForm = {
            user: angular.copy(ctrl.currentUser, {}),
            address: angular.copy(ctrl.address, {}),
        };

        if (ctrl.editForm.user.date_of_birth) {
            ctrl.editForm.user.date_of_birth = new Date(
                ctrl.editForm.user.date_of_birth
            );
        }

        ctrl.isEditing = !ctrl.isEditing;
    };

    ctrl.addPhoneNumber = function () {
        if (!ctrl.editForm.user.phone_numbers) {
            ctrl.editForm.user.phone_numbers = [];
        }

        ctrl.editForm.user.phone_numbers.push({ number: "" });
    };

    ctrl.deletePhoneNumber = function (index) {
        if (ctrl.editForm.user.phone_numbers) {
            ctrl.editForm.user.phone_numbers.splice(index, 1);
        }
    };
    ctrl.addEmail = function () {
        if (!ctrl.editForm.user.emails) {
            ctrl.editForm.user.emails = [];
        }
        ctrl.editForm.user.emails.push({ email: "" });
    };
    ctrl.deleteEmail = function (index) {
        if (ctrl.editForm.user.emails) {
            ctrl.editForm.user.emails.splice(index, 1);
        }
    };

    ctrl.acceptInvitation = function (invitation) {
        if (!invitation) {
            logger.error(
                "Expected an invitation to remove. No invitation was passed to remove."
            );
            return;
        }

        OrganisationInvitationService.accept(
            invitation.organisation_id,
            invitation._id
        ).then(function (membership) {
            var index = ctrl.invitations.indexOf(invitation);
            ctrl.invitations.splice(index, 1);

            OrganisationService.getOne(membership.organisation_id).then(
                function (organisation) {
                    ctrl.organisations[organisation._id] = organisation;
                    // Asign organisation to membership (needed for my-talents directive)
                    membership.organisation = organisation;
                    ctrl.memberships.push(membership);
                    AuthService.refreshCurrentUserMemberships();
                }
            );
        });
    };

    ctrl.rejectInvitation = function (invitation) {
        if (!invitation) {
            logger.error(
                "Expected an invitation to remove. No invitation was passed to remove."
            );
            return;
        }

        OrganisationInvitationService.reject(
            invitation.organisation_id,
            invitation._id
        ).then(function (invitation) {
            var index = ctrl.invitations.indexOf(invitation);
            ctrl.invitations.splice(index, 1);
        });
    };

    ctrl.removeMembership = function (membership) {
        if (membership.role == "CHIEF") {
            logger.error(
                "You cannot leave an organisation while you are chief! Assign a new chief or delete the organisation from the organisation's profile"
            );
            return;
        }

        MeService.leaveOrganisation(membership.organisation_id).then(function (
            result
        ) {
            var index = ctrl.memberships.indexOf(membership);
            ctrl.memberships.splice(index, 1);
            AuthService.refreshCurrentUserMemberships();
        });
    };

    ctrl.convertProjectLocations = function (project) {
        if (project.locations.length) {
            return project.locations
                .map(function (location) {
                    return location.city;
                })
                .join(", ");
        } else {
            return "";
        }
    };
    ctrl.onFileSelect = function (files) {
        var file = files[0];
        if (file) {
            var query = {
                id: ctrl.currentUser._id,
                type: file.type,
                name: file.name,
            };
            UploadPictureService.signUrl(query).then(function (data) {
                UploadPictureService.upload(data, file).then(function () {
                    ctrl.currentUser.picture_url = data.getUrl;
                    ctrl.editForm.user.picture_url = data.getUrl;
                });
            });
        } else return;
    };
    ctrl.uploadResume = function (files) {
        var file = files[0];
        if (file) {
            var query = {
                id: ctrl.currentUser._id,
                type: file.type,
                name: file.name,
            };
            UploadPictureService.signUrl(query).then(function (data) {
                UploadPictureService.upload(data, file)
                    .then(function () {
                        ctrl.currentUser.resumeUrl = data.getUrl;
                    })
                    .then(() => {
                        UserService.update(ctrl.currentUser._id, {
                            resumeUrl: ctrl.currentUser.resumeUrl,
                        }).then(() => {});
                    });
            });
        } else return;
    };
    ctrl.showError = function (file) {
        toastr.error(
            $translate.instant("ONBOARDING.STEP1.RESUME_ERROR"),
            "Error"
        );
        ctrl.InvalidFile = false;
    };

    ctrl.removeResume = function () {
        UploadPictureService.removeResume().then(() => {
            ctrl.currentUser.resumeUrl = null;
            UserService.update(ctrl.editForm.user._id, {
                resumeUrl: ctrl.currentUser.resumeUrl,
            }).then(() => {});
        });
    };
}
