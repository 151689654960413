import angular from "angular";
import template from "./create-project-form.html";
import "./create-wizard/add-roles.html";
import "./create-wizard/add-status.html";
import "./create-wizard/add-talents.html";
import "./create-wizard/personality.html";
import "./create-wizard/project-location.html";
import "./create-wizard/project-timing.html";
import "./create-wizard/project-summary.html";
import "./create-wizard/role-summary.html";
import "./create-wizard/talents-review.html";

angular.module("app.project").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.create-project",
            config: {
                url: "/project/:organisationId/create",
                template: template,
                controller: "CreateProjectFormController",
                controllerAs: "main",
                title: "Create new project",
                params: {
                    project: undefined,
                },
                resolve: {
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    project: [
                        "$stateParams",
                        function ($stateParams) {
                            return $stateParams.project;
                        },
                    ],
                    projectRoles: [
                        function () {
                            return [];
                        },
                    ],
                },
            },
        },
    ];
}
