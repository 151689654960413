import angular from 'angular';

angular
    .module('app.project')
    .controller('TemplatesDirectoryController', TemplatesDirectoryController);

TemplatesDirectoryController.$inject = ['$stateParams', '$q', 'logger', 'currentUser', 'organisationId', 'ProjectService', '$state'];

/* @ngInject */
function TemplatesDirectoryController($stateParams, $q, logger, currentUser, organisationId, ProjectService, $state) {
    const ctrl = this;

    ctrl.currentUser = currentUser;
    ctrl.templates = [];
    ctrl.organisationId = organisationId;
    ctrl.availableTemplateTags = ['Start-up', 'Planning', 'Management',
        'Juridisch', 'Financieel', 'Verkoop', 'Marketing', 'Product', 'Team'];
    ctrl.filterTags = [];

    if ($stateParams.type === 'jobs') {
        ProjectService.getTemplates('JOB').then((templates) => ctrl.templates = templates);
    } else if ($stateParams.type === 'projects') {
        ProjectService.getTemplates('PROJECT').then((templates) => ctrl.templates = templates);
    } else {
        ProjectService.getTemplates().then((templates) => ctrl.templates = templates);
    }

    ctrl.filterProject = function() {
        ProjectService.getTemplates($stateParams.type, ctrl.filterTags).then((templates) => ctrl.templates = templates);
    };

    ctrl.copyToOrg = function(templateId) {
        ProjectService.duplicateFromTemplate(ctrl.organisationId, templateId).then((result) => {
            $state.go('app.edit-project', { organisationId: ctrl.organisationId, projectId: result._id });
        });
    }
}
