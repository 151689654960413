import angular from "angular";
import template from "./public-profile.html";

angular.module("app.profile.public-profile").run(appRun);

appRun.$inject = ["routerHelper"];

/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.public-profile",
            config: {
                url: "/user/:userId",
                template: template,
                controller: "PublicProfileController",
                controllerAs: "profile",
                title: "Public profile",
                resolve: {
                    currentUser: [
                        "UserService",
                        function (UserService) {
                            return UserService.getMe();
                        },
                    ],
                    user: [
                        "$stateParams",
                        "UserService",
                        function ($stateParams, UserService) {
                            return UserService.getOne($stateParams.userId);
                        },
                    ],
                    memberships: [
                        "$stateParams",
                        "UserService",
                        function ($stateParams, UserService) {
                            return UserService.getOrganisationMemberships(
                                $stateParams.userId,
                                { includes: ["organisation"] }
                            );
                        },
                    ],
                    talents: [
                        "$stateParams",
                        "UserTalentService",
                        "_",
                        function ($stateParams, UserTalentService, _) {
                            return UserTalentService.getAll(
                                $stateParams.userId
                            ).then(function (talents) {
                                return _.reduce(
                                    talents,
                                    function (result, item) {
                                        result[item._id] = item;
                                        return result;
                                    },
                                    {}
                                );
                            });
                        },
                    ],
                },
            },
        },
    ];
}
