(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('DashboardService', DashboardService);

    DashboardService.$inject = ['$http', 'ENV_CONFIG'];

        /* @ngInject */
        function DashboardService($http, ENV_CONFIG) {
            var userPath = 'users';
            var subPathOrganisations = 'organisations';
            var service = {
                getProjectStatistics: getProjectStatistics,
                getUsersWithRecentStatusChanges: getUsersWithRecentStatusChanges,
                latestProjectsChanged: latestProjectsChanged
            };

            return service;

            /**
             * Generic success function
             *
             * @param response
             * @returns {*}
             */
            function success(response) {
                return response.data;
            }

            /**
             * Generic fail function
             *
             * @param error
             * @returns {Array|*}
             */
            function fail(error) {
                return $q.reject(error.data);
            }

            /**
             * GET Project Statistics
             *
             * @param organisationId
             * @returns {*}
             */
            function getProjectStatistics(organisationId) {
                return $http.get(ENV_CONFIG.baseAPI + subPathOrganisations + '/' + organisationId + '/projects/count')
                    .then(success)
                    .catch(fail);
            }

            /**
             * Get Users With Recent Status Changes
             * @param organisationId
             * @returns {*}
             */
            function getUsersWithRecentStatusChanges(organisationId) {
                return $http.get(ENV_CONFIG.baseAPI + userPath + '/latest?organisationId=' + organisationId)
                    .then(success)
                    .catch(fail);
            }

            /**
             * GET Latest Projects Changed
             *
             * @param organisationId
             * @returns {*}
             */
             function latestProjectsChanged(organisationId) {
                return $http.get(ENV_CONFIG.baseAPI + subPathOrganisations + '/' + organisationId + '/projects/latest')
                    .then(success)
                    .catch(fail);
            }
        }
    })();
