import './organisation.module';
import './organisation.route';
import './create-organisation-form.controller';
import './shared/organisation-edit-details.html';
import './shared/organisation-view-details.html';
import './shared/organisation-essential-edit.html';
import './shared/organisation-details-edit.html';
import './shared/additional-details-popower.html';
import './shared/search-users-additional-details-info.html';
import './shared/organisation-remove-dialog.html';
import './edit-membership-privileges/edit-membership-privileges-form.controller';
import './edit-membership-privileges/edit-membership-privileges-form.html';
import './manage-organisation/find-members/find-members.controller';
export default 'app.organisation';
