(function () {
    'use strict';

    angular
        .module('app.talent.talent-pool')
        .controller('EditTalentFormController', EditTalentFormController);

    EditTalentFormController.$inject = ['$state', 'talent', 'TalentMasterService'];

    /* @ngInject */
    function EditTalentFormController($state, talent, TalentMasterService) {
        var vm = this;

        vm.talent = talent;

        //"source": "esco",
        //    "external_id": "21520",
        //    "bg": "Глас зад кадър във филмова продукция",
        //    "cs": "Hlas filmového vypravěče",
        //    "da": "Filmstemme",
        //    "de": "Film (Stimmenrolle)",
        //    "et": "Filmihääl",
        //    "el": "Φωνή παρουσιαστή",
        //    "en": "Film voice role",
        //    "es": "Voz en películas (doblajes)",
        //    "fr": "Rôle de doublure vocale pour le cinéma",
        //    "is": "Kvikmyndarödd",
        //    "it": "Voce di commento per film",
        //    "lv": "Filmas dublēšana",
        //    "lt": "Filmų įgarsinimas",
        //    "hr": "Filmska glasovna uloga",
        //    "hu": "Szinkronhang",
        //    "mt": "Vuċi ta' kelliem f'film",
        //    "nl": "Film (inspreken)",
        //    "pl": "Rola głosowa w filmie",
        //    "pt": "Voz de filme (actuação)",
        //    "ro": "Voce în filme",
        //    "sk": "Hlasová úloha vo filme",
        //    "sl": "Glas za sinhroniziranje filmov",
        //    "fi": "Elokuvan äänirooli",
        //    "sv": "Filmröst"

        vm.fields = [
            {
                key: 'source',
                type: 'input',
                templateOptions: {
                    label: 'Source',
                    placeholder: 'Source'
                }
            },
            {
                key: 'published',
                type: 'checkbox',
                templateOptions: {
                    label: 'Published',
                    placeholder: 'Published'
                }
            },
            {
                key: 'is_reviewed',
                type: 'checkbox',
                templateOptions: {
                    label: 'Is Reviewed',
                    placeholder: 'Is Reviewed'
                }
            },
            {
                key: 'external_id',
                type: 'input',
                templateOptions: {
                    label: 'External ID',
                    placeholder: 'External ID'
                }
            },
            {
                key: 'labels',
                type: 'multiCheckbox',
                templateOptions: {
                    label: 'Labels',
                    options: [{value: 'LANGUAGE', title: 'Language'}, {value: 'PERSONALITY', title: 'Personality'}, {value: 'SOFTWARE', title: 'Software'}],
                    valueProp: 'value',
                    labelProp: 'title'
                }
            },
            {
                key: 'en',
                type: 'input',
                templateOptions: {
                    label: 'EN',
                    placeholder: 'EN'
                }
            },
            {
                key: 'nl',
                type: 'input',
                templateOptions: {
                    label: 'NL',
                    placeholder: 'NL'
                }
            },
            {
                key: 'bg',
                type: 'input',
                templateOptions: {
                    label: 'BG',
                    placeholder: 'BG'
                }
            },
            {
                key: 'cs',
                type: 'input',
                templateOptions: {
                    label: 'CS',
                    placeholder: 'CS'
                }
            },
            {
                key: 'da',
                type: 'input',
                templateOptions: {
                    label: 'DA',
                    placeholder: 'DA'
                }
            },
            {
                key: 'de',
                type: 'input',
                templateOptions: {
                    label: 'DE',
                    placeholder: 'DE'
                }
            },
            {
                key: 'et',
                type: 'input',
                templateOptions: {
                    label: 'ET',
                    placeholder: 'ET'
                }
            },
            {
                key: 'el',
                type: 'input',
                templateOptions: {
                    label: 'EL',
                    placeholder: 'EL'
                }
            },
            {
                key: 'es',
                type: 'input',
                templateOptions: {
                    label: 'ES',
                    placeholder: 'ES'
                }
            },
            {
                key: 'fr',
                type: 'input',
                templateOptions: {
                    label: 'FR',
                    placeholder: 'FR'
                }
            },
            {
                key: 'is',
                type: 'input',
                templateOptions: {
                    label: 'IS',
                    placeholder: 'IS'
                }
            },
            {
                key: 'it',
                type: 'input',
                templateOptions: {
                    label: 'IT',
                    placeholder: 'IT'
                }
            },
            {
                key: 'lv',
                type: 'input',
                templateOptions: {
                    label: 'LV',
                    placeholder: 'LV'
                }
            },
            {
                key: 'lt',
                type: 'input',
                templateOptions: {
                    label: 'LT',
                    placeholder: 'LT'
                }
            },
            {
                key: 'hr',
                type: 'input',
                templateOptions: {
                    label: 'HR',
                    placeholder: 'HR'
                }
            },
            {
                key: 'hu',
                type: 'input',
                templateOptions: {
                    label: 'HU',
                    placeholder: 'HU'
                }
            },
            {
                key: 'mt',
                type: 'input',
                templateOptions: {
                    label: 'MT',
                    placeholder: 'MT'
                }
            },
            {
                key: 'pl',
                type: 'input',
                templateOptions: {
                    label: 'PL',
                    placeholder: 'PL'
                }
            },
            {
                key: 'pt',
                type: 'input',
                templateOptions: {
                    label: 'PT',
                    placeholder: 'PT'
                }
            },
            {
                key: 'ro',
                type: 'input',
                templateOptions: {
                    label: 'RO',
                    placeholder: 'RO'
                }
            },
            {
                key: 'sk',
                type: 'input',
                templateOptions: {
                    label: 'SK',
                    placeholder: 'SK'
                }
            },
            {
                key: 'sl',
                type: 'input',
                templateOptions: {
                    label: 'SL',
                    placeholder: 'SL'
                }
            },
            {
                key: 'fi',
                type: 'input',
                templateOptions: {
                    label: 'FI',
                    placeholder: 'FI'
                }
            },
            {
                key: 'sv',
                type: 'input',
                templateOptions: {
                    label: 'SV',
                    placeholder: 'SV'
                }
            }
        ];

        vm.onSubmit = function() {
            TalentMasterService.update(vm.talent._id, vm.talent).then(function(result) {
                $state.go('app.talent-pool');

                vm.talent = result;
            })
        }
    }
})();
