(function () {
    "use strict";

    angular
        .module("app.talent.talent-pool")
        .controller("TalentPoolController", TalentPoolController);

    TalentPoolController.$inject = [
        "$state",
        "l10n",
        "$stateParams",
        "$scope",
        "TalentMasterService",
        "NgTableParams",
        "toastr",
        "$uibModal",
    ];

    /* @ngInject */
    function TalentPoolController(
        $state,
        l10n,
        $stateParams,
        $scope,
        TalentMasterService,
        NgTableParams,
        toastr,
        $uibModal
    ) {
        var vm = this;

        vm.filters = {
            source: null,
            missing: null,
        };

        var stateSize = parseInt($stateParams.size, 10) || 1;
        var statePage = parseInt($stateParams.page, 10) || 1;
        var stateSort = $stateParams.sort
            ? JSON.parse($stateParams.sort)
            : { created_at: "desc" };
        var stateFilter = $stateParams.filter
            ? JSON.parse($stateParams.filter)
            : {};
        var stateOffset = (statePage - 1) * stateSize;
        vm.labelOpts = [
            { value: "LANGUAGE", title: "Language" },
            { value: "PERSONALITY", title: "Personality" },
            { value: "SOFTWARE", title: "Software" },
        ];

        vm.table = new NgTableParams(
            {
                page: statePage,
                count: stateSize,
                sorting: stateSort,
                filter: stateFilter,
            },
            {
                counts: [10, 20, 50, 100],
                getData: function (params) {
                    var page = params.page();
                    var size = params.count();
                    var offset = (page - 1) * size;
                    var sort = params.sorting();
                    var filter = angular.copy(params.filter());

                    for (var key in filter) {
                        if (filter.hasOwnProperty(key)) {
                            if (filter[key] == "") {
                                delete filter[key];
                            }
                        }
                    }

                    return TalentMasterService.list(
                        offset,
                        size,
                        filter,
                        sort
                    ).then(function (result) {
                        params.total(result.total); // recal. page nav controls
                        var newState = {};
                        newState.page = page;
                        newState.size = size;
                        if (sort) {
                            newState.sort = JSON.stringify(sort);
                        }
                        if (filter) {
                            newState.filter = JSON.stringify(filter);
                        }

                        $state.go(".", newState, { notify: false });
                        return result.docs;
                    });
                },
            }
        );

        vm.pager = {
            totalItems: 0,
            currentPage: statePage,
            itemsPerPage: 25,
            maxSize: 100,
            pageChanged: function () {
                $state.go(
                    ".",
                    { page: vm.pager.currentPage },
                    { notify: false }
                );
                vm.refreshData();
            },
        };

        vm.callServer = function callServer(tableState) {
            vm.isLoading = true;

            var pagination = tableState.pagination;

            var start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
            var number = pagination.number || 10; // Number of entries showed per page.
            var sort = null;

            if (tableState.sort.predicate) {
                sort = tableState.sort.predicate;
                if (tableState.sort.reverse) {
                    sort = "-" + sort;
                }
            }

            TalentMasterService.list(start, number, vm.filters, sort).then(
                function (result) {
                    vm.talents = result.docs;
                    tableState.pagination.numberOfPages = result.total; //set the number of pages so the pagination can update
                    $state.go(
                        ".",
                        { offset: start, limit: number, sort: sort },
                        { notify: false }
                    );
                    vm.isLoading = false;
                }
            );
        };

        vm.setMissingFilter = function (key) {
            var filter = {};
            filter[key] = { $in: ["", null] };
            var newFilter = angular.extend(vm.table.filter(), filter);
        };

        vm.clearMissingFilter = function (key) {
            var filter = {};
            filter[key] = "";
            angular.extend(vm.table.filter(), filter);
        };

        vm.getFilterType = function (property) {
            var currentFilter = vm.table.filter();
            var filter = {};
            if (typeof currentFilter[property] === "object") {
                filter[property] = "filters/" + property + "-missing.html";
                return filter;
            } else {
                filter[property] = "text";
                return filter;
            }
        };

        // Toggle selection for a given fruit by name
        vm.toggleLabel = function toggleLabel(talent, label) {
            var idx = talent.labels.indexOf(label);

            // Is currently selected
            if (idx > -1) {
                talent.labels.splice(idx, 1);
            } else {
                // Is newly selected
                talent.labels.push(label);
            }

            TalentMasterService.update(talent._id, talent)
                .then(function (result) {
                    toastr.success("Talent was updated.");
                })
                .catch(function (err) {
                    toastr.error(err, "Error");
                });
        };

        vm.switchSource = function (talent) {
            talent.source = "Tribeforce";
            talent.published = true;

            TalentMasterService.update(talent._id, talent)
                .then(function (result) {
                    toastr.success("Talent was published to Tribeforce list.");
                })
                .catch(function (err) {
                    toastr.error(err, "Error");
                });
        };

        vm.removeTalent = function (talent) {
            TalentMasterService.remove(talent._id).then(function (result) {
                vm.table.data.splice(vm.table.data.indexOf(talent), 1);
            });
        };

        vm.replaceTalent = function (talent) {
            let modalInstance = $uibModal.open({
                animation: true,
                size: "lg",
                template: require("~/app/talent-pool/talent-pool-replace.html"),
                controller: function ($uibModalInstance, talent, data) {
                    const ctrl = this;
                    ctrl.data = data;
                    ctrl.talent = talent;
                    let currentLanguage = l10n.getLanguage();
                    ctrl.oldTalent = ctrl.talent[currentLanguage];
                    ctrl.selectNewTalent = function () {
                        if (
                            typeof ctrl.newTalent === "string" ||
                            ctrl.newTalent instanceof String
                        ) {
                            let newTalent = {};
                            newTalent["en"] = ctrl.newTalent;
                            newTalent["nl"] = ctrl.newTalent;
                            ctrl.selectedTalent = newTalent[currentLanguage];
                            newTalent.labels = [talent.labels[0]];
                            TalentMasterService.save(newTalent)
                                .then(function (newTalent) {
                                    ctrl.newTalent = newTalent;
                                    ctrl.data.push(ctrl.newTalent);
                                    ctrl.showTalent = !ctrl.showTalent;
                                })
                                .catch(function (err) {
                                    toastr.error(err, "Warning");
                                });
                        } else {
                            ctrl.selectedTalent =
                                ctrl.newTalent[currentLanguage];
                            ctrl.showTalent = !ctrl.showTalent;
                        }
                    };

                    ctrl.replaceTalent = function () {
                        let oldTalent = ctrl.talent;
                        let newTalent = ctrl.newTalent;
                        TalentMasterService.replace(
                            oldTalent._id,
                            newTalent
                        ).then(function (result) {
                            ctrl.data.splice(vm.table.data.indexOf(talent), 1);
                            $uibModalInstance.close();
                        });
                    };

                    ctrl.close = function () {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: "vm",
                resolve: {
                    talent: function () {
                        return talent;
                    },
                    data: function () {
                        return vm.table.data;
                    },
                },
            });
        };
    }
})();
