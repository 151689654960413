import angular from 'angular';
import appCore from '../core';
import appWidgets from '../widgets';
import searchUser from './user';
import searchOrganisation from './organisation';
import searchGlobal from './global';

angular.module('app.search', [
    appCore,
    appWidgets,
    'app.search.user',
    'app.search.organisation',
    'app.search.global'
]);
