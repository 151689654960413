(function () {
    'use strict';

    angular
        .module('blocks.auth')
        .factory('authInterceptor', AuthInterceptor);

    AuthInterceptor.$inject = ['$injector', '$rootScope', '$q', '$cookieStore', '$localStorage', 'logger'];

    /* @ngInject */
    function AuthInterceptor($injector, $rootScope, $q, $cookieStore, $localStorage, logger) {
        return {
            // Add authorization token to headers
            request: function (config) {
                config.headers = config.headers || {};
                if ($cookieStore.get('token')) {
                    config.headers.Authorization = 'Bearer ' + $cookieStore.get('token');
                }
                var foreignUrl = config.url.indexOf('amazonaws') > -1;
                if(foreignUrl) {
                    delete config.headers['__setXHR_'];
                    config.headers['Authorization'] = undefined;
                }
                return config;
            },

            // Intercept Errors and perform the correct action
            responseError: function(response) {
                if(response.status === 401) {
                    // remove any stale tokens
                    $cookieStore.remove('token');
                    delete $localStorage.currentUser;
                    $injector.get('$state').go('guest.login', {message: 'AUTH.SESSION_EXPIRED'});
                } else if(response.status === 500) {
                    var msg = '[ERROR] ' + response.data.message;
                    logger.error(msg, response.data, response.data.name);
                }

                return $q.reject(response);
            }
        };
    }
})();
