import angular from 'angular';

angular
    .module('app.organisation.manage-organisation')
    .controller('InviteTribeforceUsersController', InviteTribeforceUsersController);

InviteTribeforceUsersController.$inject = ['$scope', '$q', 'logger', '$translate', 'OrganisationService', 'OrganisationInvitationService'];

/* @ngInject */
function InviteTribeforceUsersController($scope, $q, logger, $translate, OrganisationService, OrganisationInvitationService) {
    var ctrl = this;
    var offset = 0;
    var fullName;
    ctrl.connectionType = "ALL";
    ctrl.result = {
        hasSearched: false,
        total: 0,
        data: []
    };

    ctrl.search = function() {
        var type = "ALL";
        fullName = ctrl.qry.fullName;
        OrganisationService.searchMembers(ctrl.organisationId, ctrl.qry, type).then(function(result){
            ctrl.result.data = result.members;
            ctrl.result.hasSearched = true;
            ctrl.result.hasSearchedByType = false;
            ctrl.connectionType = "ALL";
            ctrl.result.total = result.total;
        });
    };

    ctrl.shouldShowProperty = function(user, propertyName) {
        var scopes = [];
        for(var i=0; i < user.scopes.length; i++) {
            scopes[user.scopes[i].property] = user.scopes[i].scope;
        }
        return scopes.hasOwnProperty(propertyName) && scopes[propertyName] != 'private' && user.hasOwnProperty(propertyName) && typeof user[propertyName] !== 'undefined';
    };

    ctrl.inviteUser = function(user) {
        if(!user) {
            logger.warning('No user selected to invite! Select a user before sending an invitation.');
            return;
        }

        OrganisationInvitationService.invite(ctrl.organisationId, user._id, {type: ctrl.connectionType}).then(function(invitation) {
            logger.success($translate.instant('INVITE-USERS.SUCCESS-MESSAGE'));
        });
    };

    ctrl.getMatchingFor = function(type){
        var query = {
            fullName: fullName
        }
        OrganisationService.searchMembers(ctrl.organisationId, query, type).then(function(result){
            ctrl.result.data = result.members;
            ctrl.result.hasSearchedByType = true;
            ctrl.result.total = result.total;
        });
    }

    //if ($routeParams.query) {
    //    ctrl.qry = $routeParams.query;
    //
    //    // Reset search
    //    offset = 0;
    //    ctrl.result.hasSearched = true;
    //    ctrl.result.total = 0;
    //    ctrl.result.data = [];
    //
    //    getResults();
    //}
}
