import angular from "angular";
import * as template from "./reset-password-request.html";

let ResetPasswordRequest = {
    template: template,
    controller: "ResetPasswordRequestController",
};

angular
    .module("app.account")
    .component("resetPasswordRequest", ResetPasswordRequest);
