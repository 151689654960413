import angular from "angular";
import * as template from "./work.html";

angular.module("app.profile.work").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.work",
            config: {
                url: "/work",
                template: template,
                controller: "workController",
                controllerAs: "vm",
                title: "My work",
                resolve: {
                    memberships: [
                        "currentUser",
                        "UserService",
                        function (currentUser, UserService) {
                            return UserService.getOrganisationMemberships(
                                currentUser._id,
                                { includes: ["organisation"] }
                            );
                        },
                    ],
                    myConnections: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getMyAssignes();
                        },
                    ],
                },
            },
        },
    ];
}
