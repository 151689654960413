import angular from 'angular';

angular
    .module('blocks.filter')
    .filter('formatDate', FormatDate);

FormatDate.$inject = ['moment'];

/* @ngInject */
function FormatDate(moment) {
    return function(input){
        return moment(input).format('D/M/Y');
    };

}
