import angular from 'angular';

angular
    .module('app.search.organisation')
    .controller('OrganisationSearchController', OrganisationSearchController);

OrganisationSearchController.$inject = ['$scope', '$q', 'logger', 'OrganisationService'];

/* @ngInject */
function OrganisationSearchController($scope, $q, logger, OrganisationService) {
    var ctrl = this;
    var offset = 0;

    ctrl.qry = "";
    ctrl.result = {
        hasSearched: false,
        total: 0,
        data: []
    };

    ctrl.search = function() {
        OrganisationService.search(ctrl.qry).then(function(result) {
            ctrl.result.total = result.total;
            ctrl.result.data = result.data;
            ctrl.result.hasSearched = true;
        });
    };

    ctrl.shouldShowProperty = function(user, propertyName) {
        var scopes = [];
        for(var i=0; i < user.scopes.length; i++) {
            scopes[user.scopes[i].property] = user.scopes[i].scope;
        }
        return scopes.hasOwnProperty(propertyName) && scopes[propertyName] != 'private' && user.hasOwnProperty(propertyName) && typeof user[propertyName] !== 'undefined';
    };

    //if ($routeParams.query) {
    //    ctrl.qry = $routeParams.query;
    //
    //    // Reset search
    //    offset = 0;
    //    ctrl.result.hasSearched = true;
    //    ctrl.result.total = 0;
    //    ctrl.result.data = [];
    //
    //    getResults();
    //}
}
