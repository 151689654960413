import angular from 'angular';

angular
    .module('app.login')
    .controller('LoginController', LoginController);

LoginController.$inject = ['$rootScope','$stateParams', '$state', '$window', 'ENV_CONFIG', 'AuthService'];

/* @ngInject */
function LoginController($rootScope, $stateParams, $state, $window, ENV_CONFIG, AuthService) {
    /* jshint validthis: true */
    var vm = this;
    vm.message = $stateParams.message;
    vm.user = {};
    $rootScope.login = function(form) {
        vm.submitted = true;
        vm.error = null;
        if(form.$valid) {
            AuthService.login(vm.user.email, vm.user.password)
                .then(function(result) {
                    if(!result.onboarding_completed) {
                        $state.go('app-nomenu.onboarding');
                    } else {
                        AuthService.isChief().then(function(res) {
                            if(res.isChief) {
                                $state.go('app.dashboard', {organisationId: res.organisationId});
                            }
                            else {
                                $state.go('app.my-profile', {action:$stateParams.action, invitationId: $stateParams.invitationId});
                            }
                        });
                    }                    
                })
                .catch(function(err) {
                    vm.error = err;
                });
        }
    };

    vm.loginOauth = function(provider) {
        $window.location.href = ENV_CONFIG.baseURI + '/auth/' + provider;
    };
}
