import angular from "angular";
import template from "./recent-actions.html";

angular.module("app.dashboard.recent-actions").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.dashboard.recent-actions",
            config: {
                url: "/dashboard/:organisationId/recent-actions",
                template: template,
                controller: "RecentActionsController",
                controllerAs: "$ctrl",
                title: 'RecentActions',
            },
        },
    ];
}
