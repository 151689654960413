import angular from 'angular';
import toastr from 'toastr';
import moment from 'moment';
import _ from 'lodash';

angular
    .module('app.core')
    .constant('toastr', toastr)
    .constant('moment', moment)
    .constant('_', _);
