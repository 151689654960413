import angular from "angular";
import template from "./talent-state-toggle.html";

angular.module("app.widgets").directive("talentStateToggle", TalentStateToggle);

TalentStateToggle.$inject = [];

/* @ngInject */
function TalentStateToggle() {
    var directive = {
        scope: {
            model: "=",
            toggle: "&onToggle",
        },
        template: template,
        controller: TalentStateToggleController,
        controllerAs: "vm",
        bindToController: true,
        restrict: "E",
    };

    return directive;
}

TalentStateToggleController.$inject = [];

/* @ngInject */
function TalentStateToggleController() {
    var ctrl = this;
}
