import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import 'angucomplete-alt';
import 'angular-smart-table';
import angularMoment from 'angular-moment';
import 'ng-table';
import textAngular from 'textangular';
import validationMatch from 'angular-validation-match';
import 'angular-wizard';
import monospacedElastic from 'angular-elastic';
import ngFileUpload from 'ng-file-upload';
import 'ng-drift';
import 'intro.js';
// Should be fixed in version 4.0.0
import ngIntro from 'angular-intro.js/src/[deprectable]angular-intro';
import 'bootstrap-switch';
import 'angular-bootstrap-switch';

angular.module('app.widgets', [
    uiBootstrap,
    uiSelect,
    'angucomplete-alt',
    'smart-table',
    angularMoment,
    'ngTable',
    textAngular,
    validationMatch,
    'mgo-angular-wizard',
    monospacedElastic,
    ngFileUpload,
    'frapontillo.bootstrap-switch',
    'ng-drift',
    ngIntro.name
]).config(($provide) => {
    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function(taRegisterTool, taOptions) {
        taOptions.forceTextAngularSanitize = false;
        return taOptions;
    }]);
    }).config((ngDriftProvider) => {
        ngDriftProvider.setKey('bf3fb9in543u');
        ngDriftProvider.setSnippetVersion('0.3.1');
    });
