import angular from 'angular';

angular
    .module('app.profile.availability')
    .controller('availabilityController', availabilityController);

    availabilityController.$inject = ['$timeout','$stateParams','ngIntroService','$translate','$scope', '$q', 'logger', 'UserService', 'currentUser', 'OrganisationService', 'Util',
        'OrganisationInvitationService', 'ProjectService', 'AuthService', 'MeService','UploadPictureService','$state','memberships'];

    /* @ngInject */
    function availabilityController($timeout, $stateParams, ngIntroService, $translate, $scope, $q, logger, UserService, currentUser, OrganisationService, Util,
                                 OrganisationInvitationService, ProjectService, AuthService, MeService, UploadPictureService ,$state ,memberships) {
        var ctrl = this;
        ctrl.currentUser = currentUser;
        ctrl.isEditing = false;
        ctrl.memberships = memberships;
        ctrl.isEdit = [];
        // UserService.getOrganisationMemberships(currentUser._id, {includes: ['organisation']}).then(function(memberships) {
        //     console.log(memberships);
        //     ctrl.memberships = memberships;
        // });
        if ($stateParams.tour) {
            ctrl.tour = true;
            ctrl.IntroOptions = {
                steps: [
                    {
                        element: "#step6",
                        intro: '<p class="blue-grey f-s-20">' + $translate.instant('PRODUCT_TOUR.STEP_6_TITLE') + '</p>' + $translate.instant('PRODUCT_TOUR.STEP_6'),
                        position: "auto"
                    },
                    {
                        element: "#step6",
                        intro: '<p class="blue-grey f-s-20">' + $translate.instant('PRODUCT_TOUR.STEP_7_TITLE') + '</p>' + $translate.instant('PRODUCT_TOUR.STEP_7'),
                        position: "auto"
                    },
                    {
                        element: "#step6",
                        intro: '<p class="blue-grey f-s-20">' + $translate.instant('PRODUCT_TOUR.STEP_7_TITLE') + '</p>' + $translate.instant('PRODUCT_TOUR.STEP_7'),
                        position: "auto"
                    }
                ],
                showStepNumbers: false,
                showBullets: false,
                exitOnOverlayClick: true,
                exitOnEsc: true,
                hidePrev: true,
                hideNext: true,
                skipLabel: $translate.instant('PRODUCT_TOUR.SKIP'),
                nextLabel: $translate.instant('PRODUCT_TOUR.BUTTON_1'),
                doneLabel: $translate.instant('PRODUCT_TOUR.FINISH')
            };
                ngIntroService.setOptions(ctrl.IntroOptions);
                $timeout(ngIntroService.start.bind(ngIntroService));



        };
        ngIntroService.onAfterChange(function () {
            $('.introjs-nextbutton ').css("color", "white").css("background", "#3399ff").css("text-shadow", "none");
            if (ngIntroService.intro._currentStep == 1) {
                $('.introjs-prevbutton').css('display', 'none');
                $('.introjs-nextbutton ').html($translate.instant('PRODUCT_TOUR.BUTTON_7'));

            }
            if(ngIntroService.intro._currentStep == 2){
                ngIntroService.clear();
                $state.go('app.my-profile');
            }
        })
        ctrl.removeMembership = function(membership) {
            if(membership.role == 'CHIEF') {
                logger.error('You cannot leave an organisation while you are chief! Assign a new chief or delete the organisation from the organisation\'s profile');
                return;
            }

        MeService.leaveOrganisation(membership.organisation_id).then(function(result) {
            var index = ctrl.memberships.indexOf(membership);
            ctrl.memberships.splice(index, 1);
            AuthService.refreshCurrentUserMemberships();
        });
    };
    ctrl.onChangeAvailable = function(membership){
        if(!membership.availability){
            membership.hours = null;
            membership.frequency = 'PER_DAY';
        }
    };

        ctrl.editForm = function(index) {
            $('.introjs-tooltip').css('top', 275);
            ctrl.isEdit[index] = !ctrl.isEdit[index];
        };

        ctrl.saveForm = function(membership, index) {
                if(membership.rate >= 0) {
                    OrganisationService.updateMemberRates(membership.organisation_id, membership._id, membership)
                    .then(function(){
                        OrganisationService.updateMemberAvailability(membership.organisation_id, membership._id, membership)
                        .then(function(){
                            ctrl.editForm(index);
                            $('.introjs-tooltip').css('top', 200);
                        });
                    });
                } else {
                    OrganisationService.updateMemberAvailability(membership.organisation_id, membership._id, membership)
                    .then(function(){
                        ctrl.editForm(index);
                        $('.introjs-tooltip').css('top', 200);
                    });
                }
        };
    }
