(function () {
    "use strict";

    angular.module("app.search.organisation").run(appRun);

    appRun.$inject = ["routerHelper"];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: "app.search-organisation",
                config: {
                    url: "/search/organisation",
                    template: require("~/app/search/organisation/organisation-search.html"),
                    controller: "OrganisationSearchController",
                    controllerAs: "searchCtrl",
                    title: "search organisations",
                },
            },
        ];
    }
})();
