import angular from 'angular';

angular
    .module('app.organisation.invite-users')
    .controller('InviteUsersController', InviteUsersController);


InviteUsersController.$inject = ['$scope', '$q', 'logger', '$translate', 'organisation', 'UserService', 'OrganisationInvitationService', 'OrganisationService', 'UploadPictureService', '$state','$window'];

/* @ngInject */
function InviteUsersController($scope, $q, logger, $translate, organisation, UserService, OrganisationInvitationService, OrganisationService, UploadPictureService, $state, $window) {
    var ctrl = this;
    var offset = 0;

    ctrl.organisation = organisation;
    ctrl.user = {};
    ctrl.user.connectiontype = "EMPLOYEE";
    ctrl.user.language = "en";
    ctrl.qry = "";
    ctrl.result = {
        newEmail: true,
        hasSearched: false,
        total: 0,
        data: []
    };
    ctrl.data = {
        knowledge: [],
        general: [],
        languages_mother_tongue: [],
        languages_writing: [],
        languages_understanding: [],
        languages_speaking: [],
        tools: [],
        personality: []
    };
    ctrl.search = function(form) {
        ctrl.submitted = true;
        if(form.$valid){
            var qry = {
                fullName: ctrl.user.firstname + ' ' + ctrl.user.lastname,
                email: ctrl.user.email
            };
            UserService.search(qry)
                .then(searchResultHandler)
                .catch(function (err) {
                    err = err.data;
                    ctrl.errors = {};

                    // Update validity of form fields that match the mongoose errors
                    angular.forEach(err.errors, function(error, field) {
                        form[field].$setValidity('mongoose', false);
                        ctrl.errors[field] = error.message;
                    });
                });
        }
    };

    ctrl.backStep = function() {
        ctrl.result.hasSearched = !ctrl.result.hasSearched;
        ctrl.showForm = false;
        ctrl.result.newEmail = true;
    };

    ctrl.cancel = function() {
        $window.history.back();
    };

    ctrl.save = function(form) {
        ctrl.submitted = true;
        if(form.$valid) {
            for (var key in ctrl.data) {
                ctrl.user[key] = ctrl.data[key];
            };
            OrganisationService.saveMember(ctrl.organisation._id, ctrl.user)
                .then(function(result) {
                    logger.success($translate.instant('PASSIVE-PROFILE.CREATE.SUCCESS_MESSAGE'));
                    ctrl.user = {};
                    ctrl.data = {
                        knowledge: [],
                        general: [],
                        languages_mother_tongue: [],
                        languages_writing: [],
                        languages_understanding: [],
                        languages_speaking: [],
                        tools: [],
                        personality: []
                    };
                    ctrl.submitted = false;
                    ctrl.showForm = false,
                        $state.go('app.organisation-invite-users', {organisationId: organisation._id});
                })
                .catch(function(err) {
                    err = err.data;
                    ctrl.errors = {};

                    // Update validity of form fields that match the mongoose errors
                    angular.forEach(err.errors, function(error, field) {
                        form[field].$setValidity('mongoose', false);
                        ctrl.errors[field] = error.message;
                    });
                });
        }
    };

    ctrl.inviteUser = function(user) {
        if(!user) {
            logger.warning('No user selected to invite! Select a user before sending an invitation.');
            return;
        }

        OrganisationInvitationService.invite(ctrl.organisation._id, user._id, {type: ctrl.user.connectiontype}).then(function(invitation) {

            logger.success($translate.instant('INVITE-USERS.SUCCESS-MESSAGE'));
        });
    };
    ctrl.onFileSelect = function(files){
        var file =files[0];
        if(file){
            var query = {
                id : ctrl.organisation._id,
                type : file.type,
                name : file.name
            };
            UploadPictureService.signUrl(query).then( function(data){
                UploadPictureService.upload(data,file).then(function(){
                    ctrl.user.picture_url = data.getUrl;
                })
            });
        }
        else return;
    }

    function searchResultHandler(result) {
        ctrl.result.total = result.total;
        ctrl.result.data = result.data;
        ctrl.result.newEmail = true;
        ctrl.result.data.forEach(function (element) {
            if (ctrl.user.email == element.email){
                ctrl.result.newEmail = false;
            }
        });
        if (!ctrl.result.total)
            ctrl.showForm = true;
        ctrl.result.hasSearched = true;
        ctrl.submitted = false;
    };

    //if ($routeParams.query) {
    //    ctrl.qry = $routeParams.query;
    //
    //    // Reset search
    //    offset = 0;
    //    ctrl.result.hasSearched = true;
    //    ctrl.result.total = 0;
    //    ctrl.result.data = [];
    //
    //    getResults();
    //}
}
