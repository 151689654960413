import angular from 'angular';
import appCore from '../core';
import appWidgets from '../widgets';
import appMyTalent from './my-talent';

angular.module('app.talent', [
    appCore,
    appWidgets,
    appMyTalent
]);
