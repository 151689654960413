import angular from 'angular';

angular
    .module('app.account')
    .run(appRun);

appRun.$inject = ['routerHelper'];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: 'guest.reset-password-request',
            config: {
                url: '/reset-password?email&token',
                template: '<reset-password-request></reset-password-request>',
                title: 'Reset Password',
                isPublic: true
            }
        }
    ];
}
