import './dashboard.module.js';
import './dashboard.route';
import './dashboard.controller';
import './components/statistics/statistics.component';
import './components/statistics/statistics.controller';
import './components/recent-actions/recent-actions.component';
import './components/recent-actions/recent-actions.controller';
import './components/latest-project/latest-project.component';
import './components/latest-project/latest-project.controller';

export default 'app.dashboard';
