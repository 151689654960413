import angular from "angular";
import * as template from "./manager-view-project.html";
import managerViewProject from '../../project/manager-view-project/manager-view-project.html'
import "./shared/project-details.html";
import "./shared/role-details.html";
import "./shared/popover.html";

angular.module("app.project.manager-view-project").run(appRun);

appRun.$inject = ["routerHelper"];

/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.manager-view-project",
            config: {
                url: "/project/manage/:organisationId/:projectId",
                template: template,
                controller: "ManagerViewProjectController",
                controllerAs: "main",
                title: "",
                resolve: {
                    myOrganisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    project: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getOne(
                                $stateParams.organisationId,
                                $stateParams.projectId
                            );
                        },
                    ],
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    assignedUsers: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getAssignedConnections(
                                $stateParams.projectId
                            );
                        },
                    ],
                    projectRoles: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getOrganisationProjectRole(
                                $stateParams.organisationId,
                                $stateParams.projectId
                            );
                        },
                    ],
                },
            },
        },
        {
            state: "app.view-project",
            config: {
                url: "/project/view/:organisationId/:projectId",
                template: managerViewProject,
                controller: "ManagerViewProjectController",
                controllerAs: "main",
                title: "",
                resolve: {
                    project: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getOne(
                                $stateParams.organisationId,
                                $stateParams.projectId
                            );
                        },
                    ],
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    assignedUsers: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getAssignedConnections(
                                $stateParams.projectId
                            );
                        },
                    ],
                    projectRoles: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getOrganisationProjectRole(
                                $stateParams.organisationId,
                                $stateParams.projectId
                            );
                        },
                    ],
                },
            },
        },
    ];
}
