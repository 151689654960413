import angular from 'angular';

angular
    .module('app.profile')
    .controller('ReferController', ReferController);

ReferController.$inject = ['currentUser',
    'UserTalentService', 'l10n','ReferService', 'logger', '$translate'];

/* @ngInject */
function ReferController(currentUser, UserTalentService, l10n,ReferService, logger,$translate) {
    var ctrl = this;
    ctrl.currentUser = currentUser;

    ctrl.submit = function(){

        var mail = {
            referrer_name: currentUser.firstname + ' ' + currentUser.lastname,
            referrer_email: currentUser.email,
            refferred_person_name: ctrl.refer.personName,
            refferred_person_email: ctrl.refer.personEmail,
            refferred_person_telephone: ctrl.refer.personPhone,
            refferred_person_whyTribe: ctrl.refer.whyTribe,
            relationship:ctrl.refer.relation
        };
        if(ctrl.mySelf){
            mail.vat = ctrl.refer.myVatNumber;
            mail.self_organisation = ctrl.refer.myName;
        }
        else {
            mail.other_organisation = ctrl.refer.myNonProfitOrgName;
        }
        mail.mySelf = ctrl.mySelf;
        ReferService.sendReferEmail(mail).then(function(){
            logger.success($translate.instant('REFER.SUCCESS-MESSAGE'));
        });
    }
}
