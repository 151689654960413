import angular from 'angular';
import appCore from '../core';
import myOrganisation from './my-organisation';
import publicOrganisation from './public-organisation';
import manageOrganisation from './manage-organisation';
import inviteUsers from './manage-organisation/invite-users';
import createPassiveProfile from './create-passive-profile';
import organisationSearchTalent from './manage-organisation/search-talent';

angular.module('app.organisation', [
    appCore,
    myOrganisation,
    publicOrganisation,
    manageOrganisation,
    inviteUsers,
    createPassiveProfile,
    organisationSearchTalent
]);
