import template from "../create-project-form.html";
import "../create-wizard/add-roles.html";
import "../create-wizard/add-status.html";
import "../create-wizard/add-talents.html";
import "../create-wizard/personality.html";
import "../create-wizard/project-location.html";
import "../create-wizard/project-timing.html";
import "../create-wizard/role-summary.html";
import "../create-wizard/talents-review.html";

angular.module("app.project.edit-project").run(appRun);

appRun.$inject = ["routerHelper"];

/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.edit-project",
            config: {
                url: "/project/edit/:organisationId/:projectId/:step",
                template: template,
                controller: "CreateProjectFormController",
                controllerAs: "main",
                title: "Edit project",
                resolve: {
                    project: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getOne(
                                $stateParams.organisationId,
                                $stateParams.projectId
                            );
                        },
                    ],
                    organisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    projectRoles: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getOrganisationProjectRole(
                                $stateParams.organisationId,
                                $stateParams.projectId
                            );
                        },
                    ],
                },
            },
        },
    ];
}
