import angular from 'angular';

angular
    .module('app.profile.availability')
    .controller('workController', workController);

workController.$inject = ['_','$scope', '$q', 'logger', 'UserService', 'currentUser', 'OrganisationService', 'Util',
    'OrganisationInvitationService', 'ProjectService', 'AuthService', 'MeService', 'UploadPictureService', '$state', 'memberships','myConnections'];

/* @ngInject */
function workController(_,$scope, $q, logger, UserService, currentUser, OrganisationService, Util,
    OrganisationInvitationService, ProjectService, AuthService, MeService, UploadPictureService, $state, memberships, myConnections) {
    var ctrl = this;
    ctrl.myConnections = myConnections;
    if (ctrl.myConnections.length){
        var orgId = _.uniq(myConnections.map(e => e.project_id.organisation_id));
    }
    ctrl.currentUser = currentUser;
    ctrl.isEditing = false;
    ctrl.memberships = memberships;
    ctrl.organisations = memberships.map(memberships => memberships.organisation);
    if (orgId)
        ctrl.organisations = ctrl.organisations.filter(e => {
            return ~orgId.indexOf(e._id);
    });

    //console.log(ctrl.organisations);
    ctrl.selectedOrganisationId = 'ALL';

    ctrl.refreshRoles = function(){
        if (!ctrl.myConnections.length) return;
        if (ctrl.selectedOrganisationId !== 'ALL'){
            ctrl.selectedMembership = ctrl.memberships.find((e) => e.organisation._id === ctrl.selectedOrganisationId);
            ctrl.selectedOrganisation = ctrl.selectedMembership.organisation;
        } 
        
        ProjectService.getMyAssignes({ organisationId: ctrl.selectedOrganisationId, connectionType: ctrl.connectionType}).then((res)=>{
            ctrl.roles = res;
        });

        // My work v1 version
        // OrganisationService.getProjects(ctrl.selectedOrganisationId, { connectionType: ctrl.connectionType}).then((res) => {
        //     ctrl.projects = res.filter((e) => e.type === "PROJECT");
        //     ctrl.roles = res.filter((e) => e.type === "JOB");
        //     return ctrl.projects
        // }).then((projects) =>{
        //     var rolesPromises = projects.map((project) => {
        //         return ProjectService.getOrganisationProjectRole(ctrl.selectedOrganisationId, project._id, { connectionType: ctrl.connectionType});
        //     })
            
        //         $q.all(rolesPromises).then((projectRoles) => {
        //             projectRoles.forEach((roles) => {
        //                 roles.forEach((role) => {
        //                     var project = ctrl.projects.find((e)=> e._id === role.project_id);
        //                     role.project_name = project.name;
        //                     role.project_description = project.description;
        //                     ctrl.roles.push(role);
        //                 })
        //             });
        //     })
        // });
    }

    if (ctrl.selectedOrganisationId) {
        ctrl.refreshRoles();
    }

}
