import angular from 'angular';

angular
    .module('app.organisation')
    .controller('CreateOrganisationFormController', CreateOrganisationFormController);

CreateOrganisationFormController.$inject = ['$scope', '$state', 'logger', 'currentUser', 'l10n', 'OrganisationService',
    'UserService', 'AuthService','UploadPictureService'];

/* @ngInject */
function CreateOrganisationFormController($scope, $state, logger, currentUser, l10n, OrganisationService, UserService, AuthService, UploadPictureService) {
    var ctrl = this;

    ctrl.currentUser = currentUser;
    ctrl.valid = false;
    ctrl.organisation = {
        name: ''
    };

    ctrl.saveOrganisation = function() {
        OrganisationService.save(ctrl.organisation).then(function (organisation) {
            AuthService.refreshCurrentUserMemberships();
            $state.go('app.my-organisation', {'organisationId': organisation._id });
        }, function () {
            logger.error('Unable to create your organisation. Please contact support.');
        });
    }
    ctrl.onFileSelect = function(files){
        var file =files[0];
        if(file){
            var query = {
                id : ctrl.currentUser._id,
                type : file.type,
                name : file.name
            };
            UploadPictureService.signUrl(query).then( function(data){
                UploadPictureService.upload(data,file).then(function(){
                    ctrl.organisation.logo_url = data.getUrl;
                })
            });
        }
        else return;
    }
}
