import angular from 'angular';

angular
    .module('app.profile.public-profile')
    .controller('PublicProfileController', PublicProfileController);

PublicProfileController.$inject = ['currentUser', 'user',
    'UserTalentService', 'l10n', 'memberships', 'talents'];

/* @ngInject */
function PublicProfileController(currentUser, user, UserTalentService, l10n, memberships, talents) {
    var ctrl = this;
    ctrl.user = user;
    ctrl.currentUser = currentUser;
    ctrl.talents = talents;
    ctrl.userLanguage = l10n.guessLanguage();
    ctrl.memberships = memberships;
}
