import angular from "angular";
import template from "./availability.html";

angular.module("app.profile.availability").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.availability",
            config: {
                url: "/availability?:tour",
                template: require("~/app/profile/my-profile/availability/availability.html"),
                controller: "availabilityController",
                controllerAs: "vm",
                title: "My availability",
                resolve: {
                    memberships: [
                        "currentUser",
                        "UserService",
                        function (currentUser, UserService) {
                            return UserService.getOrganisationMemberships(
                                currentUser._id,
                                { includes: ["organisation"] }
                            );
                        },
                    ],
                },
            },
        },
    ];
}
