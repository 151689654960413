import angular from "angular";
import template from "./my-talents.directive.html";

angular.module("app.profile.my-profile").directive("myTalents", myTalents);

function myTalents() {
    var directive = {
        restrict: "E",
        template: template,
        bindToController: {
            user: "=",
            organisationMemberships: "=",
        },
        controller: MyTalentsController,
        controllerAs: "vm",
    };

    return directive;
}

MyTalentsController.$inject = [
    "$scope",
    "$q",
    "logger",
    "TalentMasterService",
    "UserTalentService",
    "UserTalentGroupService",
    "l10n",
    "$uibModal",
    "_",
    "UserService",
    "Util",
];

/* @ngInject */
function MyTalentsController(
    $scope,
    $q,
    logger,
    TalentMasterService,
    UserTalentService,
    UserTalentGroupService,
    l10n,
    $uibModal,
    _,
    UserService,
    Util
) {
    var ctrl = this;

    ctrl.currentUser = ctrl.user;
    ctrl.categories = [
        "knowledge",
        "general",
        "languages_mother_tongue",
        "languages_writing",
        "languages_understanding",
        "languages_speaking",
        "tools",
        "personality",
    ];

    UserTalentService.getAll(ctrl.currentUser._id).then(function (talents) {
        ctrl.talents = _.reduce(
            talents,
            function (result, item) {
                result[item._id] = item;
                return result;
            },
            {}
        );
    });
}
