import angular from "angular";
import * as template from "./manage-members.html";

angular.module("app.organisation.manage-organisation").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.manage-organisation-members",
            config: {
                url: "/manage-organisation/:organisationId/members/:tab",
                template: template,
                controller: "ManageOrganisationMembersController",
                controllerAs: "myOrg",
                title: "Manage organisation members",
                resolve: {
                    myOrganisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    features: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getAvailableFeatures(
                                $stateParams.organisationId
                            );
                        },
                    ],
                    memberships: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getMemberships(
                                $stateParams.organisationId,
                                { includes: ["user"] }
                            ).then(function (memberships) {
                                return memberships;
                            });
                        },
                    ],
                    invitations: [
                        "$stateParams",
                        "OrganisationInvitationService",
                        function ($stateParams, OrganisationInvitationService) {
                            return OrganisationInvitationService.getAll(
                                $stateParams.organisationId,
                                { includes: ["user"] }
                            ).then(function (invitations) {
                                return invitations;
                            });
                        },
                    ],
                },
            },
        },
    ];
}
