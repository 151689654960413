import angular from "angular";
import template from "./search-talent.html";
import controller from "./search-talent.controller";

var SearchTalent = {
    bindings: {
        organisationId: "<",
    },
    template: template,
    controller: controller,
};

angular
    .module("app.organisation.manage-organisation")
    .component("searchTalent", SearchTalent);
