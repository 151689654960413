import angular from "angular";
import template from "./manage-organisation.html";

angular.module("app.organisation.manage-organisation").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.manage-organisation",
            config: {
                url: "/manage-organisation/:organisationId",
                template: template,
                controller: "ManageOrganisationController",
                controllerAs: "myOrg",
                title: "Manage organisation",
                resolve: {
                    myOrganisation: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getOne(
                                $stateParams.organisationId
                            ).then(function (organisation) {
                                return organisation;
                            });
                        },
                    ],
                    features: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getAvailableFeatures(
                                $stateParams.organisationId
                            );
                        },
                    ],
                    memberships: [
                        "$stateParams",
                        "OrganisationService",
                        function ($stateParams, OrganisationService) {
                            return OrganisationService.getMemberships(
                                $stateParams.organisationId,
                                { includes: ["user"] }
                            ).then(function (memberships) {
                                return memberships;
                            });
                        },
                    ],
                    invitations: [
                        "$stateParams",
                        "OrganisationInvitationService",
                        function ($stateParams, OrganisationInvitationService) {
                            return OrganisationInvitationService.getAll(
                                $stateParams.organisationId,
                                { includes: ["user"] }
                            ).then(function (invitations) {
                                return invitations;
                            });
                        },
                    ],
                    messages: [
                        "$stateParams",
                        "ProjectService",
                        function ($stateParams, ProjectService) {
                            return ProjectService.getAllMessages(
                                $stateParams.organisationId
                            ).then(function (messages) {
                                console.log(messages);
                                return messages;
                            });
                        },
                    ],
                },
            },
        },
    ];
}
