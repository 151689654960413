import angular from "angular";
import template from "./talent-pool.html";

angular.module("app.talent.talent-pool").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.talent-pool",
            config: {
                url: "/talent-pool?filter&page&size&sort",
                template: template,
                controller: "TalentPoolController",
                controllerAs: "vm",
                params: {
                    filter: {
                        value: "",
                        squash: true,
                    },
                    page: {
                        value: "0",
                        squash: true,
                    },
                    size: {
                        value: "10",
                        squash: true,
                    },
                    sort: {
                        squash: true,
                    },
                },
                title: "",
            },
        },
    ];
}
