import angular from 'angular';

angular
    .module('app.login')
    .controller('AuthenticationController', AuthenticationController);

AuthenticationController.$inject = ['$routeParams', '$location', 'User', 'Session'];

/* @ngInject */
function AuthenticationController($routeParams, $location, User, Session) {
    /* jshint validthis: true */
    var vm = this;

    if($routeParams && $routeParams.token) {

        Session.setToken($routeParams.token);

        // Request user information to cache client side and redirect to dashboard
        User.get({}, function(result) {
            var user = result.data;
            user.id = result.id;
            Session.setUser(user);
            delete $location.$$search.token; // Delete the token query parameter before redirect
            $location.path('/my-profile');
        }, function() {
            // show error
        });
    } else if($routeParams && $routeParams.error) {
        // Report error and redirect back to unauthenticated section
    } else {

    }
}
