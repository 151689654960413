import angular from 'angular';
import appCore from './core';
import appWidgets from './widgets';
import appLayout from './layout';
import appAccount from './account';
import appLogin from './login';
import appProfile from './profile';
import appTalent from './talent';
import appTalentPool from './talent-pool';
import appOrganisation from './organisation';
import appProject from './project';
import appSearch from './search';
import appReferFriends from './refer-friends';
import appDashboard from './dashboard';

angular.module('app', [
    appCore,
    appWidgets,
    appLayout,
    appAccount,
    appLogin,
    appProfile,
    appTalent,
    appTalentPool,
    appOrganisation,
    appProject,
    appSearch,
    appReferFriends,
    appDashboard
]).config(function($compileProvider) {
    $compileProvider.preAssignBindingsEnabled(true);
});
