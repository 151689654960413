import angular from "angular";
import * as template from "./user-talent-multiselect.component.html";

/**
 * categoryFilter: 'LANGUAGE', 'PERSONALITY', 'SOFTWARE'
 *
 * @type {{bindings: {model: string, defaultState: string, placeholder: string, categoryFilter: string}, template: string, controller: UserTalentMultiselectController}}
 */
var UserTalentMultiselect = {
    bindings: {
        model: "=",
        data: "=",
        userId: "<",
        type: "<",
        defaultState: "<",
        placeholder: "<",
        categoryFilter: "<",
    },
    template: template,
    controller: UserTalentMultiselectController,
};

UserTalentMultiselectController.$inject = [
    "$translate",
    "$q",
    "TalentMasterService",
    "UserTalentService",
    "l10n",
    "toastr",
    "$timeout",
    "$uibModal",
];

/* @ngInject */
function UserTalentMultiselectController(
    $translate,
    $q,
    TalentMasterService,
    UserTalentService,
    l10n,
    toastr,
    $timeout,
    $uibModal
) {
    var ctrl = this;

    ctrl.results = [];
    ctrl.disabled = true;
    var LANGUAGE_MOTHER_TONGUE = "languages_mother_tongue",
        LANGUAGE_SPEAKING = "languages_speaking",
        LANGUAGE_WRITING = "languages_writing";
    var languageLabelsForMotherTongue = [
        "languages_speaking",
        "languages_writing",
        "languages_understanding",
    ];
    var languageLabels = ["languages_understanding"];
    var promise = $q.all([]);
    $timeout(function () {
        ctrl.disabled = false;
    }, 100);

    ctrl.onSelectCallback = function (item) {
        console.log("item", item);
        if (item.isNew) {
            var masterTalent = angular.copy(item);
            delete masterTalent._id;
            delete masterTalent.isNew;

            TalentMasterService.save(masterTalent)
                .then(function (talent) {
                    if (ctrl.defaultState) talent[ctrl.defaultState] = true;

                    var newUserTalent = angular.copy(talent);
                    newUserTalent.user_id = ctrl.userId;
                    newUserTalent.talent_id = talent._id;
                    if (ctrl.type === LANGUAGE_MOTHER_TONGUE) {
                        saveLanguages(
                            languageLabelsForMotherTongue,
                            newUserTalent
                        );
                    }
                    if (
                        ctrl.type === LANGUAGE_SPEAKING ||
                        ctrl.type === LANGUAGE_WRITING
                    ) {
                        saveLanguages(languageLabels, newUserTalent);
                    }
                    UserTalentService.save(
                        ctrl.userId,
                        newUserTalent,
                        ctrl.type
                    )
                        .then(function (newTalent) {
                            console.log(newTalent);
                            ctrl.model.splice(ctrl.model.indexOf(item), 1);
                            ctrl.model.push(newTalent);
                        })
                        .catch(function (err) {
                            toastr.error(err, "Error");
                            ctrl.model.splice(ctrl.model.indexOf(item), 1);
                        });
                })
                .catch(function (err) {
                    onTalentError(err, item);
                    ctrl.model.splice(ctrl.model.indexOf(item), 1);
                    // toastr.error(err, 'Error');
                });
        } else {
            if (ctrl.defaultState) {
                item[ctrl.defaultState] = true;
            }
            console.log(item);
            var newUserTalent = angular.copy(item);
            newUserTalent.user_id = ctrl.userId;
            newUserTalent.talent_id = item._id;

            console.log("defaultState", ctrl.defaultState);
            console.log("newUserTalent", newUserTalent);
            console.log(ctrl.type);

            UserTalentService.save(ctrl.userId, newUserTalent, ctrl.type)
                .then(function (newTalent) {
                    ctrl.model.splice(ctrl.model.indexOf(item), 1);
                    ctrl.model.push(newTalent);
                    if (ctrl.type === LANGUAGE_MOTHER_TONGUE) {
                        saveLanguages(
                            languageLabelsForMotherTongue,
                            newUserTalent
                        );
                    }
                    if (
                        ctrl.type === LANGUAGE_SPEAKING ||
                        ctrl.type === LANGUAGE_WRITING
                    ) {
                        saveLanguages(languageLabels, newUserTalent);
                    }
                })
                .catch(function (err) {
                    toastr.error(err, "Error");
                    ctrl.model.splice(ctrl.model.indexOf(item), 1);
                });
        }
    };

    ctrl.onRemoveCallback = function (item) {
        UserTalentService.remove(ctrl.userId, item._id, ctrl.type).catch(
            function (err) {
                toastr.error(err, "Error");
                ctrl.model.push(item);
            }
        );
    };

    ctrl.searchForTalent = function (query) {
        if (!query) return;
        TalentMasterService.search(query, ctrl.categoryFilter).then(function (
            result
        ) {
            var combinedResult = result.data;
            var currentLanguage = l10n.getLanguage();
            var matchingQuery = result.data.filter(function (element) {
                return (
                    element[currentLanguage] &&
                    element[currentLanguage].toLowerCase() ===
                        query.toLowerCase()
                );
            });

            if (!result.data.length || matchingQuery.length === 0) {
                var userInputItem = {
                    _id: query,
                    en: query,
                    nl: query,
                    isNew: true,
                };

                // Only add label when creating for a category that actually uses labels...
                if (ctrl.categoryFilter)
                    userInputItem.labels = [ctrl.categoryFilter];

                combinedResult = [userInputItem].concat(combinedResult);
            }

            ctrl.results = combinedResult;
        });
    };
    function onTalentError(err, item) {
        var modalInstance = $uibModal.open({
            animation: true,
            template: require("~/app/widgets/talent-block/talent-error.html"),
            controller: function (error, data, item) {
                var vm = this;
                vm.talent = item._id;
                vm.err = error;
                if (~error.indexOf("LANGUAGE")) {
                    var type = "languages_mother_tongue";
                    var dataTag = data.languages_mother_tongue;
                    vm.category = "LANGUAGE";
                }
                if (~error.indexOf("PERSONALITY")) {
                    var type = "personality";
                    vm.category = "PERSONALITY";
                    var dataTag = data.personality;
                }
                if (~error.indexOf("SOFTWARE")) {
                    var type = "tools";
                    vm.category = "SOFTWARE";
                    var dataTag = data.tools;
                }
                if (~error.indexOf("different")) {
                    var type = "general";
                    vm.category = "";
                    var dataTag = data.general;
                }
                vm.translatedCategory = $translate.instant(
                    "TALENT_CATEGORY_ERROR." + vm.category
                );
                vm.addTalent = function () {
                    saveNewTalent(item._id, vm.category, dataTag, type);
                    modalInstance.close();
                };
                vm.close = function () {
                    modalInstance.close();
                };
            },
            controllerAs: "vm",
            size: "md",
            resolve: {
                error: function () {
                    return err;
                },
                data: function () {
                    return ctrl.data;
                },
                item: function () {
                    return item;
                },
            },
        });
        modalInstance.result.then(
            function (membership) {},
            function () {}
        );
    }

    function saveNewTalent(query, categoryFilter, model, type) {
        TalentMasterService.search(query, categoryFilter).then(function (
            talents
        ) {
            var talent = talents.data.find(function (elem) {
                return (
                    (elem.nl &&
                        elem.nl.toLowerCase() === query.toLowerCase()) ||
                    (elem.en && elem.en.toLowerCase() === query.toLowerCase())
                );
            });
            var id = ctrl.userId;
            var newUserTalent = angular.copy(talent);
            newUserTalent.user_id = id;
            newUserTalent.talent_id = talent._id;

            UserTalentService.save(id, newUserTalent, type)
                .then(function (newTalent) {
                    model.push(newTalent);
                    if (categoryFilter === "LANGUAGE") {
                        saveLanguages(
                            languageLabelsForMotherTongue,
                            newUserTalent
                        );
                    }
                })
                .catch(function (err) {
                    toastr.error(err, "Error");
                });
        });
    }

    function saveLanguages(labels, newUserTalent) {
        angular.forEach(labels, function (label) {
            promise = promise.then(function () {
                UserTalentService.save(ctrl.userId, newUserTalent, label)
                    .then(function (newTalent) {
                        ctrl.data[label].push(newTalent);
                        ctrl.newTalent = null;
                    })
                    .catch(function (err) {
                        toastr.error(err, "Warning");
                    });
            });
        });
    }
}

angular
    .module("app.widgets")
    .component("userTalentMultiselect", UserTalentMultiselect);
