import angular from "angular";
import myTalentTemplate from "./my-talent.html";

angular.module("app.talent.my-talent").run(appRun);

appRun.$inject = ["routerHelper"];
/* @ngInject */
function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
}

function getStates() {
    return [
        {
            state: "app.my-talent",
            config: {
                url: "/my-talent/:userTalentId",
                template: myTalentTemplate,
                controller: "MyTalentController",
                controllerAs: "talentCtrl",
                title: "",
                params: {
                    isPublicProfile: false,
                },
                resolve: {
                    userTalent: [
                        "$stateParams",
                        "UserService",
                        "UserTalentService",
                        "currentUser",
                        function (
                            $stateParams,
                            UserService,
                            UserTalentService,
                            currentUser
                        ) {
                            return UserTalentService.getOne(
                                currentUser._id,
                                $stateParams.userTalentId
                            );
                        },
                    ],
                    //talents: ['Session', 'API', function(Session, API) {
                    //    return API.one('members', Session.getUserId()).one('talents').get({ s: 100 });
                    //}],
                    //talentgroups: ['Session', 'API', function(Session, API) {
                    //    return API.one('members', Session.getUserId()).one('talentgroups').get();
                    //}]
                },
            },
        },
    ];
}
