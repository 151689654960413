(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('SearchService', SearchService);

    SearchService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function SearchService($http, $q, logger, ENV_CONFIG) {
        var subPath = 'search';
        var service = {
            search: search,
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data.message);
        }

        /**
         *
         * @param query
         * @returns {*}
         */
        function search(query) {
            return $http.get(ENV_CONFIG.baseAPI + 'search', {
                params: {
                    q: query
                }
            }).then(success).catch(fail);
        }
    }

})();
