import './project.module';
import './project.route';
import './create-project-form.controller';
import './talent/talent.directive';
import './create-wizard/footer-button/footer-button.directive';
import './create-wizard/tooltip-templates/duration.html';
import './create-wizard/tooltip-templates/owner.html';
import './create-wizard/tooltip-templates/period.html';
import './create-wizard/tooltip-templates/status.html';
import './create-wizard/tooltip-templates/tasks.html';
import './templates/project-templates.route';
import './templates/project-templates.controller';
import './templates/view-template.route';
import './templates/view-template.controller';

export default 'app.project';
