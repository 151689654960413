(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('MeService', MeService);

    MeService.$inject = ['$exceptionHandler', '$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function MeService($exceptionHandler, $http, $q, logger, ENV_CONFIG) {
        var subPath = 'me';
        var service = {
            leaveOrganisation: leaveOrganisation,
            finishOnboarding: finishOnboarding,
            startOnboarding: startOnboarding,
            endOnboarding: endOnboarding
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            $exceptionHandler(error.data);
            return error;
        }

        function leaveOrganisation(organisationId) {
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/organisation/' + organisationId)
                .then(success)
                .catch(fail);
        }

        function finishOnboarding(data) {
            return $http.post(ENV_CONFIG.baseAPI + subPath + '/finish-onboarding', data)
                .then(success)
                .catch(fail);
        }

        function startOnboarding() {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/start-onboarding')
                .then(success)
                .catch(fail);
        }

        function endOnboarding(data) {
            return $http.post(ENV_CONFIG.baseAPI + subPath + '/end-onboarding', data)
                .then(success)
                .catch(fail);
        }
    }

})();
