import angular from 'angular';
import modalTemplate from "../layout/template-modal.html";

angular
    .module('app.organisation')
    .controller('CreateProjectFormController', CreateProjectFormController);

CreateProjectFormController.$inject = ['$stateParams','$rootScope', '$scope', "$uibModal", '$state', 'logger', 'currentUser', 'organisation', 'l10n',
    'TalentMasterService', 'UserTalentService', 'ProjectService', 'WizardHandler', 'project', 'projectRoles', '$translate', '$window'];

/* @ngInject */
function CreateProjectFormController($stateParams, $rootScope, $scope, $uibModal, $state, logger, currentUser, organisation, l10n,
                                     TalentMasterService, UserTalentService, ProjectService, WizardHandler, project, projectRoles, $translate, $window) {
    var ctrl = this;

    ctrl.currentUser = currentUser;
    ctrl.userLanguage = l10n.guessLanguage();
    ctrl.newTalent = null;
    ctrl.isTemplateEditor = currentUser.roles && currentUser.roles.includes('template_editor');
    ctrl.templates = [];
    ProjectService.getTemplates('PROJECT').then((templates) => ctrl.templates = templates);
    ctrl.isEditing = $state.current.name === 'app.edit-project' ? true : false;
    ctrl.availableTemplateTags = ['Start-up', 'Planning', 'Management',
        'Juridisch', 'Financieel', 'Verkoop', 'Marketing', 'Product', 'Team'];
    ctrl.availableProjectTags = [];
    if(project){
        if(project.start){
            project.start = new Date(project.start);
        }
        if(project.end){
            project.end = new Date(project.end);
            project.hasEndDate = true;
        }else{
            project.hasEndDate = false;
        }
        ctrl.project = project;
    }else{
        ctrl.project = {
            organisation_id: organisation._id,
            owner: organisation.owner,
            //organisation_member_id: currentUser._id, // TODO: Check what should be done with this?
            start: new Date(),
            end: new Date(),
            locations: [],
            logo_url: organisation.logo_url,
            type: 'JOB',
            state: 'PUBLISHED',
            duration: 'CONTINUOUS',
            frequency: 'FREQUENCY',
            hasEndDate: true,
            project_roles: []
        };
        // Add the organisation's address as default location
        if(organisation.street || organisation.number || organisation.city) {
            ctrl.project.locations.push({
                street: organisation.street || '',
                number: organisation.number || '',
                box: organisation.box || '',
                zipcode: organisation.zipcode || '',
                city: organisation.city || '',
                province: organisation.province || '',
                country: organisation.country || ''
            });
        }
    }
    ctrl.projectRoles = projectRoles.map((elem) => {
        if (elem.start) {
            elem.start = new Date(elem.start);
            elem.hasEndDate = false;
        }
        else{
            elem.start = new Date(project.start);
            elem.end = project.end ? new Date(project.end) : null;
            elem.hasEndDate = project.end ? project.hasEndDate : false;
        }
        if (elem.end) {
            elem.end = new Date(elem.end);
            elem.hasEndDate = true;
        }
        return elem;
    });
    ctrl.setStateOptions = function() {
        ctrl.stateOptions = [
            { name: 'DRAFT', description: $translate.instant('ORGANISATION.PROJECTS.STATE_OPTIONS.DRAFT') },
            { name: 'PUBLISHED', description: $translate.instant('ORGANISATION.PROJECTS.STATE_OPTIONS.PUBLISHED') },
            { name: 'ARCHIVED', description: $translate.instant('ORGANISATION.PROJECTS.STATE_OPTIONS.ARCHIVED') }
        ];
    };
    ctrl.setStateOptions();


    ctrl.setConnectionVisbilityOptions = function() {
        ctrl.connectionVisibilityOptions = [
            { name: 'APPLICANT', description: $translate.instant('GENERAL.CONNECTION_TYPES.APPLICANT') },
            { name: 'BOARD_MEMBER', description: $translate.instant('GENERAL.CONNECTION_TYPES.BOARD_MEMBER') },
            { name: 'FREELANCER', description: $translate.instant('GENERAL.CONNECTION_TYPES.FREELANCER') },
            { name: 'PARTNER', description: $translate.instant('GENERAL.CONNECTION_TYPES.PARTNER') },
            { name: 'EMPLOYEE', description: $translate.instant('GENERAL.CONNECTION_TYPES.EMPLOYEE') },
            { name: 'EX-EMPLOYEE', description: $translate.instant('GENERAL.CONNECTION_TYPES.EX-EMPLOYEE') },
            { name: 'VOLUNTEER', description: $translate.instant('GENERAL.CONNECTION_TYPES.VOLUNTEER') },
            { name: 'OTHER', description: $translate.instant('GENERAL.CONNECTION_TYPES.OTHER') }
        ];
    };
    ctrl.setConnectionVisbilityOptions();

    $rootScope.$on('$translateChangeSuccess', function () {
        ctrl.setStateOptions();
        ctrl.setConnectionVisbilityOptions();
    });

    ctrl.is100PercentAtHome = function() {
        return ctrl.project.is_remote && ctrl.project.remote_percentage >= 100;
    }

    ctrl.onHomePercentageChange = function() {
        if(ctrl.is100PercentAtHome()) {
            ctrl.project.is_on_site = false;
        }
    };

    ctrl.onOnSiteClick = function() {
        if(!ctrl.is100PercentAtHome()){
            ctrl.project.is_on_site = !ctrl.project.is_on_site;
        }
    };

    ctrl.toggleOpenDatePicker = function($event,datePicker) {
        $event.preventDefault();
        $event.stopPropagation();
        ctrl[datePicker] = !ctrl[datePicker];
    };

    ctrl.addLocation = function() {
        if (!ctrl.project.locations) {
            ctrl.project.locations = [];
        }

        ctrl.project.locations.push({city: "", province: "", country: ""});
    };

    ctrl.deleteLocation = function(index) {
        if (ctrl.project.locations) {
            ctrl.project.locations.splice(index, 1);
        }
    };

    ctrl.save = function() {
        ProjectService.save(organisation._id, ctrl.project).then(function(project) {
            $state.go('app.my-organisation', {organisationId: organisation._id});
        });
    };

    ctrl.searchForTalent = function(query) {
        if(!query) { return; }

        return TalentMasterService.search(query).then(function(result) {
            return result.data;
        });
    };

    ctrl.addTalent = function() {
        if (ctrl.newTalent == null || ctrl.newTalent == '') {
            logger.warning('Unable to add talent, something went wrong!', ctrl.newTalent);
            return;
        } else if (typeof ctrl.newTalent == 'string' || ctrl.newTalent instanceof String) {
            // Talent is new and was not provided by the autocomplete function
            var talent = {};
            talent['en'] = ctrl.newTalent;
            talent['nl'] = ctrl.newTalent;
            // First save the talent
            TalentMasterService.save(talent).then(function(talent) {
                // Next push the project-talent
                var projectTalent = angular.copy(talent);
                projectTalent.talent_id = projectTalent._id;
                delete projectTalent._id;
                ctrl.project.talents.push(projectTalent);
                ctrl.newTalent = null;
            });
        } else if (angular.isObject(ctrl.newTalent)) {
            // Talent was hopefully provided by the autocomplete function
            var projectTalent = angular.copy(ctrl.newTalent);
            projectTalent.talent_id = projectTalent._id;
            delete projectTalent._id;
            ctrl.project.talents.push(projectTalent);
            ctrl.newTalent = null;
        }
    };

    ctrl.removeTalent = function(talent, index) {
        ctrl.project.talents.splice(index, 1);
    };

    ctrl.setProjectType = function(type) {
        ctrl.project.type = type;
    }

    ctrl.addTask = function() {
        if (!ctrl.project.tasks) {
            ctrl.project.tasks = [];
        }

        ctrl.project.tasks.push({description: ""});
    };

    ctrl.addRoles = function() {

        var role = {
            name: ctrl.newRole,
            hours_recurrence: 'ONGOING',
            frequency: 'PER_DAY',
            tasks: [],
            knowledge: [],
            general: [],
            start: ctrl.project.start,
            end: ctrl.project.end,
            hasEndDate: ctrl.project.hasEndDate,
            languages_mother_tongue: [],
            languages_writing: [],
            languages_understanding: [],
            languages_speaking: [],
            tools: [],
            personality: [],
            start:ctrl.project.start,
            end:ctrl.project.end
        }
        if(ctrl.project.type === "JOB"){
            if(!ctrl.projectRoles[0]) {
                ctrl.projectRoles[0] = role;
            }
            ctrl.projectRoles[0].name = ctrl.project.name;
            ctrl.projectRoles[0].description = ctrl.project.description;
        }
        else {
            ctrl.projectRoles.push(role);
        }
        ctrl.newRole = '';

    };

    ctrl.openTemplateModal = function() {
        $uibModal.open({
                animation: true,
                template: modalTemplate,
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.templates = ctrl.templates;
                    vm.selectTemplate = function (template) {
                        $uibModalInstance.close(template);
                        ctrl.project.description = template.description;
                    };
                    vm.cancel = function () {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: "vm",
                size: "m",
                resolve: {
                    templates: function () {
                        return ctrl.templates;
                    },
                },
            }).result.then(function(){}, function(){})
    };

    ctrl.addRoleTasks = function(role) {
        if (!role.tasks) {
            role.tasks = [];
        }

        if(ctrl.newTask){
            role.tasks.push({
                description: ctrl.newTask,
                time_percentage: ctrl.newTaskHours || 0
            });
            ctrl.newTask = '';
            ctrl.newTaskHours = '';
        }
    };

    ctrl.deleteTask = function(index) {
        if (ctrl.project.tasks) {
            ctrl.project.tasks.splice(index, 1);
        }
    };

    ctrl.deleteRole = function(index) {
        if (!ctrl.projectRoles) return;
        if (ctrl.projectRoles[index]._id) {
            ProjectService.deleteOrganisationProjectRole(organisation._id, ctrl.project._id, ctrl.projectRoles[index]._id)
                .then(function(){
                    ctrl.projectRoles.splice(index, 1);
                });
        }
        else {
            ctrl.projectRoles.splice(index, 1);
        }
    };

    ctrl.deleteRoleTask = function(role, index) {
        if (role.tasks) {
            role.tasks.splice(index, 1);
        }
    };

    ctrl.searchForTalent = function(query) {
        if(!query) return;

        TalentMasterService.search(query).then(function(result) {

            var combinedResult = result.data;
            var currentLanguage = l10n.getLanguage();
            var matchingQuery = result.data.filter(function(element) {
                return (element[currentLanguage] && (element[currentLanguage].toLowerCase() === query.toLowerCase()));
            });

            if(!result.data.length || matchingQuery.length === 0) {
                var userInputItem = {
                    _id: query,
                    en: query,
                    nl: query,
                    isNew: true
                };

                combinedResult = [userInputItem].concat(combinedResult);
            }

            ctrl.talentsAutocomplete = combinedResult;
        });
    };

    ctrl.getPostbody = function(){
        var postbody = {};
        angular.copy(ctrl.project, postbody);
        // remove unused properties
        if(ctrl.project.hasEndDate === false){
            postbody.end = null;
        }
        if(ctrl.project.is_remote !== true){
            delete postbody.remote_percentage;
        }

        delete postbody.hasEndDate;
        return postbody;
    };

    ctrl.onComplete = function() {
        if(ctrl.project.type === 'JOB'){
            ctrl.addRoles();
        }
        ctrl.projectRoles.forEach(function(oneRole,index){
            ctrl.updateRole(oneRole);
        });
        ProjectService.update(organisation._id, ctrl.getPostbody()).then(function(project) {
            $state.go('app.manager-view-project', {organisationId: organisation._id, projectId: project._id});
        });
    };
    ctrl.saveProject = function (){
        if(ctrl.project && ctrl.project._id) {
            ctrl.updateProject();
            if(ctrl.project.type === 'JOB') {
                ctrl.projectRoles[0].name = ctrl.project.name;
                ctrl.projectRoles[0].description = ctrl.project.description;
                ctrl.updateRole(ctrl.projectRoles[0]);
            }
        } else {
            ProjectService.save(organisation._id, ctrl.project).then(function(project) {
                ctrl.project._id = project._id;
                if(ctrl.project.type === 'JOB') {
                    ctrl.addRoles();
                    ctrl.createRoles();
                }
            });
        }
    };

    ctrl.updateProject = function (){

        ProjectService.update(organisation._id, ctrl.getPostbody()).then(function(project){
            // ctrl.project = project;
        });
    };

    ctrl.updateRole = function (role){
        if(role._id) {
            ProjectService.updateOrganisationProjectRole(organisation._id, ctrl.project._id, role);
        } else {
            ProjectService.createOrganisationProjectRole(organisation._id, ctrl.project._id, role);
        }
    };

    ctrl.createRoles = function (){
        var newRoles = [];
        ProjectService.createOrganisationProjectRoles(organisation._id, ctrl.project._id, ctrl.projectRoles)
            .then(function(newRole,index){
                ctrl.projectRoles.forEach(function(oneRole,index){
                    ctrl.projectRoles[index]._id = newRole[index]._id;
                });
            })
    }
    ctrl.locationOnNext = function(){
        if(ctrl.project.is_remote !== true && ctrl.project.is_on_site !== true ){
            ctrl.noLocationError = true;
            return true;
        }else{
            ctrl.noLocationError = false;
            return false;
        }
    };
    ctrl.checkDisabled = function(roles){
        if(!roles || roles.length === 0){
            return true;
        }
        var value = roles.every(function(role){
            return role.name;
        });
        return !value;
    };

    ctrl.cancel = function() {
        $window.history.back();
    };

    if ($stateParams.step) {
        var step = $stateParams.step;
        var wizard = WizardHandler.wizard();
        if (wizard){
            wizard.goTo(step);
        }
    }
    $scope.$watch(function () {
        return WizardHandler.wizard();
    }, function (wizard) {
        if (wizard && step){
            wizard.goTo(3);
        }
    });
}
