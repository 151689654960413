(function () {
    'use strict';

    angular
        .module('blocks.api')
        .factory('OrganisationService', OrganisationService);

    OrganisationService.$inject = ['$http', '$q', 'logger', 'ENV_CONFIG'];

    /* @ngInject */
    function OrganisationService($http, $q, logger, ENV_CONFIG) {
        var subPath = 'organisations';
        var service = {
            save: save,
            update: update,
            remove: remove,
            getOne: getOne,
            search: search,
            getMemberships: getMemberships,
            searchMembers: searchMembers,
            handoverChief: handoverChief,
            saveMember: saveMember,
            updateMember: updateMember,
            updateMemberAvailability: updateMemberAvailability,
            removeMember: removeMember,
            addMemberTalent: addMemberTalent,
            removeMemberTalent: removeMemberTalent,
            searchConnections: searchConnections,
            getProjects: getProjects,
            getAvailableFeatures: getAvailableFeatures,
            updateMemberRates: updateMemberRates
        };

        return service;

        /**
         * Generic success function
         *
         * @param response
         * @returns {*}
         */
        function success(response) {
            return response.data;
        }

        /**
         * Generic fail function
         *
         * @param error
         * @returns {Array|*}
         */
        function fail(error) {
            return $q.reject(error.data);
        }

        function save(organisation) {
            return $http.post(ENV_CONFIG.baseAPI + subPath, organisation)
                .then(success)
                .catch(fail);
        }


        function update(organisationId, data) {
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/' + organisationId, data)
                .then(success)
                .catch(fail);
        }

        function remove(organisationId) {
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/' + organisationId)
                .then(success)
                .catch(fail);
        }

        /**
         * GET one organisation
         *
         * @param organisationId
         * @returns {*}
         */
        function getOne(organisationId) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + organisationId)
                .then(success)
                .catch(fail);
        }

        /**
         * GET one available features for a single organisation
         *
         * @param organisationId
         * @returns {*}
         */
        function getAvailableFeatures(organisationId) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/features')
                .then(success)
                .catch(fail);
        }

        /**
         *
         * @param query
         * @returns {*}
         */
        function search(query) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/search', {
                params: {
                    q: query
                }
            }).then(success).catch(fail);
        }

        /**
         *
         * @param organisationId
         * @returns {*}
         */
        function getMemberships(organisationId, params) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/members', {
                params: params
            })
                .then(success)
                .catch(fail);
        }
        function searchMembers(organisationId, query, type){

            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/members/search', {
                params: {
                    q: query,
                    type: type
                }
            })
                .then(success)
                .catch(fail);
        }

        function saveMember(organisationId, userData) {
            return $http.post(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members', userData)
                .then(success);
        }

        function updateMember(organisationId, membershipId, membership) {
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members/' + membershipId, membership)
                .then(success)
                .catch(fail);
        }

        function updateMemberAvailability(organisationId, membershipId, membership) {
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members/' + membershipId+'/availability', membership)
                .then(success)
                .catch(fail);
        }
        function updateMemberRates(organisationId, membershipId, membership) {
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members/' + membershipId+'/rates', membership)
                .then(success)
                .catch(fail);
        }

        function removeMember(organisationId, membershipId) {
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members/' + membershipId)
                .then(success)
                .catch(fail);
        }

        function addMemberTalent(organisationId, membershipId, data) {
            return $http.post(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members/' + membershipId + '/talents', data)
                .then(success)
                .catch(fail);
        }

        function removeMemberTalent(organisationId, membershipId, talentId) {
            return $http.delete(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members/' + membershipId + '/talents/' + talentId)
                .then(success)
                .catch(fail);
        }

        /**
         *
         * @param organisationId
         * @returns {*}
         */
        function getProjects(organisationId, params) {
            return $http.get(ENV_CONFIG.baseAPI + subPath + '/' + organisationId + '/projects', {
                params: params
            })
            .then(success)
            .catch(fail);
        }

        function searchConnections(organisationId, data) {
            return $http.post(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/connections/search-talent', data)
                .then(success)
                .catch(fail);
        }

        function handoverChief(organisationId, membershipId){
            return $http.put(ENV_CONFIG.baseAPI + subPath + '/'+ organisationId + '/members/handoverchief/' + membershipId)
                .then(success)
                .catch(fail);
        }

    }

})();
