import angular from 'angular';

let userPicture = () => {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {
            let src = attr.url || require(`Images/placeholder/person.png`);

            // required for webpack to pick up image
            element.attr('src', src);
            attr.$observe('url', function (newUrl) {
                element.attr('src', newUrl || src);
            })
        }
    };
};

userPicture.$inject = [];

angular.module('app.widgets').directive('userPicture', userPicture);
