import angular from 'angular';

angular
    .module('app.profile')
    .controller('MyOrganisationController', MyOrganisationController);

    MyOrganisationController.$inject = ['$timeout','$scope', '$q', '$state', '$stateParams', 'currentUser', 'myOrganisation', 'features', 'memberships', 'MeService', 'AuthService', 'OrganisationService', 'ngIntroService', '$translate', 'logger'];

    /* @ngInject */
    function MyOrganisationController($timeout, $scope, $q, $state, $stateParams, currentUser, myOrganisation, features, memberships, MeService, AuthService, OrganisationService, ngIntroService, $translate, logger) {
        var ctrl = this;
        ctrl.currentUser = currentUser;
        ctrl.organisation = myOrganisation;
        ctrl.features = features;
        ctrl.memberships = memberships;
        ctrl.isChief = false;

    for(var i=0; i < ctrl.memberships.length; i++) {
        var membership = ctrl.memberships[i];
        if(($stateParams.organisationId == membership.organisation_id) && (membership.user_id == ctrl.currentUser._id) && membership.role == 'CHIEF') {
            ctrl.isChief = true;
        }
    }

    ctrl.leaveOrganisation = function() {
        if(ctrl.isChief) {
            logger.error('You cannot leave an organisation while you are chief! Assign a new chief or delete the organisation from the organisation\'s profile');
            return;
        }

        MeService.leaveOrganisation(membership.organisation_id).then(function(result) {
            const index = ctrl.memberships.indexOf(membership);
            ctrl.memberships.splice(index, 1);
            AuthService.refreshCurrentUserMemberships();
            $state.go('app.my-profile');
        });
    };
        $scope.$on('$stateChangeSuccess',
            function (event, toState, toParams, fromState, fromParams) {
                if (fromParams.tour) {
                    ngIntroService.clear()
                    ctrl.IntroOptions = {
                        steps: [
                            {
                                element: "#step5",
                                intro: '<p class="blue-grey f-s-20">' + $translate.instant('PRODUCT_TOUR.STEP_5_TITLE') + '</p>' + $translate.instant('PRODUCT_TOUR.STEP_5'),
                                position: "right"
                            }
                        ],
                        showStepNumbers: false,
                        showBullets: false,
                        exitOnOverlayClick: true,
                        exitOnEsc: true,
                        nextLabel: $translate.instant('PRODUCT_TOUR.BUTTON_5'),
                        doneLabel: $translate.instant('PRODUCT_TOUR.BUTTON_5')
                    };
                    ngIntroService.setOptions(ctrl.IntroOptions);
                    $timeout(ngIntroService.start.bind(ngIntroService));
                    ngIntroService.onAfterChange(function () {
                        $('.introjs-donebutton ').css("color", "white").css("background", "#3399ff").css("text-shadow", "none");
                    });
                    ngIntroService.onComplete(function () {
                        ngIntroService.clear();
                        $state.go('app.availability', { "tour": true});
                    });
                }
            })


    OrganisationService.getProjects($stateParams.organisationId).then(function(projects) {
        ctrl.projects = projects;
    });
}
