import angular from "angular";
import template from "./project-members-block.component.html";

var ProjectMembersBlock = {
    bindings: {
        owner: "=",
        data: "=",
        type: "=",
    },
    template: template,
    controller: ProjectMembersBlockController,
};

ProjectMembersBlockController.$inject = ["ProjectService"];

/* @ngInject */
function ProjectMembersBlockController(ProjectService) {
    var ctrl = this;
    ctrl.currentPage = 1;
    ctrl.itemsPerPage = 3;
}

angular
    .module("app.project.manager-view-project")
    .component("projectMembersBlock", ProjectMembersBlock)
    .filter("startFrom", function () {
        return function (input, start) {
            start = +start; //parse to int
            return input.slice(start);
        };
    });
